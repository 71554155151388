import { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { COLOR_BLUM } from 'styles/variables';
import { EezyButton } from '../Buttons';
import { FormTextArea } from '../form';
import { Modal, ModalActions, ModalContent } from './Modal';
import { useTranslation } from 'react-i18next';
import { CREATE_MESSAGE_THREAD } from '../../containers/support/queries';
import { trpc } from 'trpc';

interface IFeedbackModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
}

const FeedbackModal = (props: IFeedbackModalProps) => {
    const { t } = useTranslation();
    const trpcUtils = trpc.useUtils();

    const [feedbackText, setFeedbackText] = useState<string>('');

    const [createMessage] = useMutation(CREATE_MESSAGE_THREAD, {
        onCompleted: () => {
            props.handleModalClose();
            toast(t('feedback.success-toast'));
            setFeedbackText('');
            trpcUtils.messages.list.invalidate();
        },
        onError: () => {
            props.handleModalClose();
            toast(t('failure-toast'));
        },
    });

    const submitFeedback = () => {
        if (feedbackText) {
            createMessage({
                variables: {
                    message: {
                        content: `${feedbackText} \n\n${window.location.pathname} `,
                        subject: t('feedback.subject'),
                    },
                },
            });
        }
    };

    return (
        <Modal
            id="modal-strong-auth"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            noscroll
            small
            title={<div style={{ marginTop: 10 }}>{t('feedback.title') || ''}</div>}
        >
            <ModalContent style={{ margin: 5 }}>
                <FormTextArea
                    data-mf-replace="**REMOVED**"
                    label={''}
                    onChange={(val) => setFeedbackText(val)}
                    name="feedback-input"
                    placeholder={t('feedback.input-placeholder') || ''}
                    rowsMin={2}
                    required
                    value={feedbackText}
                />
            </ModalContent>

            <ModalActions>
                <EezyButton
                    style={{ border: `1px solid ${COLOR_BLUM}` }}
                    color="purple"
                    dark
                    fullWidth
                    onClick={submitFeedback}
                >
                    {t('feedback.submit-button')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default FeedbackModal;
