import { type CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadSalariesModal from './DownloadSalariesModal';
import { Button } from 'components/common/Button';

interface IProps {
    disabled?: boolean;
    style?: CSSProperties;
}

export const DownloadSalaries = (props: IProps) => {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div style={props.style}>
            <Button disabled={props.disabled} onClick={() => setModalOpen(true)}>
                {t('salaries:modal.title')}
            </Button>
            {modalOpen && (
                <DownloadSalariesModal handleModalClose={() => setModalOpen(false)} isOpen={modalOpen} />
            )}
        </div>
    );
};
