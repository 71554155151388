import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { IRootState } from 'reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { Toast } from 'components/Toast';
import { isMobile } from 'utils';
import { useUser } from 'queries/useUserQuery';
import { toast } from 'sonner';
import WithholdingTaxToast from './WithholdingTaxToast';
import fetchGetWithholdingTax from 'queries/fetchGetWithholdingTax';
import { ToastBody } from 'components/common/ToastBody';
import { SquareArrowOutUpRight } from 'lucide-react';
import { Button } from 'components/common/Button';

const timeoutToAvoidFlickering = 2000;
const WITHHOLDING_TAX_STATUS_TOAST_CONTAINER_ID = 'withholding-tax-status-toast';

const WithholdingTax = () => {
    const { t } = useTranslation();
    const user = useUser();

    const isLoggedIn: boolean | null = useSelector((state: IRootState) => state.auth.loggedIn);
    const skip = !isLoggedIn || !user?.isIdentified;

    useEffect(() => {
        if (skip) {
            return;
        }

        (async () => {
            try {
                const {
                    status,
                    addPercentage,
                    basicPercentageLimit,
                    basicPercentage,
                    text,
                    startDate,
                    error,
                } = await fetchGetWithholdingTax();

                if (!status || text === 'no_change' || error?.reason === 'request_recently_sent') return;

                const toastProps = {
                    taxApiMessage: '',
                    isDataError: false,
                    isTechError: false,
                };

                if (
                    status === 'ok' &&
                    (addPercentage || basicPercentageLimit || basicPercentage || startDate) &&
                    text !== 'no_change'
                ) {
                    toastProps.taxApiMessage = t('withholdingTax.successMessage', {
                        percentage: basicPercentage,
                    });
                    toastProps.isTechError = false;
                    toastProps.isDataError = false;
                } else if (
                    status === 'error' &&
                    (error?.reason === 'tax_card_save_failed' ||
                        error?.reason === 'api_call_failed' ||
                        error?.reason === 'bad_request' ||
                        error?.reason === 'request_recently_sent')
                ) {
                    toastProps.taxApiMessage = t('withholdingTax.technicalErrorMessage', {
                        percentage: basicPercentage,
                    });
                    toastProps.isTechError = true;
                } else if (status === 'error' && error?.reason === 'no_data') {
                    toastProps.taxApiMessage = t('withholdingTax.noDataErrorMessage', {
                        percentage: basicPercentage,
                    });
                    toastProps.isDataError = true;
                } else {
                    return;
                }

                if (toastProps.isDataError) {
                    toast(
                        <ToastBody
                            title={t('withholdingTax.errorTitle')}
                            text={toastProps.taxApiMessage}
                            truncate
                            icon={
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={['fas', 'warning']}
                                    className="text-red-600"
                                />
                            }
                            tailSlot={
                                <Button variant="outline" endIcon={SquareArrowOutUpRight}>
                                    {t('withholdingTax.noDataErrorButton')}
                                </Button>
                            }
                        />,
                        {
                            duration: 15000,
                        },
                    );
                } else if (toastProps.isTechError) {
                    toast(
                        <ToastBody
                            title={t('withholdingTax.errorTitle')}
                            text={toastProps.taxApiMessage}
                            truncate
                            icon={
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={['fas', 'warning']}
                                    className="text-red-600"
                                />
                            }
                        />,
                        {
                            duration: 15000,
                        },
                    );
                } else {
                    toast(
                        <ToastBody
                            title={toastProps.taxApiMessage}
                            icon={
                                <FontAwesomeIcon size="lg" icon={faCircleCheck} className="text-green-600" />
                            }
                        />,
                    );
                }
            } catch (e) {
                // console.error(e)
            }
        })();
    }, [skip]);

    return (
        <>
            <Toast
                style={{ width: isMobile() ? '100%' : 641 }}
                containerId={WITHHOLDING_TAX_STATUS_TOAST_CONTAINER_ID}
                isLarge
                position={isMobile() ? 'bottom-center' : 'top-center'}
                hideIcon
            />
        </>
    );
};

export default WithholdingTax;
