import { type FormEvent, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import type { ThunkDispatch } from 'redux-thunk';
import AuthCode from 'react-auth-code-input';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { authActionFailure, hideError, loginSuccess } from '../../actions/auth';
import { EezyButton, InlineButtonLink } from '../../components/Buttons';
import ErrorBox from '../../components/ErrorBox';
import { FormInput, FormSection } from '../../components/form';
import LanguageOptions from '../../components/LanguageOptions';
import LoadingSpinner from '../../components/Loading';
import { P, UniqueTitle } from '../../components/textElements';
import type { IAuth } from '../../reducers/authReducer';
import { GtagCommands, GTMEvents } from 'constants/user';
import {
    BORDER_RADIUS,
    COLOR_BLUM,
    COLOR_WHITE_WALKER,
    TOPBAR_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    FOOTER_HEIGHT,
    SCREEN_M,
    COLOR_GREY_FOG,
    COLOR_DARK_GRAY,
} from '../../styles/variables';
import { trans } from '../../utils';
import { getLocalStorage } from '../../utils/user/userUtils';
import { resetPasswordValidator } from '../../utils/user/validators';
import { EMAIL_REGEX, formatValidationResult } from '../../utils/validation';
import Bar, { BarContent } from '../nav/Bar';
import PurpleBackground from '../nav/PurpleBackground';
import Footer from '../../components/Footer';
import type { IRootState } from 'reducers';
import API from 'utils/API';
import { PASSWORD_REQUESTED_FAILURE } from 'actionTypes';
import { ERROR_PWD_CHANGE_OTHER } from 'utils/error';

// When user requests link to reset password, email is stored in local storage for x
// minutes and after that email is asked in the form
const EMAIL_VALID_IN_LOCAL_STORAGE_MINUTES = 15;

const ContentWrapper = styled.div`
    display: block;
    margin: 0 auto;
    padding-top: 40px;
    text-align: center;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);
    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);
    }
`;

const LoginWrapper = styled.div`
    align-items: start;
    background-color: ${COLOR_WHITE_WALKER};
    border-radius: ${BORDER_RADIUS};
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 30px;
    text-align: left;
    margin: 40px auto;
    max-width: 450px;
    width: 100%;

    h1 {
        margin-bottom: 0;
    }
    form {
        width: 100%;
    }
    .codeContainer {
        margin: 10px 0 50px 0;
        display: flex;
        justify-content: center;
    }

    .codeInput {
        border: 1px solid ${COLOR_GREY_FOG};
        width: 50px;
        height: 50px;
        text-align: center;
        margin: 0 5px;
        font-size: 20px;

        :focus {
            border-color: ${COLOR_DARK_GRAY};
        }
    }
`;

interface IForm {
    email: string;
    password: string;
    password2: string;
    tfToken?: string;
}

const initialFormData: IForm = {
    email: '',
    password: '',
    password2: '',
};

interface IProps {
    auth: IAuth;
    hideError: () => void;
}

const PasswordReset = (props: IProps) => {
    const navigate = useNavigate();
    const { token } = useParams();
    const { t } = useTranslation();

    const { error, loading } = props.auth;
    const [formData, setFormData] = useState<IForm>(initialFormData);
    const [errors, setErrors] = useState<any>(null);
    const [emailAutoFilled, setEmailAutoFilled] = useState(false);
    const [verificationCodeRequired, setVerificationCodeRequired] = useState(false);

    const isLoggedIn = useSelector((state: IRootState) => state.auth.loggedIn);

    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const validate = (data: any) => {
        return formatValidationResult(resetPasswordValidator.validate(data));
    };

    const handleChange = (val: string, name: string) => {
        const newFormData = { ...formData, [name]: val };
        setFormData(newFormData);
        if (errors) {
            setErrors(validate(newFormData) || null);
        }
    };

    const login = async () => {
        try {
            const { data } = await API.post('/login', {
                name: formData.email,
                password: formData.password,
                ...(verificationCodeRequired ? { tfToken: formData.tfToken } : {}),
            });

            if (data.status === 'tw_token_needed') {
                setVerificationCodeRequired(true);
                return;
            }

            localStorage.setItem('login-time', new Date().toISOString());

            dispatch(loginSuccess());
        } catch (e) {
            if (e?.response?.status === 401) {
                sessionStorage.removeItem('jwt');
                if (formData.tfToken) {
                    setErrors({ tfToken: true });
                }
            }
            console.error(e);
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const err = validate(formData);
        if (err) {
            setErrors(err);
        } else {
            setErrors(null);

            try {
                await API.post('/user/reset-password', { ...formData, token: token || '' });

                window.gtag?.(GtagCommands.event, GTMEvents.reset_password);

                await login();
            } catch (e) {
                const error = e?.response?.data?.errors ? e.response.data?.errors[0] : null;
                dispatch({ type: PASSWORD_REQUESTED_FAILURE });

                if (error) {
                    dispatch(authActionFailure(error));
                } else {
                    dispatch(authActionFailure(ERROR_PWD_CHANGE_OTHER));
                }
            }
        }
    };

    useEffect(() => {
        const localData = getLocalStorage('reset-email');
        if (localData) {
            const { email, minutesSince } = localData;
            const emailIsValid = email.match(EMAIL_REGEX); // Failsafe check
            if (minutesSince <= EMAIL_VALID_IN_LOCAL_STORAGE_MINUTES && emailIsValid) {
                setFormData({ ...formData, email });
                setEmailAutoFilled(true);
            } else {
                localStorage.removeItem('reset-email');
            }
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/', { replace: true });
        }
    }, [isLoggedIn]);

    useEffect(() => {
        props.hideError();
    }, []);

    useEffect(() => {
        if (formData.tfToken?.length === 4) {
            login();
        }
    }, [formData.tfToken, setVerificationCodeRequired]);

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/', { replace: true });
        }
    }, [isLoggedIn]);

    return (
        <>
            <Bar>
                <BarContent>
                    <LanguageOptions />
                </BarContent>
            </Bar>
            <PurpleBackground>
                <ContentWrapper>
                    <LoginWrapper>
                        <UniqueTitle color={COLOR_BLUM}>{trans('login.pwd-reset-title')}</UniqueTitle>
                        <form>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '40px',
                                }}
                            >
                                {loading && <LoadingSpinner />}
                                {error && !error.place && (
                                    <ErrorBox>
                                        {error.title}
                                        {error.internalUrl && (
                                            <>
                                                {' '}
                                                <InlineButtonLink
                                                    color="white"
                                                    onClick={() => navigate(error.internalUrl || '')}
                                                >
                                                    {error.internalLinkAnchor}
                                                </InlineButtonLink>
                                            </>
                                        )}
                                    </ErrorBox>
                                )}
                                {errors && (
                                    <ErrorBox>
                                        {errors?.email && trans('login.invalid-email')}
                                        {errors?.password2 && trans('login.invalid-password-2')}
                                        {errors?.password && trans('login.invalid-password')}
                                        {errors?.tfToken && trans('login.wrong-verification-code')}
                                    </ErrorBox>
                                )}
                            </div>
                            {verificationCodeRequired ? (
                                <>
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            marginBottom: 40,
                                            marginTop: 20,
                                        }}
                                    >
                                        {t('login.check-verification-code')}
                                    </p>
                                    <AuthCode
                                        length={4}
                                        allowedCharacters="numeric"
                                        inputClassName="codeInput"
                                        containerClassName="codeContainer"
                                        onChange={(val) => {
                                            handleChange(val, 'tfToken');
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <FormSection>
                                        <P>{trans('login.pwd-reset-help')}</P>
                                    </FormSection>
                                    <FormSection>
                                        {!emailAutoFilled && (
                                            <FormInput
                                                error={errors?.email}
                                                label={trans('login.email')}
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                                type="email"
                                            />
                                        )}
                                        <FormInput
                                            autoFocus
                                            error={errors?.password}
                                            label={trans('login.pwd-new')}
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                            type="password"
                                        />
                                        <FormInput
                                            error={errors?.password2}
                                            label={trans('login.pwd-repeat')}
                                            name="password2"
                                            value={formData.password2}
                                            onChange={handleChange}
                                            required
                                            type="password"
                                        />
                                    </FormSection>
                                    <FormSection>
                                        <EezyButton
                                            color="purple"
                                            onClick={handleSubmit}
                                            style={{ alignSelf: 'flex-end' }}
                                            type="submit"
                                        >
                                            {trans('login.login')}
                                        </EezyButton>
                                    </FormSection>
                                </>
                            )}
                        </form>
                    </LoginWrapper>
                </ContentWrapper>
            </PurpleBackground>
            <Footer />
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        hideError: () => {
            dispatch(hideError());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
