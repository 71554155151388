import { Avatar } from 'components/common/Avatar';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { LogOut } from 'lucide-react';
import { useUser } from 'queries/useUserQuery';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigationItems, type UserDropdownMenuItem } from './useNavigationItems';

// UserDropdownSingleItem is without subItems.
const UserDropdownSingleItem = (
    props: Omit<UserDropdownMenuItem, 'subItems' | 'Icon'> & { Icon?: UserDropdownMenuItem['Icon'] },
) => {
    const itemComponent = (
        <DropdownMenuItem onClick={props.onClick} className="cursor-pointer">
            {props.Icon && <props.Icon />} <span>{props.text}</span>
        </DropdownMenuItem>
    );

    // If there's a path, it's a link so wrap it in a link
    if (!props.path) return itemComponent;
    return (
        <Link to={props.path} className="no-underline text-gray-800">
            {itemComponent}
        </Link>
    );
};

const UserDropdownItem = (
    props: Omit<UserDropdownMenuItem, 'Icon'> & { Icon?: UserDropdownMenuItem['Icon'] },
) => {
    if (props.subItems) {
        return (
            <DropdownMenuSub>
                <DropdownMenuSubTrigger className="cursor-pointer">
                    {props.Icon && <props.Icon />} <span>{props.text}</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                        {props.subItems.map((item) => (
                            <UserDropdownItem key={item.text} {...item} />
                        ))}
                    </DropdownMenuSubContent>
                </DropdownMenuPortal>
            </DropdownMenuSub>
        );
    }

    return <UserDropdownSingleItem {...props} />;
};

export const NavigationUserPopover = () => {
    const user = useUser();

    const { dropdownMenuItems } = useNavigationItems();
    const { t } = useTranslation();

    return (
        <>
            <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                    <Avatar user={user} />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="min-w-64" collisionPadding={20}>
                    <div className="flex gap-4 py-2 pl-3 pr-5">
                        <Avatar user={user} asDiv />
                        <div>
                            <div className="tg-body-medium text-gray-700">
                                {user?.firstName} {user?.lastName}
                            </div>
                            <div className="tg-caption text-gray-500">{user?.email}</div>
                        </div>
                    </div>
                    <DropdownMenuSeparator />
                    {dropdownMenuItems.map((group) => (
                        <div key={`dropdown-group-${group[0].text}`}>
                            <DropdownMenuGroup>
                                {group.map((item) => (
                                    <UserDropdownItem key={item.text} {...item} />
                                ))}
                            </DropdownMenuGroup>
                            <DropdownMenuSeparator />
                        </div>
                    ))}
                    <Link to="/logout" className="no-underline text-gray-800">
                        <DropdownMenuItem className="flex justify-between">
                            <span>{t('login.logout')}</span>
                            <LogOut />
                        </DropdownMenuItem>
                    </Link>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
};
