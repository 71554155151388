import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { formatTime } from '../../utils';
import { trpc } from 'trpc';
import { useTranslation } from 'react-i18next';

// How often the backend message is queried from the server.
// E.g. for cases when the maintenace break is dismissed.
const REFETCH_MINUTES = 10;

// How often a warning is displayed if the previous one is hidden
const WARNING_MINUTES = 5;

const MaintenanceBreak = () => {
    const { t } = useTranslation();
    const [interval, setIntervalTimer] = React.useState<NodeJS.Timeout>();

    const { data } = trpc.backend.messages.useQuery(undefined, {
        refetchInterval: REFETCH_MINUTES * 60 * 1000,
    });

    useEffect(() => {
        if (!data) return;

        clearInterval(interval);
        setIntervalTimer(
            setInterval(
                () => {
                    toast.warn(
                        t('errors.maintenance-break-coming', {
                            min: data.duration,
                            time: formatTime(data.time),
                        }),
                        { autoClose: false, toastId: 'maintenanceWarning' },
                    );
                },
                WARNING_MINUTES * 60 * 1000,
            ),
        );
    }, [data]);

    return null;
};

export default MaintenanceBreak;
