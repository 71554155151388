import { connect } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';

import type { IContactList, ICoworker } from '../../../../shared/src/types/user';
import { showModals } from '../../actions/auth';
import { setSelectedCoworker } from '../../actions/coworker';
import { AutoSaveCard } from '../../components/cards';
import { Flex } from '../../components/Flex';
import { COLOR_BLUM, COLOR_GREYHOUND } from '../../styles/variables';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';

interface IGroupInvoicingProps {
    contacts: IContactList;
    showModals: (modals: string[]) => void;
    setSelectedCoworker: (coworker: ICoworker) => void;
}

const GroupInvoicing = (props: IGroupInvoicingProps) => {
    const { t } = useTranslation();
    return (
        <AutoSaveCard title={t('profile:group-invoicing.title')}>
            <p className="tg-body">{t('profile:group-invoicing.description')}</p>
            <div className="flex items-center justify-between">
                <p className="tg-body-bold">
                    <b>{t('profile:group-invoicing.invite-paragraph')}</b>
                </p>
                <Button onClick={() => props.showModals(['INVITE_COWORKER'])}>
                    {t('profile:group-invoicing.invite-button')}
                </Button>
            </div>
            <ContactsList
                type="admitted"
                contacts={props.contacts?.contacts}
                showModals={props.showModals}
                setSelectedCoworker={props.setSelectedCoworker}
            />
            <ContactsList
                type="pending"
                contacts={props.contacts?.pendingContacts}
                showModals={props.showModals}
                setSelectedCoworker={props.setSelectedCoworker}
            />
        </AutoSaveCard>
    );
};

interface IPendingContactsListProps {
    type: 'admitted' | 'pending';
    contacts: ICoworker[] | undefined;
    showModals: (modal: string[]) => void;
    setSelectedCoworker: (coworker: ICoworker) => void;
}

const ContactsList = (props: IPendingContactsListProps) => {
    const { t } = useTranslation();
    const handleClick = (coworker: ICoworker) => {
        props.setSelectedCoworker(coworker);
        props.showModals(['DELETE_COWORKER']);
    };

    const getColor = (type: typeof props.type) => {
        switch (type) {
            case 'admitted':
                return COLOR_BLUM;
            case 'pending':
                return COLOR_GREYHOUND;
        }
    };

    const contactList = (props.contacts || []).map((contact) => (
        <Flex key={contact.id} spread style={{ marginTop: 15 }}>
            <p className="tg-body-bold" data-mf-replace="**REMOVED**" style={{ color: getColor(props.type) }}>
                <b>
                    {contact.name} {props.type === 'pending' && t('profile:group-invoicing.pending')}
                </b>
            </p>
            <Button color="red" onClick={() => handleClick(contact)}>
                {t('form.delete')}
            </Button>
        </Flex>
    ));
    return contactList;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        setSelectedCoworker: (coworker: ICoworker) => {
            dispatch(setSelectedCoworker(coworker));
        },
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};

export default connect(null, mapDispatchToProps)(GroupInvoicing);
