import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { showModals } from 'actions/auth';
import ColorBox from 'components/ColorBox';
import UploadTaxCard from './UploadTaxCard';
import { COLOR_BLACKWATER, COLOR_LILA_MEDIUM } from 'styles/variables';
import { Button } from 'components/common/Button';

interface IProps {
    nextTaxCardStartDate?: string;
    taxCardMissing?: boolean;
    taxCardPending?: boolean;
    taxCardNotValidYet?: boolean;
    isIdentified?: boolean;
}

const TaxCardNote: FC<IProps> = ({
    nextTaxCardStartDate,
    taxCardMissing,
    taxCardPending,
    taxCardNotValidYet,
    isIdentified,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();
    if (!taxCardPending && !taxCardMissing && isIdentified && !(taxCardNotValidYet && nextTaxCardStartDate)) {
        return null;
    }

    return (
        <ColorBox color={COLOR_LILA_MEDIUM} disableClose style={{ marginBottom: 40, padding: 30 }}>
            {taxCardPending ? (
                <>
                    <p className="tg-body-bold">
                        <b>{t('salaries:taxCard.pending1')}</b>
                    </p>
                    <p className="tg-body mt-2" style={{ color: COLOR_BLACKWATER }}>
                        {t('salaries:taxCard.pending2')}
                    </p>
                </>
            ) : taxCardNotValidYet && nextTaxCardStartDate ? (
                <>
                    <p className="tg-body-bold">
                        <b>
                            {t('salaries:taxCard.notValidYet1', {
                                date: moment(nextTaxCardStartDate, 'DD.MM.YYYY').format('l'),
                            })}
                        </b>
                    </p>
                    <p className="tg-body mt-2" style={{ color: COLOR_BLACKWATER }}>
                        {t('salaries:taxCard.notValidYet2')}
                    </p>

                    <UploadTaxCard hideLabel style={{ marginTop: 40 }} />
                </>
            ) : taxCardMissing && isIdentified ? (
                <>
                    <p className="tg-body-bold">
                        <b>{t('salaries:taxCard.missing1')}</b>
                    </p>
                    <p className="tg-body mt-2" style={{ color: COLOR_BLACKWATER }}>
                        {t('salaries:taxCard.missing2')}
                    </p>
                    <UploadTaxCard hideLabel style={{ marginTop: 40 }} />
                </>
            ) : taxCardMissing && !isIdentified ? (
                <>
                    <p className="tg-body-bold">
                        <b>{t('salaries:taxCard.notIdentifiedMissing1')}</b>
                    </p>
                    <p className="tg-body mt-2" style={{ color: COLOR_BLACKWATER }}>
                        {t('salaries:taxCard.notIdentifiedMissing2')}
                    </p>
                    <div className="mt-8">
                        <Button onClick={() => dispatch(showModals(['AUTH']))}>
                            {t('salaries:taxCard.notIdentifiedButton')}
                        </Button>
                    </div>
                </>
            ) : null}
        </ColorBox>
    );
};

export default TaxCardNote;
