import { useTranslation } from 'react-i18next';
import { Combobox } from '../common/ComboBox';
import { useMemo } from 'react';

type StatisticsClientPickerProps = {
    clients: string[];
    value: string | null;
    onChange: (value: string | null) => void;
};

export function StatisticsClientPicker({ clients, value, onChange }: StatisticsClientPickerProps) {
    const { t } = useTranslation();
    const allClientsName = t('statistic.all-clients');

    const clientsList = useMemo(() => {
        return [
            { label: allClientsName, value: 'all' },
            ...(clients.map((client) => ({
                label: client,
                value: client,
            })) || []),
        ];
    }, [clients, allClientsName]);

    const handleClientChange = (name: string) => {
        if (name === allClientsName) return onChange(null);
        onChange(name === 'all' ? null : name);
    };

    return (
        <Combobox
            options={clientsList}
            value={value ?? 'all'}
            defaultValue="all"
            onChange={handleClientChange}
            selectLabel={allClientsName}
            notFoundLabel={t('statistic.no-clients-found')}
            searchLabel={t('statistic.search-clients')}
            className="w-auto rounded-md"
            limitButtonTextWidthTo={150}
            title={t('statistic.choose-client')}
        />
    );
}
