import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { COLOR_IMPORTANT, COLOR_WHITE_WALKER } from '../../styles/variables';
import { trans } from '../../utils';
import { trpc } from 'trpc';

const popout = keyframes`
    from {
        transform:scale(0);
    }
    80% {
        transform:scale(1.2);
    }        
    to {
        transform:scale(1);
    }
`;

const MessageNotification = styled(Flex)`
    animation: ${popout} 0.7s ease;
    height: 20px;
    margin-left: 19px;
    position: relative;

    &:focus-within {
        svg {
            color: ${COLOR_WHITE_WALKER};
        }
    }
`;

const NumberBubble = styled(Flex).attrs({
    center: true,
    justifyCenter: true,
})`
    background-color: ${COLOR_IMPORTANT};
    border-radius: 16px;
    color: ${COLOR_WHITE_WALKER};
    font-display: swap;
    font-size: 13px;
    height: 19px;
    position: absolute;
    right: -11px;
    top: -11px;
    width: 19px;
`;

interface IProps {
    color: string;
}

const NewMessagesBubble = (props: IProps) => {
    const { data: messagesData } = trpc.messages.list.useQuery(undefined, { refetchInterval: 5 * 60 * 1000 });

    const unreadThreads = messagesData?.filter((thread) => thread.hasUnread) || [];

    if (!messagesData || !unreadThreads.length) {
        return null;
    }

    return (
        <MessageNotification>
            <Link
                className="new-message-link"
                to={`/support${unreadThreads?.length === 1 ? `/messages/${unreadThreads[0].id}` : ''}`}
                aria-label={trans('support:message.new-messages', {
                    count: unreadThreads.length,
                })}
            >
                <Icon icon={['far', 'envelope']} color={props.color} />
                <NumberBubble aria-hidden={true}>
                    <span>{unreadThreads.length}</span>
                </NumberBubble>
            </Link>
        </MessageNotification>
    );
};

export default NewMessagesBubble;
