import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AutoSaveCard } from 'components/cards';
import { Icon } from 'components/Icon';
import { COLOR_BLUM, COLOR_GREYJOY } from 'styles/variables';
import { REPORT_SHOULD_HAVE_YEL } from '../dashboard/queries';
import { useEffect, useState } from 'react';
import { useUserQuery } from 'queries/useUserQuery';
import { Button } from 'components/common/Button';

const shouldDisableYel = (birthday: string) => {
    const birthYear = Number(birthday.substring(0, 4));

    const birthDate = new Date(birthday);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    if (
        age < 18 ||
        (birthYear <= 1957 && age > 68) ||
        (birthYear >= 1958 && birthYear <= 1961 && age > 69) ||
        (birthYear >= 1962 && age > 78)
    ) {
        return true;
    }
    return false;
};

const ProfileInsurances = () => {
    const { t, i18n } = useTranslation();
    const { data: user, refetch } = useUserQuery();

    const [disabledYel, setDisabledYel] = useState(false);

    useEffect(() => {
        if (user?.birthday) {
            if (shouldDisableYel(user?.birthday)) {
                setDisabledYel(true);

                if (user?.yelSelfReported) {
                    reportYel({ variables: { shouldHave: false } });
                }
            }
        }
    }, [user?.birthday]);

    const [reportYel] = useMutation(REPORT_SHOULD_HAVE_YEL, {
        onCompleted: () => {
            toast(t('general.saved'));
            refetch();
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
    });

    const handleConfirm = (shouldHave: boolean) => {
        reportYel({ variables: { shouldHave } });
    };

    return (
        <AutoSaveCard title={t('profile:insurances.title')}>
            <div className="flex flex-col gap-4">
                <p className="tg-body">{t('profile:insurances.description')}</p>

                <p className="tg-body">
                    <a
                        href={
                            i18n.language === 'en'
                                ? 'https://kevytyrittajat.eezy.fi/en/eezy-light-entrepreneurs-insurances/'
                                : 'https://kevytyrittajat.eezy.fi/kevytyrittajan-vakuutukset-ja-korvauksen-hakeminen/'
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t('profile:insurances.link3')}
                        <Icon
                            className="small"
                            color={COLOR_BLUM}
                            icon={['far', 'arrow-up-right-from-square']}
                            style={{ marginLeft: 10 }}
                        />
                    </a>
                </p>

                <p className="tg-body">{t('profile:insurances.description2')}</p>

                <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3">
                    <p className="tg-subtitle">{t('dashboard.messages.yel.modal-confirm')}</p>
                    <div className="flex gap-1">
                        <Button
                            variant={user?.yelSelfReported ? 'filled' : 'outline'}
                            disabled={disabledYel}
                            onClick={() => handleConfirm(true)}
                        >
                            {t('general.yes')}
                        </Button>
                        <Button
                            variant={!user?.yelSelfReported ? 'filled' : 'outline'}
                            disabled={disabledYel}
                            onClick={() => handleConfirm(false)}
                        >
                            {t('general.no-I')}
                        </Button>
                    </div>
                </div>

                {disabledYel && (
                    <p className="tg-body" color={COLOR_GREYJOY}>
                        {t('profile:insurances.disabled-reason')}
                    </p>
                )}

                <p className="tg-body">
                    <Link to="/yel">
                        {t('profile:insurances.link2')}
                        <Icon
                            className="small"
                            color={COLOR_BLUM}
                            icon={['far', 'arrow-up-right-from-square']}
                            style={{ marginLeft: 10 }}
                        />
                    </Link>
                </p>
            </div>
        </AutoSaveCard>
    );
};

export default ProfileInsurances;
