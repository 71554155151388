import { useEffect, useState, useCallback, useMemo } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import QRCode from 'qrcode';
import type { IRootState } from 'reducers';
import { toast } from 'react-toastify';
import { debounce } from 'utils/debounce';
import type { ToastContentProps } from 'react-toastify/dist/types';
import styled from 'styled-components';
import type { IOccupation } from '../../../../shared/src/types/invoice';
import BackButtonWithTitle from '../../components/BackButtonWithTitle';
import { EezyButton } from 'components/Buttons';
import { Card } from 'components/cards';
import ErrorBox from '../../components/ErrorBox';
import { Flex } from 'components/Flex';
import { FormInput, FormNumber, FormSelect } from 'components/form';
import { Icon } from 'components/Icon';
import LoadingSpinner from '../../components/Loading';
import { SmallP } from 'components/textElements';
import { Toast } from 'components/Toast';
import config from 'config';
import { GtagCommands, GTMEvents } from 'constants/user';
import {
    COLOR_BLUM,
    COLOR_DARK_GRAY,
    COLOR_GREYJOY,
    COLOR_IMPORTANT,
    COLOR_STATUS_WAITING,
    COLOR_WHITE_WALKER,
    FOOTER_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    SCREEN_M,
    SCREEN_S,
    TOPBAR_HEIGHT,
} from 'styles/variables';
import { eursToCents, isMobile } from 'utils';
import { sortObjectsByLabel } from 'utils/str';
import RelatedObjectMessage from '../dashboard/RelatedObjectMessage';
import { GET_OCCUPATIONS } from '../invoice/queries';
import { CREATE_SALES_PAGE, GET_SALES_PAGES, GET_SALES_PAGES_BY_PATH } from './queries';
import SalesPageShareModal from './SalesPageShareModal';
import SuccessCard from './SuccessCard';
import { validateForbiddenTerms } from './validation/validation';
import getVatDefaultForInvoice from 'utils/invoice/getVatDefaultForInvoice';
import { useUser } from 'queries/useUserQuery';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { dispatch } from 'utils/store';
import { showModals } from 'actions/auth';
import { useSelector } from 'react-redux';
import useEezyPayTermsUtils from 'utils/eezyPay/useEezyPayTermsUtils';
import type { VatOption } from 'types/invoice.type';
import GET_INVOICE_VAT_OPTIONS from 'containers/options-config/queries/getInvoiceVatOptions';
import type { IDropdownOption } from 'components/form/AutocompleteDropdown';
import { Button } from 'components/common/Button';

export interface ISalesPage {
    creationDate: Date;
    id: number;
    amountOfPayments?: number;
    occupationId: number;
    publicPath: string;
    serviceDescription?: string;
    serviceName: string;
    servicePrice: number;
    serviceVat: number;
    transactions_aggregate: {
        aggregate: {
            count: number;
        };
    };
    state: number;
}

interface ISalesPageData {
    insert_sales_pages: {
        returning: ISalesPage[];
    };
}

interface QRCodeError extends Error {
    message: string;
    name: string;
    stack?: string;
}

const VAT_TOAST_ID = 'vat-toast-id';
const forbiddenTermsFile = '/forbiddenTerms.txt';
const MIN_SERVICE_PRICE = 5;
const MAX_SERVICE_PRICE = 6000;

// Styled components remain the same

const Wrapper = styled.div`
    max-width: 644px;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);
    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);
    }
    margin: 0 auto;
    padding-top: 30px;
`;

const inputStyle = {
    flexGrow: 1,
    marginBottom: 20,
    marginTop: 0,
    width: '100%',
};

const SuccessCardWrapper = styled(Card)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 36px;
`;

const ToastMsg = styled.div`
    padding: 20px 30px 20px 20px;
    @media (max-width: ${SCREEN_S}px) {
        flex-direction: column;
    }
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const LoaderWrapper = styled.div`
    text-align: center;
    margin: 130px 0;
`;

const CustomInput = styled.input`
    &:disabled {
        color: ${COLOR_GREYJOY};
    }
`;

const VatToastMessage = ({ closeToast }: Partial<ToastContentProps>) => {
    const { t } = useTranslation();
    return (
        <ToastMsg>
            <Flex>
                <Flex center>
                    <div>
                        <Icon
                            color={COLOR_STATUS_WAITING}
                            icon={['fas', 'triangle-exclamation']}
                            style={{ margin: 'auto 14px auto 0' }}
                        />
                    </div>
                    <div
                        className="text-left"
                        dangerouslySetInnerHTML={{ __html: t('eezyPay:vat-toast-message') }}
                    />
                </Flex>
                <Icon
                    onClick={closeToast}
                    icon={['fal', 'times']}
                    style={{ position: 'absolute', top: 10, right: 20 }}
                />
            </Flex>
        </ToastMsg>
    );
};

const generatePublicPath = () => (Math.random() + 1).toString(36).substring(2);

type Props = {
    editMode?: boolean;
};

const SalesPageCreate: React.FC<Props> = ({ editMode }: Props) => {
    // Properties
    const params = useParams();
    const user = useUser();

    const [serviceName, setServiceName] = useState<string>('');
    const [serviceNameError, setServiceNameError] = useState<boolean>(false);
    const [serviceNameTermsError, setServiceNameTermsError] = useState<boolean>(false);
    const [servicePrice, setServicePrice] = useState<number | undefined>();
    const [servicePriceError, setServicePriceError] = useState<boolean>(false);
    const [servicePriceAmountError, setServicePriceAmountError] = useState<boolean>(false);
    const [serviceVat, setServiceVat] = useState<number>(getVatDefaultForInvoice());
    const [serviceVatError, setServiceVatError] = useState<boolean>(false);
    const [serviceDescription, setServiceDescription] = useState<string>('');
    const [serviceDescriptionTermsError, setServiceDescriptionTermsError] = useState<boolean>(false);
    const [publicPath, setPublicPath] = useState<string>('');
    const [occupationId, setOccupationId] = useState<number | undefined>();
    const [occupationError, setOccupationError] = useState<boolean>(false);
    const [pageCreated, setPageCreated] = useState<boolean>(false);
    const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
    const [salesPage, setSalesPage] = useState<ISalesPage | undefined>();
    const [forbiddenTerms, setForbiddenTerms] = useState<string>('');
    const [invoiceVatOptions, setInvoiceVatOptions] = useState<VatOption[]>([]);

    const { eezyPayTermsAccepted } = useSelector((state: IRootState) => ({
        eezyPayTermsAccepted: state.eezyPay.eezyPayTermsAccepted,
    }));

    // Computed
    const { data: occupations, loading: occupationsLoading } = useQuery(GET_OCCUPATIONS);

    const rowVatOptions: IDropdownOption[] = (() => {
        const options = invoiceVatOptions
            .concat([{ value: serviceVat, default: false }])
            .filter((v, i, a) => a.findIndex((x) => x.value === v.value) === i)
            .map((i) => ({ label: i.value.toString(), value: i.value.toString() }));

        return options;
    })();

    // Methods
    const { t, i18n } = useTranslation();

    const { submitAcceptTerms } = useEezyPayTermsUtils();

    const [fetchInvoiceVatOptions] = useLazyQuery(GET_INVOICE_VAT_OPTIONS, {
        fetchPolicy: 'cache-and-network',
    });

    const debouncedValidationFn = useCallback(
        (field: 'name' | 'description', value: string) => {
            if (value?.trim() && forbiddenTerms) {
                if (field === 'name') {
                    setServiceNameTermsError(validateForbiddenTerms(value, forbiddenTerms));
                } else {
                    setServiceDescriptionTermsError(validateForbiddenTerms(value, forbiddenTerms));
                }
            } else {
                if (field === 'name') {
                    setServiceNameTermsError(false);
                } else {
                    setServiceDescriptionTermsError(false);
                }
            }
        },
        [forbiddenTerms],
    );

    const debouncedValidation = useMemo(() => debounce(debouncedValidationFn, 300), [debouncedValidationFn]);

    useEffect(() => {
        debouncedValidation('name', serviceName || '');
        return () => debouncedValidation.cancel();
    }, [serviceName, debouncedValidation]);

    useEffect(() => {
        debouncedValidation('description', serviceDescription || '');
        return () => debouncedValidation.cancel();
    }, [serviceDescription, debouncedValidation]);

    const occupationOptions =
        occupations?.allOccupations?.map((opt: IOccupation) => {
            return {
                label: t(`occupations:${opt.id}`),
                value: opt.id.toString(),
            };
        }) || [];

    const renderErrorMessage = () => (
        <SmallP color={COLOR_IMPORTANT} style={{ marginTop: -15, marginBottom: 15 }}>
            {t('eezyPay:required-information')}
        </SmallP>
    );

    const mutationVariables = {
        onCompleted: (salesPagesData: ISalesPageData) => {
            toast(t('general.saved'));
            setSalesPage(salesPagesData.insert_sales_pages.returning[0]);
            generateQrCode(salesPagesData.insert_sales_pages.returning[0].publicPath);
            setPageCreated(true);
            window.gtag?.(GtagCommands.event, GTMEvents.payment_page_created);
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
        refetchQueries: () => [
            {
                context: { clientName: 'eezyPayHasura' },
                query: GET_SALES_PAGES,
                variables: {
                    searchTerm: '%%',
                },
            },
        ],
    };

    const [createSalesPage, { loading: createSalesPageLoading }] = useMutation(CREATE_SALES_PAGE, {
        context: { clientName: 'eezyPayHasura' },
        ...mutationVariables,
    });

    const [getPageData] = useLazyQuery(GET_SALES_PAGES_BY_PATH, {
        context: { clientName: 'eezyPayHasura' },
        onCompleted: ({ sales_pages }) => {
            setServiceName(sales_pages[0].serviceName);
            setServicePrice(Number(sales_pages[0].servicePrice) / 100);
            setServiceVat(sales_pages[0].serviceVat);
            setServiceDescription(sales_pages[0].serviceDescription || '');
            setOccupationId(sales_pages[0].occupationId);
            setPublicPath(sales_pages[0].publicPath);
        },
    });

    useEffect(() => {
        if (editMode) {
            getPageData({
                variables: {
                    publicPath: params.publicPath,
                },
            });
        } else {
            setPublicPath(generatePublicPath());
        }
    }, [getPageData, editMode, params.publicPath]);

    useEffect(() => {
        if (serviceVat < 24) {
            toast(<VatToastMessage />, {
                autoClose: false,
                containerId: VAT_TOAST_ID,
                position: 'bottom-center',
                toastId: VAT_TOAST_ID,
            });
        }
    }, [serviceVat]);

    useEffect(() => {
        axios.get(forbiddenTermsFile).then((response) => {
            setForbiddenTerms(response.data);
        });
    }, []);

    const generateQrCode = (path: string) => {
        QRCode.toDataURL(`${config.eezyPayPublicPath}/${path}`, {
            margin: 1,
            width: 142,
        })
            .then((url: string) => {
                if (url) {
                    setQrCodeUrl(url);
                }
            })
            .catch((err: QRCodeError) => {
                console.error('QR Code generation failed:', err.message);
            });
    };

    const handleServiceNameChange = (val: string) => {
        const newValue = val || '';
        setServiceName(newValue);
        if (!newValue.trim()) {
            setServiceNameError(true);
        } else {
            setServiceNameError(false);
        }
    };

    const validatePrice = (price: number) => {
        if (price < MIN_SERVICE_PRICE || price > MAX_SERVICE_PRICE) {
            setServicePriceAmountError(true);
        } else {
            setServicePriceAmountError(false);
        }
    };

    const getPaymentPageState = () => (serviceVat === 0 ? 2 : 1);

    const validateForm = (): boolean => {
        if (!serviceName.trim()) {
            setServiceNameError(true);
            return false;
        }

        if (validateForbiddenTerms(serviceName, forbiddenTerms)) {
            setServiceNameTermsError(true);
            return false;
        }

        if (serviceDescription && validateForbiddenTerms(serviceDescription, forbiddenTerms)) {
            setServiceDescriptionTermsError(true);
            return false;
        }

        if (!servicePrice) {
            setServicePriceError(true);
            return false;
        }

        if (!serviceVat && serviceVat !== 0) {
            setServiceVatError(true);
            return false;
        }

        if (!occupationId) {
            setOccupationError(true);
            return false;
        }

        if (servicePriceAmountError) {
            return false;
        }

        return true;
    };

    const onClickSubmit = async () => {
        const bankAccountNumber = user?.bankAccountNumber;
        const canCreatePayment = !!user?.isIdentified && !!user?.address && !!bankAccountNumber;

        if (!canCreatePayment) {
            dispatch(showModals(['EEZY_PAY']));
            return;
        }

        if (!eezyPayTermsAccepted) {
            await submitAcceptTerms(user);
        }

        const isValid = validateForm();
        if (!isValid) {
            return;
        }

        createSalesPage({
            variables: {
                email: user?.email,
                firstName: user?.firstName,
                language: i18n.language,
                lastName: user?.lastName,
                occupationId,
                phone: `+${user?.phoneCountryCode} ${user?.phone}`,
                publicPath,
                serviceDescription,
                serviceName: serviceName.trim(),
                servicePrice: eursToCents(servicePrice!),
                serviceVat,
                state: getPaymentPageState(),
                userAccountId: user?.accountId,
            },
        });
    };

    const closeShareModal = () => {
        setShareModalOpen(false);
    };

    const handleCopyLinkClick = (path: string) => {
        navigator.clipboard.writeText(path);
        toast(t('form.copied'));
    };

    useEffect(() => {
        (async () => {
            const { data } = await fetchInvoiceVatOptions();
            setInvoiceVatOptions(data.invoiceVatOptions);
        })();
    }, []);

    const renderShareModal = (page: ISalesPage) => {
        if (!page) {
            return null;
        }

        return (
            <SalesPageShareModal
                shareModalOpen={shareModalOpen}
                handleShareModalClose={closeShareModal}
                page={page}
                qrCodeUrl={qrCodeUrl}
                handleCopyLinkClick={() => {
                    handleCopyLinkClick(`${config.eezyPayPublicPath}/${page.publicPath}`);
                }}
            />
        );
    };

    if (pageCreated) {
        return (
            <Wrapper>
                <BackButtonWithTitle />
                <SuccessCardWrapper>
                    <SuccessCard title={editMode ? t('eezyPay:page-updated') : t('eezyPay:page-created')} />
                    <img src={qrCodeUrl} alt="" />
                    {getPaymentPageState() === 2 ? (
                        <ErrorBox style={{ marginTop: '30px' }}>{t('eezyPay:waiting-for-approval')}</ErrorBox>
                    ) : (
                        <EezyButton
                            style={{ marginTop: '30px' }}
                            color="purple"
                            dark
                            onClick={() => {
                                setShareModalOpen(true);
                            }}
                        >
                            {t('eezyPay:share')}
                        </EezyButton>
                    )}
                </SuccessCardWrapper>
                {salesPage && renderShareModal(salesPage)}
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <BackButtonWithTitle />
            <Card>
                <h1 className="tg-heading-1 text-center mb-6">
                    {editMode ? t('eezyPay:edit-sales-page') : t('eezyPay:create-sales-page')}
                </h1>
                {!user ? (
                    <LoaderWrapper>
                        <LoadingSpinner color={COLOR_BLUM} size="3em" />
                    </LoaderWrapper>
                ) : (
                    <>
                        <p className="tg-caption-bold mb-5 text-gray-500">
                            *{t('eezyPay:required-information')}
                        </p>
                        <div className="flex flex-col">
                            {/* Service name */}
                            <CustomInput
                                as={FormInput}
                                label={`${t('eezyPay:service-name')} ${editMode ? '' : '*'}`}
                                placeholder={t('eezyPay:text-here')}
                                name="serviceName"
                                maxLength={255}
                                onChange={handleServiceNameChange}
                                style={inputStyle}
                                value={serviceName}
                                error={serviceNameError}
                                disabled={editMode}
                                tooltip={editMode ? t('eezyPay:tooltip.field-disabled') : ''}
                                tooltipColor={COLOR_WHITE_WALKER}
                                tooltipBackground={editMode ? COLOR_DARK_GRAY : COLOR_BLUM}
                                data-mf-replace="**REMOVED**"
                            />

                            {serviceNameError && renderErrorMessage()}

                            {/* Service price */}
                            <FormNumber
                                label={`${t('eezyPay:service-price')}*`}
                                placeholder={t('eezyPay:text-here')}
                                name="servicePrice"
                                endAdornment={t('form.eurs', { eurs: '' }).trim()}
                                onChange={(val: string | number) => {
                                    const numVal = Number(val);
                                    setServicePrice(numVal);
                                    setServicePriceError(!val);
                                    validatePrice(numVal);
                                }}
                                style={inputStyle}
                                value={servicePrice}
                                error={servicePriceError || servicePriceAmountError}
                                required
                            />
                            {servicePriceError && renderErrorMessage()}
                            {servicePriceAmountError && (
                                <SmallP
                                    color={COLOR_IMPORTANT}
                                    style={{
                                        marginBottom: 15,
                                        marginTop: -15,
                                    }}
                                >
                                    {t('eezyPay:service-price-error')}
                                </SmallP>
                            )}

                            {/* VAT */}
                            <FormSelect
                                id={serviceVat?.toString()}
                                label={`${t('eezyPay:vat')} ${editMode ? '' : '*'}`}
                                name="vat"
                                onChange={(val: string) => {
                                    setServiceVat(Number(val));
                                    setServiceVatError(!val);
                                }}
                                options={rowVatOptions}
                                required
                                selectStyle={inputStyle}
                                value={serviceVat?.toString() || ''}
                                showIcon
                                noWrap
                                error={serviceVatError}
                                disabled={editMode}
                                tooltip={
                                    editMode ? (
                                        t('eezyPay:tooltip.field-disabled')
                                    ) : (
                                        <div dangerouslySetInnerHTML={{ __html: t('eezyPay:tooltip.vat') }} />
                                    )
                                }
                                tooltipColor={COLOR_WHITE_WALKER}
                                tooltipBackground={editMode ? COLOR_DARK_GRAY : COLOR_BLUM}
                                tooltipTextAlign="left"
                            />
                            {serviceVatError && renderErrorMessage()}
                            {serviceVat === 0 && (
                                <SmallP color={COLOR_IMPORTANT} style={{ marginTop: -15, marginBottom: 15 }}>
                                    {t('eezyPay:manually-approved-msg')}
                                </SmallP>
                            )}

                            {/* Description */}
                            <FormInput
                                label={t('eezyPay:description')}
                                placeholder={t('eezyPay:text-here')}
                                optional
                                maxLength={255}
                                name="serviceDescription"
                                onChange={(val: string) => setServiceDescription(val)}
                                style={inputStyle}
                                value={serviceDescription}
                                error={serviceDescriptionTermsError}
                                data-mf-replace="**REMOVED**"
                            />

                            {/* Occupation */}
                            <FormSelect
                                id={occupationId?.toString()}
                                label={`${t('eezyPay:occupation')} ${editMode ? '' : '*'}`}
                                placeholder={t('eezyPay:occupation-placeholder')}
                                name="occupation"
                                onChange={(val: string) => {
                                    setOccupationId(Number(val));
                                    setOccupationError(!val);
                                }}
                                options={sortObjectsByLabel(occupationOptions)}
                                optionsLoading={occupationsLoading}
                                required
                                showIcon
                                noWrap
                                selectStyle={inputStyle}
                                value={occupationId?.toString() || ''}
                                error={occupationError}
                                disabled={editMode}
                                tooltip={
                                    editMode
                                        ? t('eezyPay:tooltip.field-disabled')
                                        : t('eezyPay:tooltip.occupation')
                                }
                                tooltipColor={COLOR_WHITE_WALKER}
                                tooltipBackground={editMode ? COLOR_DARK_GRAY : COLOR_BLUM}
                            />
                            {occupationError && renderErrorMessage()}

                            <div style={{ marginTop: '20px' }}>
                                {(serviceNameTermsError || serviceDescriptionTermsError) && (
                                    <>
                                        <SmallP
                                            color={COLOR_IMPORTANT}
                                            style={{
                                                marginTop: -15,
                                                marginBottom: 15,
                                            }}
                                        >
                                            {t('eezyPay:text-validation-error-ln1')}
                                        </SmallP>
                                        <SmallP
                                            color={COLOR_IMPORTANT}
                                            style={{
                                                marginTop: -15,
                                                marginBottom: 15,
                                            }}
                                        >
                                            {t('eezyPay:text-validation-error-ln2-part1')}{' '}
                                            <a
                                                href="mailto:info.kevytyrittajat@eezy.fi"
                                                style={{
                                                    color: COLOR_IMPORTANT,
                                                }}
                                            >
                                                {t('eezyPay:text-validation-error-ln2-part2')}
                                            </a>{' '}
                                            {t('eezyPay:text-validation-error-ln2-part3')}{' '}
                                            <a
                                                href="tel:09472475630"
                                                style={{
                                                    color: COLOR_IMPORTANT,
                                                }}
                                            >
                                                {t('eezyPay:text-validation-error-ln2-part4')}
                                            </a>
                                        </SmallP>
                                    </>
                                )}
                            </div>

                            <div className="flex justify-center my-2">
                                <Button loading={createSalesPageLoading} onClick={onClickSubmit}>
                                    {editMode ? t('eezyPay:update') : t('eezyPay:create')}
                                </Button>
                            </div>

                            <div className="tg-body text-gray-500 text-center mt-5">
                                {t('eezyPay:terms-acceptance-notice')}{' '}
                                <button
                                    type="button"
                                    className="underline pointer text-violet-900"
                                    onClick={() => dispatch(showModals(['EEZY_PAY_TERMS_READ_ONLY']))}
                                >
                                    {t('eezyPay:terms-of-service')}
                                </button>
                            </div>

                            <Toast
                                style={{ width: isMobile() ? '100%' : 641 }}
                                containerId={VAT_TOAST_ID}
                                isLarge
                                hideIcon
                                closeOnClick={false}
                                position="bottom-center"
                            />
                        </div>
                    </>
                )}
            </Card>
            <RelatedObjectMessage displayEezyPayBadge />
        </Wrapper>
    );
};

export default SalesPageCreate;
