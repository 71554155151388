import { Avatar } from 'components/common/Avatar';
import { useNavigationItems, type NavigationItem, type UserDropdownMenuItem } from './useNavigationItems';
import { Link } from 'react-router-dom';
import { Button } from 'components/common/Button';
import { ChevronRight, LogOut } from 'lucide-react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from 'components/ui/dropdown-menu';
import { useUser } from 'queries/useUserQuery';
import { useTranslation } from 'react-i18next';

type MobileNavigationItemProps = {
    item: NavigationItem | UserDropdownMenuItem | Omit<UserDropdownMenuItem, 'Icon'>;
    onLinkClick: () => void;
};

const MobileNavigationItem = ({ item, onLinkClick }: MobileNavigationItemProps) => {
    const innerItem = (
        <button
            className="flex justify-between px-6 py-4 hover:bg-gray-100 active:bg-violet-50 active:text-violet-700 w-full"
            onClick={() => 'onClick' in item && item.onClick?.()}
            type="button"
        >
            <div className="flex gap-5">
                {'Icon' in item && <item.Icon className="size-6 text-gray-800" />}
                <div className="tg-body-medium text-gray-800">{item.text}</div>
            </div>
            {'subItems' in item && item.subItems && <ChevronRight className="size-5 text-gray-800" />}
        </button>
    );

    if ('subItems' in item && item.subItems) {
        return (
            <DropdownMenu>
                <DropdownMenuTrigger className="w-full" asChild>
                    {innerItem}
                </DropdownMenuTrigger>
                <DropdownMenuContent className="z-[99999]" align="end">
                    {item.subItems.map((subItem) => (
                        <MobileNavigationItem key={subItem.text} item={subItem} onLinkClick={onLinkClick} />
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        );
    }

    if (!item.path) return innerItem;

    return (
        <Link to={item.path} className="no-underline" onClick={onLinkClick}>
            {innerItem}
        </Link>
    );
};

export const MobileNavigation = ({ onLinkClick }: { onLinkClick: () => void }) => {
    const user = useUser();
    const { t } = useTranslation();

    const { navigationItems, dropdownMenuItems } = useNavigationItems();

    const groupedNavigation = [navigationItems, ...dropdownMenuItems];
    return (
        <div className="flex flex-col h-full overflow-y-auto">
            <div className="flex gap-4 px-6 pt-3 pb-7">
                <Avatar user={user} asDiv />
                <div>
                    <div className="tg-body-medium text-gray-700">
                        {user?.firstName} {user?.lastName}
                    </div>
                    <div className="tg-caption text-gray-500">{user?.email}</div>
                </div>
            </div>
            {groupedNavigation.map((group, index) => (
                <div key={`dropdown-group-${group[0].text}`}>
                    {index !== 0 && <div className="h-px w-full bg-gray-200 my-3" />}
                    {group.map((item) => (
                        <MobileNavigationItem key={item.text} item={item} onLinkClick={onLinkClick} />
                    ))}
                </div>
            ))}
            <Link to="/logout" className="m-6 mb-8">
                <Button variant="outline" className=" w-full" endIcon={LogOut}>
                    {t('login.logout')}
                </Button>
            </Link>
        </div>
    );
};
