import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { IRootState } from 'reducers';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { showModals, hideModal } from 'actions/auth';
import {
    COLOR_GREYHOUND,
    COLOR_BLUM,
    COLOR_IMPORTANT,
    SCREEN_S,
    COLOR_GREYJOY,
} from '../../styles/variables';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { EezyButton } from '../Buttons';
import { Icon } from '../../components/Icon';
import Expandable from 'components/Expandable';
import Step from 'components/Step';
import GET_SUBSCRIPTION from 'containers/eezypay/queries/getSubscription';
import {
    calcOrderProcessingMinutesDependingOnWeekday,
    daysLeftForSubscription,
    formatDate,
} from 'utils/time';
import SuccessCard from 'containers/eezypay/SuccessCard';
import LoadingSpinner from 'components/Loading';
import { getLocalStorage, setLocalStorage } from '../../utils/user/userUtils';
import { renderOrderProcessingMessage } from './SubscriptionModal';
import CampwirePackages from 'components/CampwirePackages';
import { useUser } from 'queries/useUserQuery';
import { ACCEPT_CAMPWIRE_TERMS, CREATE_USER } from 'containers/eezypay/queries';
import GET_USER from 'containers/eezypay/queries/getUser';
import { toast } from 'react-toastify';
import config from '../../config';

interface IModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
    hasAddress: boolean;
}

const CAMPWIRE_SUBSCRIPTION_BUY_CLICK = 'campwire-subscription-buy-click';
const CAMPWIRE_LOGIN_URL =
    process.env.NODE_ENV === 'development' ? 'https://test.cwxdev.com/' : 'https://app.campwire.com/';

const BulletsContainer = styled.ul`
    list-style-type: disc;
    margin: 10px 10px;

    li {
        margin-bottom: 10px;
        margin-left: 10px;
    }
`;

const IntroText = styled.p`
    color: ${COLOR_GREYHOUND};
`;

// **Updated DisabledMessageList to use COLOR_IMPORTANT and underline only these links**
const DisabledMessageList = styled.ul`
    list-style-type: none; 
    padding: 0;
    margin: 0 0 15px 0;

    li {
        position: relative;
        margin: 0;
        padding-left: 20px;
        color: ${COLOR_IMPORTANT}; // Uses COLOR_IMPORTANT for red color

        &:before {
            content: '•'; 
            position: absolute;
            left: 0;
            color: ${COLOR_IMPORTANT}; 
            font-size: 1.2em; 
        }

        text-decoration: underline; 
        cursor: pointer;
    }
`;

const DisabledMessageItem = styled.li.attrs((props) => ({
    role: 'button',
    'aria-label': props['aria-label'],
}))`
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const ValidSubsciptionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const RenewContainer = styled.div`
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: ${SCREEN_S}px) {
        flex-direction: column;
    }
`;

const RenewContainerLabel = styled.div`
    @media (max-width: ${SCREEN_S}px) {
        margin-bottom: 10px;
    }
`;

const RenewContainerButton = styled.div`
    min-width: 40%;
    @media (max-width: ${SCREEN_S}px) {
        min-width: 100%;
    }
`;

// **Ensure other links retain their original styles**
const LinkButton = styled(EezyButton)`
    align-self: flex-start;
    padding: 0 !important;
    text-decoration: underline;
    border: 0 !important;
    margin: 15px 0;
`;

const LoginButton = styled.a`
    text-decoration: none;
    border: 1px solid ${COLOR_BLUM};
    border-radius: 100px;
    font-size: 14px;
    font-weight: 600;
    height: 38px;
    letter-spacing: -0.5px;
    line-height: 35px;
    min-width: 110px;
    padding: 0px 32px;
    text-align: center;
    text-transform: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    margin: 10px 0;

    @media (max-width: ${SCREEN_S}px) {
        height: 44px;
        line-height: 41px;
    }
`;

const NoticeText = styled.div`
    font-size: 0.9rem;  
    color: ${COLOR_GREYJOY};
    text-align: center;
    margin-top: 20px;
    button {
        background: none;
        border: none;
        color: ${COLOR_BLUM};
        text-decoration: underline;
        cursor: pointer;
        font-size: inherit;  
    }
`;

type SubscriptionDuration = 1 | 3 | 12;

const CampwireModal = (props: IModalProps) => {
    const { hasAddress, isOpen, handleModalClose } = props;
    const user = useUser();

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const campwireTermsAccepted = useSelector<IRootState>((state) => state.eezyPay.campwireTermsAccepted);

    const { data: eezyPayuser, refetch: refetchEezyPayUser } = useQuery(GET_USER);

    const [fetchSubscription, { data: subscriptionData, loading: subscriptionLoading }] = useLazyQuery(
        GET_SUBSCRIPTION,
        {
            context: { clientName: 'eezyPayHasura' },
            fetchPolicy: 'cache-and-network',
        },
    );

    const [acceptCampwireTerms] = useMutation(ACCEPT_CAMPWIRE_TERMS, {
        context: { clientName: 'eezyPayHasura' },
    });

    const [createEezyPayUser] = useMutation(CREATE_USER, {
        context: { clientName: 'eezyPayHasura' },
        onError: () => {
            toast.error(t('errors.general'));
        },
        refetchQueries: [{ query: GET_USER, context: { clientName: 'eezyPayHasura' } }],
    });

    const [token, setToken] = useState(sessionStorage.getItem('jwt'));
    const [selectedPackage, setSelectedPackage] = useState<number>(1);
    const [isProcessing, setIsProcessing] = useState(false);
    const [processingError, setProcessingError] = useState<string | null>(null);

    const campwireSubscriptionEndDate = subscriptionData?.subscriptions[0]?.campwireEndDate;
    const campwireSubscriptionDaysLeft = daysLeftForSubscription(campwireSubscriptionEndDate);
    const campwireSubscriptionExpired = campwireSubscriptionDaysLeft < 0;

    const bankAccountNumber = user?.bankAccountNumber;
    const isDisabled = !user?.isIdentified || !bankAccountNumber || !hasAddress;
    const readMoreUrl =
        i18n.language === 'fi'
            ? 'https://kevytyrittajat.eezy.fi/campwire-verkkokurssialusta/'
            : 'https://kevytyrittajat.eezy.fi/en/campwire-online-course-platform/';

    const packageOptions = [
        {
            value: '1',
            label: t('campwire-modal.package-1-label'),
            price: t('campwire-modal.package-1-price'),
        },
        {
            value: '3',
            label: t('campwire-modal.package-3-label'),
            price: t('campwire-modal.package-3-price'),
        },
        {
            value: '12',
            label: t('campwire-modal.package-12-label'),
            price: t('campwire-modal.package-12-price'),
        },
    ];

    const steps = [
        { number: '1', content: t('campwire-modal.list-2'), name: 'SELECT_PACKAGE' },
        { number: '2', content: t('campwire-modal.list-3'), name: 'CREATE_ACCOUNT' },
        { number: '3', content: t('campwire-modal.list-4'), name: 'READY' },
    ];

    useEffect(() => {
        if (token) {
            fetchSubscription();
        }
    }, [fetchSubscription, token]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!token) {
                setToken(sessionStorage.getItem('jwt'));
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [token]);

    // **Define the getDisabledMessage function**
    const getDisabledMessage = () => {
        const messages = [];
        if (!user?.isIdentified)
            messages.push({ text: t('campwire-modal.complete-identification'), type: 'isIdentified' });
        if (!bankAccountNumber)
            messages.push({ text: t('campwire-modal.add-bank-account'), type: 'bankAccountNumber' });
        if (!hasAddress) messages.push({ text: t('campwire-modal.add-address'), type: 'hasAddress' });

        if (messages.length === 0) return null; // No messages to display

        return (
            <DisabledMessageList>
                <IntroText>{t('campwire-modal.you-still-need-to')}</IntroText>
                {messages.map((msg) => (
                    <DisabledMessageItem
                        key={msg.type}
                        aria-label={msg.text}
                        onClick={() => {
                            handleClick(msg.type);
                        }}
                    >
                        {msg.text}
                    </DisabledMessageItem>
                ))}
            </DisabledMessageList>
        );
    };

    const getPurchaseButtonText = () => {
        switch (selectedPackage) {
            case 1:
                return t('campwire-modal.purchase-button-1');
            case 3:
                return t('campwire-modal.purchase-button-3');
            case 12:
                return t('campwire-modal.purchase-button-12');
            default:
                return t('campwire-modal.purchase-button-1');
        }
    };

    const handleConfirm = async () => {
        try {
            setIsProcessing(true);
            setProcessingError(null);

            if (!campwireTermsAccepted) {
                if (!eezyPayuser) {
                    if (process.env.NODE_ENV === 'development') {
                        console.info('[DEV] Proceeding without EezyPay in development environment');
                    } else {
                        throw new Error(t('campwire-modal.error-creating-user'));
                    }
                } else if (eezyPayuser.users.length === 0) {
                    await createEezyPayUser({
                        variables: {
                            firstName: user?.firstName,
                            lastName: user?.lastName,
                            language: i18n.language,
                            email: user?.email,
                            phone: `+${user?.phoneCountryCode} ${user?.phone}`,
                            userAccountId: user?.accountId,
                            campwireTermsAccepted: true,
                            termsAccepted: true,
                        },
                    });

                    const refetchResult = await refetchEezyPayUser();
                    if (refetchResult.data.users.length === 0) {
                        throw new Error(t('campwire-modal.error-creating-user'));
                    }
                } else {
                    await acceptCampwireTerms({
                        variables: {
                            id: user?.id,
                        },
                    });
                }
            }

            const subscriptionLink = {
                1: config[t('config.campwire.subscription_url_1') as keyof typeof config] as string,
                3: config[t('config.campwire.subscription_url_3') as keyof typeof config] as string,
                12: config[t('config.campwire.subscription_url_12') as keyof typeof config] as string,
            }[selectedPackage];

            setLocalStorage(CAMPWIRE_SUBSCRIPTION_BUY_CLICK, {});
            const win = window.open(subscriptionLink, '_blank');
            if (win) {
                win.focus();
                handleModalClose();
            } else {
                throw new Error(t('campwire-modal.error-opening-window'));
            }
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.info('[DEV] Error in Campwire purchase flow:', error);
            }
            setProcessingError(
                error instanceof Error ? error.message : t('campwire-modal.error-creating-user'),
            );
        } finally {
            setIsProcessing(false);
        }
    };

    const handleTrackPaymentsClick = () => {
        dispatch(hideModal('CAMPWIRE'));
        navigate('/sales-pages/payments');
    };

    const { minutesSince: minutesSinceLastBuySubscriptionClick } =
        getLocalStorage(CAMPWIRE_SUBSCRIPTION_BUY_CLICK) || {};

    const handleClick = (type: string) => {
        dispatch(hideModal('CAMPWIRE'));
        handleModalClose();

        switch (type) {
            case 'isIdentified':
                dispatch(showModals(['AUTH']));
                break;
            case 'bankAccountNumber':
                navigate('/profile/salary');
                break;
            case 'hasAddress':
                navigate('/profile/contact');
                break;
            default:
                break;
        }
    };

    const GetStartedContainer = () => (
        <>
            <Expandable title={t('campwire-modal.what-is-campwire')}>
                <BulletsContainer>
                    <li>{t('campwire-modal.bulletpoint-1')}</li>
                    <li>{t('campwire-modal.bulletpoint-2')}</li>
                    <li>{t('campwire-modal.bulletpoint-3')}</li>
                    <li>{t('campwire-modal.bulletpoint-4')}</li>
                </BulletsContainer>

                <p>
                    <a
                        href={readMoreUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: COLOR_BLUM }}
                    >
                        <strong>{t('campwire-modal.read-more')}</strong>
                        <Icon
                            className="small"
                            color={COLOR_BLUM}
                            icon={['far', 'arrow-up-right-from-square']}
                            style={{ marginLeft: 10 }}
                        />
                    </a>
                </p>
            </Expandable>

            <strong>{t('campwire-modal.how-to-start')}</strong>

            <div>
                {steps.map((step) => {
                    if (step.name === 'SELECT_PACKAGE') {
                        return (
                            <div key={step.number}>
                                <Step stepNumber={step.number} stepContent={step.content} />
                                <CampwirePackages
                                    options={packageOptions}
                                    selected={selectedPackage}
                                    onSelect={(value) => {
                                        setSelectedPackage(Number.parseInt(value));
                                    }}
                                />
                            </div>
                        );
                    }
                    return <Step stepNumber={step.number} stepContent={step.content} key={step.number} />;
                })}
            </div>

            {/* **Integrate getDisabledMessage here, under the third step and before the purchase button** */}
            {getDisabledMessage()}
        </>
    );

    const SubscriptionDetailsContainer = () => (
        <>
            <ValidSubsciptionContainer>
                <ImageContainer>
                    <SuccessCard
                        title={
                            t('campwire-modal.subscription-valid-until', {
                                date: formatDate(campwireSubscriptionEndDate),
                            }) || ''
                        }
                        titleStyles={{ maxWidth: 'none' }}
                    />
                </ImageContainer>
                <RenewContainer>
                    <RenewContainerLabel style={{ marginRight: 20, fontSize: 14 }}>
                        {t('campwire-modal.renew-label')}
                    </RenewContainerLabel>
                    <RenewContainerButton>
                        <EezyButton
                            style={{ width: '100%' }}
                            autoFocus
                            dark
                            color="purple"
                            onClick={() => {
                                dispatch(showModals(['CAMPWIRE_RENEW']));
                            }}
                            type="submit"
                        >
                            {t('campwire-modal.renew-button')}
                        </EezyButton>
                    </RenewContainerButton>
                </RenewContainer>
                <LoginButton href={CAMPWIRE_LOGIN_URL} rel="noopener noreferrer" target="_blank">
                    {t('campwire-modal.campwire-login')}
                </LoginButton>
                <LinkButton
                    color="purple"
                    onClick={() => {
                        handleTrackPaymentsClick();
                    }}
                    type="submit"
                >
                    {t('campwire-modal.track-payments')}
                    <Icon
                        className="small"
                        color={COLOR_BLUM}
                        icon={['far', 'circle-right']}
                        style={{ marginLeft: 8 }}
                    />
                </LinkButton>
                <strong style={{ fontSize: 14 }}>{t('campwire-modal.campwire-support')}</strong>
                <div>
                    <a
                        href="mailto:tuki@campwire.com"
                        style={{ textDecoration: 'none', color: COLOR_GREYHOUND }}
                    >
                        tuki@campwire.com
                    </a>
                </div>
            </ValidSubsciptionContainer>
        </>
    );

    return (
        <Modal
            huge
            id="campwire-about"
            isOpen={isOpen}
            onClose={handleModalClose}
            title={campwireSubscriptionExpired && t('campwire-modal.title')}
        >
            <ModalContent $huge>
                {subscriptionLoading ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LoadingSpinner size={24} color={COLOR_BLUM} />
                    </div>
                ) : campwireSubscriptionExpired ? (
                    GetStartedContainer()
                ) : (
                    SubscriptionDetailsContainer()
                )}
            </ModalContent>

            <ModalActions style={{ flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                {subscriptionLoading ? null : campwireSubscriptionExpired ? (
                    <EezyButton
                        disabled={isDisabled || isProcessing}
                        style={{ minWidth: '50%', margin: '5px auto' }}
                        autoFocus
                        dark
                        color="purple"
                        onClick={() => {
                            handleConfirm();
                        }}
                        type="submit"
                    >
                        {isProcessing ? <LoadingSpinner size={16} color="#fff" /> : getPurchaseButtonText()}
                    </EezyButton>
                ) : (
                    <EezyButton
                        disabled={isDisabled}
                        style={{ minWidth: '50%', margin: '5px auto' }}
                        autoFocus
                        color="purple"
                        onClick={() => {
                            handleModalClose();
                        }}
                        type="submit"
                    >
                        {t('campwire-modal.close')}
                    </EezyButton>
                )}
                {processingError && <div className="text-red-500 mb-4 text-sm">{processingError}</div>}
                {isProcessing && (
                    <div className="text-gray-600 mb-4 text-sm text-center">
                        {t('campwire-modal.processing')}
                    </div>
                )}
                {minutesSinceLastBuySubscriptionClick <
                    calcOrderProcessingMinutesDependingOnWeekday(minutesSinceLastBuySubscriptionClick) &&
                    campwireSubscriptionExpired &&
                    renderOrderProcessingMessage()}

                <NoticeText>
                    {t('campwire-modal.terms-acceptance-notice')}{' '}
                    <button type="button" onClick={() => dispatch(showModals(['EEZY_PAY_TERMS_READ_ONLY']))}>
                        {t('campwire-modal.terms-of-service')}
                    </button>
                </NoticeText>
            </ModalActions>
        </Modal>
    );
};

export default CampwireModal;
