import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from 'utils';

const SwitchElement = React.forwardRef<
    React.ElementRef<typeof SwitchPrimitives.Root>,
    React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
    <SwitchPrimitives.Root
        className={cn(
            'peer inline-flex h-switch w-8 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-violet-500 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-40 group-[.error]:focus-visible:ring-red-500 data-[state=checked]:bg-violet-800 data-[state=checked]:group-[.error]:bg-red-700 data-[state=checked]:hover:bg-violet-900 data-[state=unchecked]:bg-gray-400 data-[state=unchecked]:hover:bg-gray-500',
            className,
        )}
        {...props}
        ref={ref}
    >
        <SwitchPrimitives.Thumb
            className={cn(
                'pointer-events-none block h-3.5 w-3.5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-3.5 data-[state=unchecked]:translate-x-0',
            )}
        />
    </SwitchPrimitives.Root>
));
SwitchElement.displayName = SwitchPrimitives.Root.displayName;

interface SwitchProps extends React.ComponentPropsWithoutRef<typeof SwitchElement> {
    label: string;
}

export function Switch(props: SwitchProps) {
    const { label, ...switchProps } = props;
    const uuid = React.useRef<string>(Math.random().toString(36).substring(7));

    return (
        <div className="flex items-center gap-3 text-gray-700 hover:text-gray-950">
            <SwitchElement id={uuid.current} {...switchProps} />
            <label
                htmlFor={uuid.current}
                className={cn(
                    'tg-body-medium',
                    props.checked && 'text-gray-950',
                    props.disabled && 'text-gray-500',
                )}
            >
                {label}
            </label>
        </div>
    );
}
