import type { ReactNode } from 'react';

type ToastBodyProps = {
    title?: string;
    text?: string;
    icon?: ReactNode;
    truncate?: boolean; // Icon will also align top instead of center
    headSlot?: ReactNode;
    tailSlot?: ReactNode;
};

export const ToastBody = (props: ToastBodyProps) => {
    const { truncate } = props;

    return (
        <div className={['flex gap-5 w-100', truncate || !props.text ? 'items-center' : ''].join(' ')}>
            {props.icon ? (
                <div className="text-xl [&>svg]:!mr-0 [&>svg]:!w-5 [&>svg]:!h-5">{props.icon}</div>
            ) : null}
            <div className="flex flex-col gap-1 flex-grow">
                {props.title ? <p className="tg-body-bold">{props.title}</p> : null}
                {props.text ? (
                    <div className={truncate ? 'max-w-80' : ''}>
                        <p className={['tg-body text-gray-500', truncate ? 'truncate' : ''].join(' ')}>
                            {props.text}
                        </p>
                    </div>
                ) : null}
            </div>
            {props.tailSlot ? <div>{props.tailSlot}</div> : null}
        </div>
    );
};
