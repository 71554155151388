import type { MutationFunctionOptions } from '@apollo/client';
import { CheckBox } from 'components/ui/checkbox';
import { useTranslation } from 'react-i18next';
import { useUser } from 'queries/useUserQuery';

interface IProps {
    updateUserData: (options?: MutationFunctionOptions<any, Record<string, any>> | undefined) => Promise<any>;
}

export const ProfileSettingsNotifyInvoicePaid = (props: IProps) => {
    const { t } = useTranslation();
    const user = useUser();
    const handleNotifyUpdate = (value: boolean) => {
        props.updateUserData({
            optimisticResponse: {
                updateUserData: {
                    __typename: 'User',
                    ...user,
                    notifyWhenInvoicePaid: value,
                },
            },
            variables: {
                notifyWhenInvoicePaid: value,
            },
        });
    };

    return (
        <CheckBox
            required
            id="email-notification"
            name="email-notification"
            value={'notifyWhenInvoicePaid'}
            checked={user?.notifyWhenInvoicePaid}
            label={t('profile:settings.payment-email-notification')}
            onCheckedChange={(checked) => handleNotifyUpdate(!!checked.valueOf())}
        />
    );
};
