import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AutoSaveCard } from 'components/cards';
import { P } from 'components/textElements';
import ContractsModal from './ContractsModal';
import { showModals } from 'actions/auth';
import { Button } from 'components/common/Button';

const ProfileImportant = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const [contractsModalOpen, setContractsModalOpen] = useState(false);

    const location = useLocation();

    const scrollRef = useRef<HTMLDivElement>(null);
    const path = location.pathname;
    const pathSubstring = path.substring(path.lastIndexOf('/')); // TODO: Is it correct? Test it.

    useEffect(() => {
        if (pathSubstring === '/documents' && scrollRef.current) {
            window.requestAnimationFrame(() =>
                scrollRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                }),
            );
        }
    }, [pathSubstring]);

    return (
        <div ref={scrollRef}>
            <AutoSaveCard title={t('profile:important.title')}>
                <div className="flex items-center justify-between">
                    <p className="tg-body-bold">
                        <b>{t('profile:important.restricted-work.title')}</b>
                    </p>
                    <Button variant="outline" onClick={() => dispatch(showModals(['RESTRICTED_WORK']))}>
                        {t('profile:important.open')}
                    </Button>
                </div>

                <p className="tg-body">{t('profile:important.restricted-work.description')}</p>

                <div className="flex items-center justify-between mt-4">
                    <p className="tg-body-bold">
                        <b>{t('profile:important.gdpr.title')}</b>
                    </p>
                    <Button variant="outline" onClick={() => dispatch(showModals(['GDPR']))}>
                        {t('profile:important.open')}
                    </Button>
                </div>

                <p>{t('profile:important.gdpr.description')}</p>

                <div className="flex items-center justify-between mt-4">
                    <p className="tg-body-bold">
                        <b>{t('profile:important.contracts.title')}</b>
                    </p>
                    <Button variant="outline" onClick={() => setContractsModalOpen(true)}>
                        {t('profile:important.open')}
                    </Button>
                </div>

                <P>{t('profile:important.contracts.description')}</P>

                <div className="flex items-center justify-between mt-4">
                    <p className="tg-body-bold">
                        <b>{t('profile:important.regCert.title')}</b>
                    </p>
                    <Button variant="outline" onClick={() => dispatch(showModals(['REG_CERT']))}>
                        {t('profile:important.download')}
                    </Button>
                </div>

                {contractsModalOpen && (
                    <ContractsModal
                        handleModalClose={() => setContractsModalOpen(false)}
                        isOpen={contractsModalOpen}
                    />
                )}
            </AutoSaveCard>
        </div>
    );
};

export default ProfileImportant;
