import { useState } from 'react';
import { toast } from 'react-toastify';
import { type ApolloError, useMutation } from '@apollo/client';
import { CREATE_COWORKER, GET_COWORKERS } from '../../containers/profile/queries';
import { COLOR_BLACKWATER } from 'styles/variables';
import { getErrorKey, getErrors } from 'utils/apolloErrors';
import { FormInput } from '../form';
import { UniqueTitle } from '../textElements';
import { Modal, ModalActions, ModalContent, ModalHeader } from './Modal';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';

interface IInviteCoworkerModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
}

const InviteCoworkerModal = (props: IInviteCoworkerModalProps) => {
    const { t } = useTranslation();

    const [inviteEmail, setInviteEmail] = useState<string>('');

    const [createCoworker] = useMutation(CREATE_COWORKER, {
        onCompleted: () => {
            props.handleModalClose();
            toast(t('profile:group-invoicing.user-added-toast'));
        },
        onError: (e: ApolloError) => {
            if ((getErrorKey(e) || '').includes('contact_already_exists')) {
                toast.error(t('profile:group-invoicing.invite-coworker-modal.errors.contact-already-exists'));
            } else {
                if (getErrors(e)?.error?.includes('NOT_FOUND')) {
                    toast.error(
                        t('profile:group-invoicing.invite-coworker-modal.errors.contact-is-not-exist'),
                    );
                } else {
                    toast.error(t('errors.general'));
                }
            }
        },
        refetchQueries: [{ query: GET_COWORKERS }],
    });

    const handleCreateCoworker = () => {
        if (inviteEmail) {
            createCoworker({
                variables: {
                    email: inviteEmail,
                },
            });
        }
    };

    return (
        <Modal id="modal-strong-auth" isOpen={props.isOpen} onClose={props.handleModalClose} noscroll>
            <ModalHeader>
                <UniqueTitle
                    color={COLOR_BLACKWATER}
                    style={{
                        lineHeight: '35px',
                        padding: '15px 0 0 0',
                    }}
                >
                    {t('profile:group-invoicing.invite-coworker-modal.title')}
                </UniqueTitle>
            </ModalHeader>

            <ModalContent>
                <div>
                    <p>{t('profile:group-invoicing.invite-coworker-modal.description-1')}</p>
                    <p>{t('profile:group-invoicing.invite-coworker-modal.description-2')}</p>
                </div>
                <div className="mt-4">
                    <FormInput
                        label={t('profile:group-invoicing.invite-coworker-modal.email') || ''}
                        onChange={(val) => setInviteEmail(val)}
                        name="invite-email"
                        placeholder={'essi.esimerkki@eezy.fi'}
                        required
                        value={inviteEmail}
                    />
                </div>
            </ModalContent>

            <ModalActions>
                <Button variant="outline" onClick={props.handleModalClose}>
                    {t('general.cancel')}
                </Button>
                <Button onClick={handleCreateCoworker}>{t('invoice.send')}</Button>
            </ModalActions>
        </Modal>
    );
};

export default InviteCoworkerModal;
