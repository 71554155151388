import React, { type CSSProperties } from 'react';
import ChangeTaxRateModal from './ChangeTaxRateModal';
import { Button } from 'components/common/Button';
import { useTranslation } from 'react-i18next';

interface IProps {
    disabled?: boolean;
    style?: CSSProperties;
}

export const ChangeTaxRate = (props: IProps) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const { t } = useTranslation();

    return (
        <div style={props.style}>
            <Button disabled={props.disabled} onClick={() => setModalOpen(true)}>
                {t('salaries:changeTaxRate')}
            </Button>
            {modalOpen && (
                <ChangeTaxRateModal handleModalClose={() => setModalOpen(false)} isOpen={modalOpen} />
            )}
        </div>
    );
};
