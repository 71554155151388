import {} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { COLOR_BLUM, COLOR_LILA, COLOR_LILA_LIGHT, COLOR_WHITE_WALKER } from 'styles/variables';
import { buildVersion } from 'utils/version';
import { GET_BACKEND_VERSION } from './queries';
import { trpc } from 'trpc';

const Wrapper = styled.div`
    background-color: ${COLOR_WHITE_WALKER};
    bottom: 0;
    left: 0;
    padding: 0;
    position: fixed;
    right: 0;
    z-index: 4;

    & > div {
        background-color: ${COLOR_LILA_LIGHT};
        color: ${COLOR_BLUM};
        font-size: 15px;
        padding: 15px;
        text-align: center;

        & button {
            margin-left: 20px;
        }
    }
`;

const VersionMismatch = () => {
    const { t } = useTranslation();
    const frontEndVersion = buildVersion?.split(' ')?.[0];

    const { data: newBackendVersion, isLoading: isLoadingNewBackendVersion } = trpc.version.useQuery(
        undefined,
        { refetchInterval: 3 * 60 * 1000 },
    );
    const { data: oldBackendVersion, loading: isLoadingOldBackendVersion } = useQuery(GET_BACKEND_VERSION, {
        pollInterval: 3 * 60 * 1000,
    });

    const handleUpdate = () => {
        window.location.reload();
    };

    const backendOutdated =
        !isLoadingNewBackendVersion &&
        !isLoadingOldBackendVersion &&
        [oldBackendVersion?.backendVersion, newBackendVersion].some(
            (version) => frontEndVersion !== version?.split(' ')?.[0],
        );

    return (
        <>
            {backendOutdated && (
                <Wrapper>
                    <div>
                        {t('errors.update-available')}
                        <EezyButton
                            color="purple"
                            dark
                            hasIcon
                            iconAlignment="right"
                            onClick={handleUpdate}
                            width={130}
                        >
                            {t('errors.update-available-button')}
                            <Icon color={COLOR_LILA} icon={['fas', 'redo']} />
                        </EezyButton>
                    </div>
                </Wrapper>
            )}
        </>
    );
};

export default VersionMismatch;
