import type { FC } from 'react';
import EmptyGraphics from '../assets/images/empty-graphics.svg';

interface IProps {
    text: string;
    textColor?: string;
}

export const EmptyListPlaceholder: FC<IProps> = ({ text, textColor }) => {
    return (
        <div className="flex flex-col items-center justify-center w-full">
            <p className="py-4 px-5 tg-body" style={{ color: textColor }}>
                {text}
            </p>
            <img
                alt="Empty graphics"
                src={EmptyGraphics}
                className="m-8 mr-0"
                style={{
                    width: '65%',
                }}
            />
        </div>
    );
};
