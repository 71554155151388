import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ErrorBox from '../../components/ErrorBox';
import { formatCents, trans } from '../../utils';
import type { ISalesPage } from './SalesPageCreate';
import { QRCodeContainer, ShareWrapper } from './SalesPages';
import TwoColumnCard from './TwoColumnCard';
import { Button } from 'components/common/Button';
import { Link } from 'lucide-react';

const DetailsRow = styled.div<{ column?: boolean }>`
    display: flex;
    margin-bottom: 14px;
    ${(props) => props.column && 'flex-direction: column'}
`;

const DetailsValue = styled.div<{ left?: boolean }>`
    margin-left: ${(props) => (!props.left ? 'auto' : '0')};
`;

interface ISalesPageDetailsProps {
    page: ISalesPage;
    qrCodeUrl?: string;
    handleCopyLinkClick?: () => void;
    handleDeleteClick?: () => void;
}

const SalesPageDetails: React.FC<ISalesPageDetailsProps> = (props: ISalesPageDetailsProps) => {
    const { page, qrCodeUrl, handleCopyLinkClick, handleDeleteClick } = props;
    const amountOfPayments = page.transactions_aggregate.aggregate.count;

    const navigate = useNavigate();

    return (
        <TwoColumnCard
            title={page.serviceName}
            column1={
                <>
                    {page.state === 0 && (
                        <ErrorBox style={{ margin: 50 }}>{trans('eezyPay:closed')}</ErrorBox>
                    )}
                    {page.state === 2 && (
                        <ErrorBox style={{ margin: 50 }}>{trans('eezyPay:waiting-for-approval')}</ErrorBox>
                    )}
                    <DetailsRow>
                        <div>{trans('eezyPay:price')}: </div>
                        <div className="ml-auto">
                            {trans('form.eurs', {
                                eurs: formatCents(page.servicePrice, true),
                            })}
                        </div>
                    </DetailsRow>
                    {page.serviceDescription && (
                        <DetailsRow column>
                            <div>{trans('eezyPay:description')}:</div>
                            <div className="ml-0" data-mf-replace="**REMOVED**">
                                {page.serviceDescription}
                            </div>
                        </DetailsRow>
                    )}
                    <DetailsRow>
                        <div>{trans('eezyPay:payments-amount')}: </div>
                        <div className="ml-auto">{amountOfPayments}</div>
                    </DetailsRow>
                    <div className="flex flex-col items-stretch gap-3" style={{ marginTop: '70px' }}>
                        <Button
                            variant="outline"
                            onClick={() => navigate(`/sales-pages/edit/${page.publicPath}`)}
                            disabled={page.state === 0}
                        >
                            {trans('eezyPay:edit-page')}
                        </Button>
                        <Button variant="outline" onClick={handleDeleteClick}>
                            {trans('eezyPay:delete-page')}
                        </Button>
                    </div>
                </>
            }
            column2={
                <ShareWrapper>
                    <QRCodeContainer>
                        <img src={qrCodeUrl} alt="" />
                    </QRCodeContainer>
                    <Button
                        className="w-full"
                        endIcon={Link}
                        disabled={page.state !== 1}
                        onClick={handleCopyLinkClick}
                    >
                        {trans('eezyPay:copy-link')}
                    </Button>
                </ShareWrapper>
            }
        />
    );
};

export default SalesPageDetails;
