import { type FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { COLOR_BLACKWATER, COLOR_STATUS_DONE, SCREEN_S } from 'styles/variables';
import { formatCents, formatDate, formatPercentage } from 'utils';
import { DownloadSalaries } from './DownloadSalaries';
import { DownloadTaxReport } from './DownloadTaxReport';
import { ChangeTaxRate } from './ChangeTaxRate';
import { GET_SALARIES } from './queries';
import TaxCardNote from './TaxCardNote';
import type { IRootState } from 'reducers';
import { useUserQuery } from 'queries/useUserQuery';
import type { IWithholdingTax } from '../../../../shared/src/types/user';
import GET_WITHHOLDING_TAX from 'containers/profile/queries/getWithholdingTax';

const TaxActions = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    & div:first-child {
        & button {
            margin-bottom: 16px;
        }
    }

    && {
        @media (min-width: ${SCREEN_S}px) {
            width: 100%;
            justify-content: flex-end;
            flex-direction: row;

            & div:first-child {
                & button {
                    margin-right: 16px;
                    margin-bottom: 0;
                }
            }
        }
    }
`;

const TaxRateIndicator = styled('div')<{ taxRateUpated: boolean }>`
    font-weight: ${(props) => (props.taxRateUpated ? '600' : '400')};
    position: relative;
    padding-left: ${(props) => (props.taxRateUpated ? '18px' : '0')};
    line-height: 28px;

    &::before {
        content: '';
        position: absolute;
        background: ${COLOR_STATUS_DONE};
        border-radius: 50%;
        width: 6px;
        height: 6px;
        left: 0;
        top: calc(50% - 3px);
        display: ${(props) => (props.taxRateUpated ? 'block' : 'none')};
    }
`;

const SalarySummary: FC = () => {
    const { t } = useTranslation();
    const { data: user, refetch } = useUserQuery();

    const taxCardFile = useSelector((state: IRootState) => state.file.taxCard);

    const { data: withholdingTaxData } = useQuery<{ userWithholdingTax: IWithholdingTax }>(
        GET_WITHHOLDING_TAX,
        {
            skip: !user?.isIdentified,
        },
    );

    const nextTaxCardStartDate: string | undefined = user?.nextTaxCardStartDate
        ? user?.nextTaxCardStartDate
        : undefined;

    const { data: salaryData } = useQuery(GET_SALARIES, {
        variables: { page: { offset: 0, pageSize: 20 } },
    });

    const salariesAmount = salaryData?.salaries?.items.length;

    const [taxRateUpated, setTaxRateUpated] = useState(false);

    useEffect(() => {
        refetch();
    }, [refetch, taxCardFile]);

    useEffect(() => {
        const { status, addPercentage, basicPercentageLimit, basicPercentage, startDate } =
            withholdingTaxData?.userWithholdingTax || {};

        if (status === 'ok' && (addPercentage || basicPercentageLimit || basicPercentage || startDate)) {
            refetch();
            setTaxRateUpated(true);
        } else {
            setTaxRateUpated(false);
        }
    }, [refetch, withholdingTaxData]);

    return (
        <div className="flex flex-col">
            {user && (
                <TaxCardNote
                    nextTaxCardStartDate={nextTaxCardStartDate}
                    taxCardMissing={!user?.taxCardDetails}
                    taxCardPending={!!user?.taxCardUploadedAt}
                    taxCardNotValidYet={!!user?.nextTaxCardStartDate}
                    isIdentified={user?.isIdentified}
                />
            )}
            {!!user?.taxCardDetails && (
                <div className="bg-white p-6 mb-5 rounded-xl">
                    <h3 className="tg-heading-3 mb-5">
                        {t('salaries:taxCard.title')} {new Date().getFullYear()}
                    </h3>

                    {user?.taxCardDetails && (
                        <div className="flex flex-col gap-2">
                            <div className="flex justify-between w-full">
                                <span className="tg-body">{t('salaries:taxCard.valid')}</span>
                                <span className="tg-body">
                                    {formatDate(user?.taxCardDetails?.startDate)} -{' '}
                                    {formatDate(user?.taxCardDetails?.endDate)}
                                </span>
                            </div>

                            <div className="flex justify-between w-full">
                                <span className="tg-body">{t('salaries:incomeLimit')}</span>
                                <span className="tg-body">
                                    {t('form.eurs', {
                                        eurs: formatCents(
                                            user?.taxCardDetails?.basicPercentageLimit ?? 0,
                                            true,
                                        ),
                                    })}
                                </span>
                            </div>

                            <div className="flex justify-between w-full">
                                <TaxRateIndicator
                                    as="label"
                                    htmlFor="taxrate"
                                    color={COLOR_BLACKWATER}
                                    taxRateUpated={taxRateUpated}
                                >
                                    {t('salaries:taxRate')}
                                </TaxRateIndicator>
                                {taxRateUpated ? (
                                    <span id="taxrate" className="tg-body-bold">
                                        <b>
                                            {t('form.percent', {
                                                percentage: formatPercentage(
                                                    Number.parseFloat(
                                                        Number.parseFloat(
                                                            user?.taxCardDetails?.basicPercentage,
                                                        ),
                                                    ),
                                                ),
                                            })}
                                        </b>
                                    </span>
                                ) : (
                                    <span id="taxrate" className="tg-body">
                                        {t('form.percent', {
                                            percentage: formatPercentage(
                                                Number.parseFloat(
                                                    Number.parseFloat(user?.taxCardDetails?.basicPercentage),
                                                ),
                                            ),
                                        })}
                                    </span>
                                )}
                            </div>

                            <div className="flex justify-between w-full">
                                <span className="tg-body">{t('salaries:taxRateAdditional')}</span>
                                <span className="tg-body">
                                    {t('form.percent', {
                                        percentage: formatPercentage(
                                            Number.parseFloat(user?.taxCardDetails?.addPercentage),
                                        ),
                                    })}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className="text-right mt-5">
                        <ChangeTaxRate />
                    </div>
                </div>
            )}
            {!!user?.taxCardDetails && (
                <TaxActions>
                    <DownloadSalaries disabled={!salariesAmount || salariesAmount === 0} />
                    <DownloadTaxReport disabled={!salariesAmount || salariesAmount === 0} />
                </TaxActions>
            )}
        </div>
    );
};

export default SalarySummary;
