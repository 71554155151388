import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { cn } from 'utils';
import { useTranslation } from 'react-i18next';
import { EezyButton } from '../Buttons';
import LoadingSpinner from 'components/Loading';
import { IconButton } from 'components/common/IconButton';
import { X } from 'lucide-react';

export const RootDialog = DialogPrimitive.Root;

export const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Overlay
        ref={ref}
        className={cn(
            'fixed inset-0 z-50 bg-black/60  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
            className,
        )}
        {...props}
    />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

export const DialogContent = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
    <DialogPortal>
        <DialogOverlay />
        <DialogPrimitive.Content
            ref={ref}
            className={cn(
                'fixed left-[50%] top-[50%] px-6 z-50 w-full max-w-lg translate-x-[-50%] translate-y-[-50%] border bg-background shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
                className,
            )}
            {...props}
        >
            {children}
        </DialogPrimitive.Content>
    </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

export const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('flex flex-col text-center sm:text-left py-4 pr-[25px]', className)} {...props} />
);
DialogHeader.displayName = 'DialogHeader';

export const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            'flex flex-col-reverse gap-2 sm:flex-row-reverse sm:justify-between sm:space-x-2 p-6',
            className,
        )}
        {...props}
    />
);
DialogFooter.displayName = 'DialogFooter';

export const DialogTitle = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Title ref={ref} className={cn('tg-subtitle', className)} {...props} />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Description
        ref={ref}
        className={cn('text-sm text-muted-foreground', className)}
        {...props}
    />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

// This following part is our custom dialog component, the ones above are generated with shadcn-ui

type DialogProps = {
    triggerComponent: React.ReactNode;
    children: ((close: () => void) => React.ReactNode) | React.ReactNode;
    title: string;
    handleSubmit: (close: () => void) => void;
    description?: string;
    submitDisabled?: boolean;
    submitText?: string;
    loading?: boolean;
};

const Dialog = ({
    children,
    triggerComponent,
    title,
    handleSubmit,
    description,
    submitDisabled,
    submitText,
    loading,
}: DialogProps) => {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);

    return (
        <RootDialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{triggerComponent}</DialogTrigger>
            <DialogContent className="sm:max-w-[600px] max-h-[calc(-120px+100vh)] dialog flex flex-col">
                <DialogHeader className="px-6 pt-6 pb-2">
                    <div className="flex justify-between items-center">
                        <DialogTitle>{title}</DialogTitle>

                        <DialogClose>
                            <IconButton Icon={X} />
                            <span className="sr-only">Close</span>
                        </DialogClose>
                    </div>
                    {description && <DialogDescription>{description}</DialogDescription>}
                </DialogHeader>
                <div className="overflow-auto px-6">
                    {typeof children === 'function' ? children(() => setOpen(false)) : children}
                </div>
                <DialogFooter className="px-6 py-6 sm:px-12">
                    <DialogClose asChild>
                        <EezyButton color="purple">{t('general.cancel')}</EezyButton>
                    </DialogClose>
                    <EezyButton
                        autoFocus
                        color="purple"
                        dark
                        onClick={() => handleSubmit(() => setOpen(false))}
                        type="submit"
                        disabled={submitDisabled}
                        className="!ml-0 flex gap-4 items-center"
                    >
                        <span>{submitText ? submitText : t('general.save')}</span>
                        {loading && <LoadingSpinner size="1em" />}
                    </EezyButton>
                </DialogFooter>
            </DialogContent>
        </RootDialog>
    );
};

export default Dialog;
