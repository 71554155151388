import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import type { ThunkDispatch } from 'redux-thunk';
import { type ApolloError, useMutation } from '@apollo/client';
import { Hidden } from '@mui/material';
import type { IUserBasicData, IUserDataInput } from '../../../../shared/src/types/user';
import { type IStrongAuthData, strongAuth } from 'actions/auth';
import { ManualCard } from 'components/cards';
import { getErrorKey } from 'utils/apolloErrors';
import { getCountriesOptions, getCountryCodeOptions } from 'utils/profile/contact';
import { contactValidator } from 'utils/profile/validators';
import { sortObjectsByLabel } from 'utils/str';
import { EMAIL_REGEX, formatValidationResult } from 'utils/validation';
import { UPDATE_USER } from '../eezypay/queries';
import { UPDATE_USER_DATA } from './queries';
import API from 'utils/API';
import {
    didEmailVerificationFailed,
    RenderEmailValidationMessage,
    VERIFY_EMAIL_TIMEOUT_MS,
} from '../login/Register';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'components/common/Button';
import { Check } from 'lucide-react';
import Select from 'components/ui/select';
import { Input } from 'components/common/Input';
import { useUserQuery } from 'queries/useUserQuery';

interface IProps {
    defaultOpen?: boolean;
    strongAuth: (data: IStrongAuthData) => void;
}

const INITIAL_USER: IUserBasicData = {
    address: {},
    bankAccountNumber: '',
    email: '',
    finnishCitizen: true,
    id: 0,
    longTermStorageConsent: false,
    notifyWhenInvoicePaid: false,
    phone: '',
    phoneCountryCode: 358,
    salaryPaymentRuleId: 0,
    servicePercentage: '',
    yelInsured: false,
};

const ProfileContact = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const { data: user, refetch } = useUserQuery();
    const queryClient = useQueryClient();
    const [formData, setFormData] = useState<IUserBasicData>(INITIAL_USER);
    const [closeF, setCloseF] = useState<any>(null);
    const [editEmailMode, setEditEmailMode] = useState(false);

    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [errors, setErrors] = useState<any>(null);
    const [emailVerifyError, setEmailVerifyError] = useState<string>();

    const validateEmail = async (val: string) => {
        if (EMAIL_REGEX.test(val)) {
            try {
                const result = await API.get('/auth/verify-email', {
                    params: { email: val },
                    timeout: VERIFY_EMAIL_TIMEOUT_MS,
                });
                const status = result?.data?.status;
                if (didEmailVerificationFailed(status) && status) {
                    setEmailVerifyError(status);
                    setErrors({
                        ...errors,
                        email: {
                            path: ['email'],
                            type: status,
                        },
                    });
                } else {
                    setErrors({ ...errors, email: undefined });
                    setEmailVerifyError(undefined);
                }
            } catch (error) {
                setErrors({ ...errors, email: undefined });
                setEmailVerifyError(undefined);
            }
        } else {
            setErrors({
                ...errors,
                email: {
                    path: ['email'],
                    type: 'string.pattern.base',
                },
            });
        }
    };

    // Local version of update is needed as we want to close the card only if the mutation
    // is completed, and there are currently no other ways to call onCompleted
    const [updateUserData, { loading }] = useMutation(UPDATE_USER_DATA, {
        onCompleted: () => {
            if (closeF) {
                closeF();
            }
            toast(t('general.saved'));
            handleReset();
            refetch();
        },
        onError: (e: ApolloError) => {
            if ((getErrorKey(e) || '').includes('email_already_exists')) {
                toast.error(t('profile:contact.email.error'));
            } else {
                toast.error(t('errors.general'));
            }
        },
    });

    const [updateUserInHasura] = useMutation(UPDATE_USER, {
        context: { clientName: 'eezyPayHasura' },
    });

    const mapPropsToForm = (userData: any) => {
        const { __typename, address, ...data } = userData;
        const { __typename: typename2, ...addressData } = address;
        return {
            ...INITIAL_USER,
            ...data,
            address: { ...addressData, country: 'FI' },
        };
    };

    useEffect(() => {
        if (user) {
            setFormData(mapPropsToForm(user));
        }
    }, [user]);

    const handleAuthentication = () => {
        props.strongAuth({ action: 'profile', lang: i18n.language });
    };

    const handleSave = (callback: () => void) => {
        // callback-fn (that closes the card) is available here but needs to be called in
        // onCompleted of the mutation, thus the hack
        setCloseF(() => () => callback());
        let validationErrors = formatValidationResult(
            contactValidator.validate({ ...formData, email1, email2 }),
        );
        if (!validationErrors?.email && emailVerifyError) {
            validationErrors = {
                ...validationErrors,
                email: {
                    path: ['email'],
                    type: emailVerifyError,
                },
            };
        }
        if (validationErrors) {
            toast.error(t('profile:contact.error'));
            setErrors(validationErrors);
        } else {
            // street2 is not modified here
            const { street2, ...address } = formData.address;
            const data: IUserDataInput = {
                address,
                homeCountry: formData.homeCountry,
                email: email1 && email1 === email2 ? email1 : formData.email,
                finnishCitizen: formData.homeCountry === 'FI',
                phone: formData.phone,
                phoneCountryCode: formData.phoneCountryCode,
            };
            setErrors(null);
            updateUserData({ variables: data });
            updateUserInHasura({
                variables: {
                    id: user?.id,
                    email: email1 && email1 === email2 ? email1 : formData.email,
                    phone: `+${formData.phoneCountryCode} ${formData.phone}`,
                },
            });
        }
        // The user might be missing an address, which results an error, thus we want to invalidate the query
        // if the user updates the
        queryClient.invalidateQueries({ queryKey: ['yelData'] });
    };

    const handleReset = () => {
        setEmail1('');
        setEmail2('');
        setEditEmailMode(false);
        setErrors(null);
        if (user) {
            setFormData(mapPropsToForm(user));
        }
    };

    const handleChange = (val: string, name: string) => {
        const newData = { ...formData, [name]: val };
        setFormData(newData);
    };

    const handleAddressChange = (val: string, name: string) => {
        const newData = {
            ...formData,
            address: { ...formData.address, [name]: val },
        };
        setFormData(newData);
    };

    const handleEmailChange = (val: string, name: string) => {
        if (name === 'email1') {
            setEmail1(val);
        } else if (name === 'email2') {
            setEmail2(val);
        }
    };

    const handleCountryCodeChange = (val: string) => {
        setFormData({ ...formData, phoneCountryCode: Number.parseInt(val, 10) });
    };

    return (
        <ManualCard
            defaultOpen={props.defaultOpen}
            editableContent={
                <div className="flex flex-col gap-4">
                    {/* Name */}
                    <div className="flex items-end gap-2">
                        <div className="grow" style={{ maxWidth: '355px' }}>
                            <Input
                                disabled
                                label={t('profile:contact.firstName')}
                                name="firstName"
                                value={user?.firstName}
                                onChange={(e) => handleChange(e.target.value, 'firstName')}
                                onBlur={(e) => handleChange(e.target.value, 'firstName')}
                            />
                        </div>
                        <div className="sm:ml-auto">
                            {user?.isIdentified ? (
                                <Button color="green" disabled startIcon={Check}>
                                    {t('profile:contact.authenticated')}
                                </Button>
                            ) : (
                                <Button onClick={handleAuthentication}>
                                    {t('profile:contact.authenticate')}
                                </Button>
                            )}
                        </div>
                    </div>

                    <div style={{ maxWidth: '355px' }}>
                        <Input
                            disabled
                            label={t('profile:contact.lastName')}
                            name="lastName"
                            value={user?.lastName}
                            onChange={(e) => handleChange(e.target.value, 'lastName')}
                            onBlur={(e) => handleChange(e.target.value, 'lastName')}
                        />
                    </div>

                    <div className="flex flex-col gap-2" style={{ maxWidth: '355px' }}>
                        <div className="tg-caption-bold">{t('profile:contact.address.homecountry')}</div>
                        <Select
                            options={sortObjectsByLabel(getCountriesOptions())}
                            triggerText={`${formData.homeCountry}`}
                            onChange={(text) => handleChange(text, 'homeCountry')}
                            value={formData.homeCountry}
                            helpText={errors?.homeCountry}
                            className="grow"
                        />
                    </div>

                    {/* Email */}
                    <div className="flex items-end gap-2">
                        <div className="grow" style={{ maxWidth: '355px' }}>
                            <Input
                                disabled
                                label={t('profile:contact.email.label')}
                                name="email"
                                value={formData.email}
                                onChange={(e) => handleChange(e.target.value, 'email')}
                                onBlur={(e) => handleChange(e.target.value, 'email')}
                            />
                        </div>
                        <div className="sm:ml-auto">
                            {!editEmailMode && (
                                <Button onClick={() => setEditEmailMode(true)}>
                                    {t('profile:contact.email.change')}
                                </Button>
                            )}
                        </div>
                    </div>

                    {editEmailMode && (
                        <>
                            {errors && <RenderEmailValidationMessage errors={errors} />}
                            <div style={{ maxWidth: '355px' }}>
                                <Input
                                    required
                                    // error={errors?.email1 || errors?.email}
                                    label={t('profile:contact.email.new1')}
                                    name="email1"
                                    type="email"
                                    value={email1}
                                    onChange={(e) => handleEmailChange(e.target.value, 'email1')}
                                    onBlur={(e) => validateEmail(e.target.value)}
                                />
                            </div>
                            <div style={{ maxWidth: '355px' }}>
                                <Input
                                    required
                                    error={errors?.email2 || (email2 && email2 !== email1)}
                                    label={t('profile:contact.email.new2')}
                                    name="email2"
                                    type="email"
                                    value={email2}
                                    onChange={(e) => handleEmailChange(e.target.value, 'email2')}
                                />
                            </div>
                        </>
                    )}

                    {/* Phone */}
                    <div className="flex flex-col gap-2" style={{ maxWidth: '355px' }}>
                        <div className="tg-caption-bold">{t('profile:contact.phone')}</div>
                        <div className="flex gap-2">
                            <div className="grow" style={{ maxWidth: '100px' }}>
                                <Select
                                    options={getCountryCodeOptions().map((i) => ({
                                        label: `+${i.value}`,
                                        value: i.value,
                                    }))}
                                    triggerText={`${formData.homeCountry}`}
                                    onChange={handleCountryCodeChange}
                                    value={`${formData.phoneCountryCode || INITIAL_USER.phoneCountryCode}`}
                                    helpText={errors?.phoneCountryCode}
                                />
                            </div>
                            <div className="grow">
                                <Input
                                    required
                                    error={errors?.phone}
                                    name="phone"
                                    value={formData.phone}
                                    onChange={(e) => handleChange(e.target.value, 'phone')}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Address */}
                    <div style={{ maxWidth: '355px' }}>
                        <Input
                            required
                            error={errors?.['address.street1']}
                            label={
                                formData.finnishCitizen
                                    ? t('profile:contact.address.street1')
                                    : t('profile:contact.address.fistreet1')
                            }
                            name="street1"
                            value={formData.address?.street1 ?? ''}
                            onChange={(e) => handleAddressChange(e.target.value, 'street1')}
                        />
                    </div>

                    <div className="flex gap-2" style={{ maxWidth: '355px' }}>
                        <div style={{ maxWidth: '100px' }}>
                            <Input
                                required
                                error={errors?.['address.zipCode']}
                                label={t('profile:contact.address.zipCode')}
                                name="zipCode"
                                value={formData.address?.zipCode}
                                onChange={(e) => handleAddressChange(e.target.value, 'zipCode')}
                            />
                        </div>
                        <div className="grow">
                            <Input
                                required
                                error={errors?.['address.town']}
                                label={t('profile:contact.address.town')}
                                name="town"
                                value={formData.address?.town}
                                onChange={(e) => handleAddressChange(e.target.value, 'town')}
                            />
                        </div>
                    </div>
                </div>
            }
            onClose={handleReset}
            onSave={handleSave}
            route="contact"
            saveLoading={loading}
            title={t('profile:contact.title')}
            viewContent={
                <div className="flex flex-col">
                    <p className="tg-body-bold text-nowrap">
                        <b data-mf-replace="**REMOVED**">
                            {user?.firstName} {user?.lastName}
                        </b>
                    </p>
                    {user?.isIdentified && (
                        <Hidden xsDown>
                            <p className="tg-body">{t('profile:contact.isIdentified')}</p>
                        </Hidden>
                    )}
                    <Hidden xsDown>
                        <p className="tg-body mt-4">
                            <b data-mf-replace="**REMOVED**">
                                {user?.phoneCountryCode && `+${user?.phoneCountryCode} `}
                                {user?.phone}
                            </b>
                        </p>
                        <p className="tg-body" data-mf-replace="**REMOVED**">
                            {user?.email}
                        </p>
                        <p className="tg-body mt-4">
                            <b data-mf-replace="**REMOVED**">{user?.address.street1}</b>
                        </p>
                        <p className="tg-body" data-mf-replace="**REMOVED**">
                            {user?.address.zipCode} {user?.address.town}
                        </p>
                    </Hidden>

                    <Hidden smUp>
                        <p className="tg-body mt-4 text-nowrap" data-mf-replace="**REMOVED**">
                            {user?.phoneCountryCode && `+${user?.phoneCountryCode} `}
                            {user?.phone}, {user?.email}
                        </p>
                        <p className="tg-body text-nowrap" data-mf-replace="**REMOVED**">
                            {user?.address.street1}, {user?.address.zipCode} {user?.address.town}
                        </p>
                    </Hidden>
                </div>
            }
        />
    );
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        strongAuth: (data: IStrongAuthData) => {
            dispatch(strongAuth(data));
        },
    };
};

export default connect(null, mapDispatchToProps)(ProfileContact);
