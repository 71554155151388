import { EezyLink } from '../../components/Buttons';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import styled from 'styled-components';
import { SCREEN_S } from 'styles/variables';
import { useTranslation } from 'react-i18next';

interface IModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
}

const StyledLink = styled(EezyLink)`
    width: 100%;
    line-height: 41px !important;

    @media (min-width: ${SCREEN_S}px) {
        width: auto;
        line-height: 35px !important;
    }
`;

const ChangeTaxRateModal = (props: IModalProps) => {
    const { t, i18n } = useTranslation();

    const taxAuthorityUrl =
        i18n.language === 'fi'
            ? 'https://www.vero.fi/sahkoiset-asiointipalvelut/omavero/'
            : 'https://www.vero.fi/en/e-file/mytax/';

    return (
        <Modal
            id="modal-download-salaries"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('salaries:changeTaxRateModal.title')}
        >
            <ModalContent>
                <p className="tg-body">{t('salaries:changeTaxRateModal.content')}</p>
            </ModalContent>

            <ModalActions>
                <StyledLink color="purple" href={taxAuthorityUrl} rel="noopener noreferrer" target="_blank">
                    {t('salaries:changeTaxRateModal.button')}
                </StyledLink>
            </ModalActions>
        </Modal>
    );
};

export default ChangeTaxRateModal;
