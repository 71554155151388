import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { COLOR_GREYJOY, COLOR_IMPORTANT } from '../../styles/variables';
import { COST_RECEIPT_FILETYPES } from '../costs/costLogic';
import type { MessageRelatedObject, Thread } from 'trpc';

export const MESSAGE_ATTACHMENT_FILETYPES = COST_RECEIPT_FILETYPES;

export const getThreadIcon = (
    thread: Thread,
): {
    icon: {
        color: string;
        icon: IconProp;
    };
    smallIcon?: IconProp;
} => {
    const smallIcon: IconProp | undefined = thread.messages.find((t) => t.files.length > 0)
        ? ['far', 'paperclip']
        : undefined;

    if (thread.hasUnread) {
        return {
            icon: {
                color: COLOR_IMPORTANT,
                icon: ['far', 'envelope'],
            },
            smallIcon,
        };
    }
    return {
        icon: {
            color: COLOR_GREYJOY,
            icon: ['far', 'envelope-open'],
        },
        smallIcon,
    };
};

export const getRelatedObjectIcon = (relatedObject: MessageRelatedObject): IconProp => {
    switch (relatedObject?.type) {
        case 'invoice':
            return ['far', 'file-lines'];
        case 'cost_invoice':
            return ['fal', 'coins'];
        case 'salary_payment':
            return ['fas', 'euro-sign'];
        default:
            return ['far', 'paperclip'];
    }
};

export const getRelatedObjectUrl = (relatedObject: MessageRelatedObject): string | undefined => {
    switch (relatedObject?.type) {
        case 'invoice':
            return `/invoices/view/${relatedObject.id}`;
        case 'cost_invoice':
            return undefined;
        case 'salary_payment':
            return `/salaries/${relatedObject.id}`;
        default:
            return undefined;
    }
};
