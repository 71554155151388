import { useState } from 'react';
import { type ApolloError, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { getErrorKey } from 'utils/apolloErrors';
import { changePasswordValidator } from 'utils/user/validators';
import { formatValidationResult } from 'utils/validation';
import { CHANGE_PASSWORD } from './queries';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import { Input } from 'components/common/Input';

export const ProfileSettingsPassword = () => {
    const [changePasswordMode, setChangePasswordMode] = useState(false);
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [errors, setErrors] = useState<any>(null);

    const { t } = useTranslation();

    const [changePassword, { loading: changePasswordLoading }] = useMutation(CHANGE_PASSWORD, {
        onCompleted: () => {
            handleReset();
            toast(t('general.saved'));
        },
        onError: (e: ApolloError) => {
            if (getErrorKey(e) === 'aava:change_password.invalid_password') {
                toast.error(t('profile:settings.password.errorCurrent'));
                setErrors({ password: true });
            } else {
                toast.error(t('errors.general'));
            }
        },
    });

    const validatePassword = () => {
        return formatValidationResult(changePasswordValidator.validate({ password, password1, password2 }));
    };

    const handleSavePassword = () => {
        const validationErrors = validatePassword();
        if (validationErrors) {
            setErrors(validationErrors);
            toast.error(t('profile:settings.password.error'));
        } else {
            setErrors(null);
            changePassword({
                variables: {
                    currentPassword: password,
                    newPassword: password1,
                    newPasswordConfirm: password2,
                },
            });
        }
    };

    const handlePasswordChange = (val: string, name: string) => {
        if (name === 'password1') {
            setPassword1(val);
        } else if (name === 'password2') {
            setPassword2(val);
        } else if (name === 'password') {
            setPassword(val);
        }
        if (errors) {
            setErrors(validatePassword());
        }
    };

    const handleReset = () => {
        setPassword('');
        setPassword1('');
        setPassword2('');
        setChangePasswordMode(false);
        setErrors(null);
    };

    return (
        <>
            <div className="flex items-center justify-between">
                <p className="tg-body-bold">
                    <b>{t('profile:settings.password.change-password')}</b>
                </p>
                <Button variant="outline" onClick={() => setChangePasswordMode(!changePasswordMode)}>
                    {!changePasswordMode ? t('profile:settings.password.change') : t('general.cancel')}
                </Button>
            </div>

            {changePasswordMode && (
                <div className="flex flex-col gap-4">
                    <Input
                        required
                        label={t('profile:settings.password.current')}
                        name="password"
                        type="password"
                        error={errors?.password && t('profile:settings.password.errorCurrent')}
                        value={password}
                        onChange={(e) => handlePasswordChange(e.target.value, 'password')}
                    />

                    <Input
                        required
                        label={t('profile:settings.password.new1')}
                        name="password1"
                        type="password"
                        error={errors?.password1 && t('profile:settings.password.error1')}
                        value={password1}
                        onChange={(e) => handlePasswordChange(e.target.value, 'password1')}
                    />

                    <Input
                        required
                        label={t('profile:settings.password.new2')}
                        name="password2"
                        type="password"
                        error={
                            (errors?.password2 || (password2 && password2 !== password1)) &&
                            t('profile:settings.password.error2')
                        }
                        value={password2}
                        onChange={(e) => handlePasswordChange(e.target.value, 'password2')}
                    />

                    <div className="flex flex-col sm:flex-row justify-end">
                        <Button loading={changePasswordLoading} onClick={handleSavePassword}>
                            {t('general.save')}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
