import React, { type MouseEvent } from 'react';
import { ListItem } from '../../components/layout/List';
import { COLOR_BLUM } from '../../styles/variables';
import { formatCents } from '../../utils';
import type { ISalesPage } from './SalesPageCreate';
import { Button } from 'components/common/Button';
import { useTranslation } from 'react-i18next';

interface IListItemProps {
    salesPage: ISalesPage;
    selected?: boolean;
    handleClose?: (e: MouseEvent) => void;
}

const SalesPageListItem = (props: IListItemProps) => {
    const { salesPage, selected, handleClose } = props;

    const { t } = useTranslation();

    return (
        <ListItem color={COLOR_BLUM}>
            <div className="item-content">
                <div style={{ color: COLOR_BLUM, minWidth: 0 }}>
                    <p
                        className="tg-body-bold overflow-hidden truncate whitespace-nowrap pr-5"
                        data-mf-replace="**REMOVED**"
                    >
                        <b>{salesPage.serviceName}</b>
                    </p>
                    <div className="tg-body">
                        {t('eezyPay:price')}:{' '}
                        {t('form.eurs', {
                            eurs: formatCents(salesPage.servicePrice, true),
                        })}
                    </div>
                </div>
                {selected && (
                    <div style={{ marginLeft: 'auto' }}>
                        <Button onClick={handleClose}>{t('menu.close')}</Button>
                    </div>
                )}
            </div>
        </ListItem>
    );
};

export default React.memo(SalesPageListItem);
