import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { Icon } from '../../components/Icon';
import { IconBackground } from '../../components/IconBackground';
import { ListItem } from '../../components/layout/List';
import { SmallP, Span } from '../../components/textElements';
import { COLOR_BLUM, COLOR_GREYHOUND, COLOR_WHITE_WALKER } from '../../styles/variables';
import { formatCalendarTime, formatDateAndTime } from '../../utils';
import { getThreadIcon } from '../../utils/support/messageUtils';
import type { Thread } from 'trpc';

interface IListItemProps {
    thread: Thread;
}

const SmallIconSign = ({ icon }: { icon: IconProp }) => {
    return (
        <IconBackground style={{ backgroundColor: COLOR_BLUM }}>
            <Icon className="small" icon={icon} color={COLOR_WHITE_WALKER} />
        </IconBackground>
    );
};

const MessageListItem = (props: IListItemProps) => {
    const { thread } = props;
    const threadIcon = getThreadIcon(thread);

    return (
        <ListItem color={COLOR_BLUM} iconColor={threadIcon.icon.color}>
            <div className="item-content">
                <div className="icon-wrapper">
                    <>
                        <Icon className="small" icon={threadIcon.icon.icon} color={COLOR_WHITE_WALKER} />
                        {threadIcon.smallIcon && <SmallIconSign icon={threadIcon.smallIcon} />}
                    </>
                </div>
                <p>
                    <Span data-mf-replace="**REMOVED**">{thread.title} </Span>
                </p>
                <div title={formatDateAndTime(thread.lastMessageDate)}>
                    <SmallP color={COLOR_GREYHOUND}>{formatCalendarTime(thread.lastMessageDate)}</SmallP>
                </div>
            </div>
        </ListItem>
    );
};

export default React.memo(MessageListItem);
