import {
    Calculator,
    ChartColumnBig,
    File,
    Folder,
    Globe,
    Headset,
    MessageSquareText,
    Settings,
    Smartphone,
    Wallet,
} from 'lucide-react';
import { useChangeUserLanguageMutation } from 'queries/useUserQuery';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type NavigationItem = {
    text: string;
    path: string;
    // all icons have the same type
    Icon: typeof Calculator;
};

export type UserDropdownMenuItem = Omit<NavigationItem, 'path'> & {
    path?: string;
    subItems?: Omit<UserDropdownMenuItem, 'Icon'>[];
    onClick?: () => void;
};

export const useNavigationItems = () => {
    const { t, i18n } = useTranslation();
    const changeUserLanguage = useChangeUserLanguageMutation();

    const language = useMemo(() => {
        switch (i18n.language) {
            case 'en':
                return 'English';
            case 'fi':
                return 'Suomi';
        }
    }, [i18n.language]);

    const navigationItems: NavigationItem[] = [
        {
            text: t('nav.invoices'),
            path: '/invoices',
            Icon: File,
        },
        {
            text: t('nav.eezyPay'),
            path: '/sales-pages',
            Icon: Smartphone,
        },
        {
            text: t('nav.salaries'),
            path: '/salaries',
            Icon: Wallet,
        },
        {
            text: t('nav.statistics'),
            path: '/statistics',
            Icon: ChartColumnBig,
        },
    ];

    // Dropdown menu items grouped by sections
    const dropdownMenuItems: UserDropdownMenuItem[][] = [
        [
            {
                text: t('nav.own-info'),
                path: '/profile',
                Icon: Settings,
            },
            {
                text: `${t('nav.language')} - ${language}`,
                Icon: Globe,
                subItems: [
                    {
                        text: 'English',
                        onClick: () => changeUserLanguage('en'),
                    },
                    {
                        text: 'Suomi',
                        onClick: () => changeUserLanguage('fi'),
                    },
                ],
            },
        ],
        [
            {
                text: t('nav.salary-calculator'),
                path: '/salary-calculator',
                Icon: Calculator,
            },
            {
                text: t('nav.documents'),
                path: '/profile/documents',
                Icon: Folder,
            },
        ],
        [
            {
                text: t('nav.customer-service'),
                path: '/support',
                Icon: Headset,
            },
            {
                text: t('nav.feedback'),
                Icon: MessageSquareText,
            },
        ],
    ];

    return {
        navigationItems,
        dropdownMenuItems,
    };
};
