import type React from 'react';
import { Fragment, type MouseEvent, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Fade, Hidden } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import QRCode from 'qrcode';
import styled from 'styled-components';
import { saveSalesPageSearchQuery } from 'actions/eezyPay';
import {
    AccordionPanel,
    AccordionPanelDetails,
    AccordionPanelSummary,
    AccordionTypography,
} from 'components/AccordionPanel';
import BackButtonWithTitle from 'components/BackButtonWithTitle';
import { EezyButton } from 'components/Buttons';
import { EmptyListPlaceholder } from 'components/EmptyListPlaceholder';
import { Icon } from 'components/Icon';
import { List } from 'components/layout/List';
import { TwoColumnView } from 'components/layout/TwoColumnView';
import { LineMiddle } from 'components/Lines';
import { Modal, ModalActions } from 'components/modals/Modal';
import { TextDivider } from 'components/TextDivider';
import {} from 'components/textElements';
import config from 'config';
import { BORDER_RADIUS, COLOR_BLUM, COLOR_WHITE_WALKER, SCREEN_S, COLOR_IMPORTANT } from 'styles/variables';
import { formatCents, getMonthName, isMobile, trans } from 'utils';
import { filterPages, getSalesPagesMonthList, type IMonthYear } from 'utils/eezyPay/eezyPayUtils';
import { DELETE_SALES_PAGE, GET_SALES_PAGES, GET_CAMPWIRE_SALES_PAGES, GET_PAYMENTS } from './queries';
import type { ISalesPage } from './SalesPageCreate';
import SalesPageDetails from './SalesPageDetails';
import SalesPageListItem from './SalesPageListItem';
import SalesPageSearch from './SalesPageSearch';
import SalesPageShareModal from './SalesPageShareModal';
import RelatedObjectMessage from '../dashboard/RelatedObjectMessage';
import ReactJoyride from 'react-joyride';
import { useTranslation } from 'react-i18next';
import EezyPayInstructionModal from 'components/modals/EezyPayInstructionModal';
import ModalContainer from 'components/layout/ModalContainer';
import { Button } from 'components/common/Button';
import { Info, Plus } from 'lucide-react';

type ISalesPagesProps = object;

const LineMiddleStyled = styled(LineMiddle)`
    margin: 20px 0;
`;

const EezyButtonStyled = styled(EezyButton)`
    width: 100%;
    margin-bottom: 10px;
`;

const AccordionSummaryContainer = styled.div`
    padding-right: 10px;
    p {
        font-size: 15px;
        padding: 0;
    }
`;

const AccordionActions = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`;

const PaymentPageActions = styled.div`
    margin-top: 20px;

    button {
        width: 100%;
        margin: 10px 0 0 0 !important;
    }

    @media (min-width: ${SCREEN_S}px) {
        button {
            width: auto;
            margin-right: 10px !important;
        }
    }
`;

const ServiceName = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    margin-bottom: 6px;
    width: 100%;
`;

const AccordionPanelSummaryStyled = styled(AccordionPanelSummary)`
    cursor: auto !important;
    .MuiAccordionSummary-content {
        min-width: 0;
    }
`;

const Wrapper = styled.div<{
    showScroll?: boolean;
}>`
    border-radius: ${BORDER_RADIUS};
    background-color: ${COLOR_WHITE_WALKER};
    padding: ${(props) => (props.showScroll ? '0 35px 20px 20px' : '0 20px 20px 20px')};
`;

export const ShareWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const QRCodeContainer = styled.div`
    margin-bottom: 24px;
`;

const SalesPages: React.FC<ISalesPagesProps> = (_: ISalesPagesProps) => {
    // Properties
    const [selectedId, setSelectedId] = useState(0);
    const [expanded, setExpanded] = useState(0);
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [showGuide, setShowGuide] = useState(false);
    const [visibleInstructionModal, setVisibleInstructionModal] = useState(false);

    const { searchQuery } = useSelector((state: any) => ({
        searchQuery: state.eezyPay.salesPageSearchQuery,
        termsAccepted: state.eezyPay.eezyPayTermsAccepted,
        userLoadingComplete: state.eezyPay.userLoadingComplete,
        userState: state.eezyPay.userState,
    }));

    const ITEM_DELAY = 20;
    const FADE_TIMEOUT = 200;
    const MIN_ITEMS_TO_SHOW_SCROLL = 6;

    let animationCounter = 0;

    // Computed
    const { data: salesPages } = useQuery(GET_SALES_PAGES, {
        context: { clientName: 'eezyPayHasura' },
        fetchPolicy: 'cache-and-network',
        variables: {
            searchTerm: `%${searchQuery}%`,
        },
    });

    const monthList = getSalesPagesMonthList(salesPages?.sales_pages || []);
    const numberOfVisiblePages = salesPages?.sales_pages?.length || 0;
    const hasNoPages = numberOfVisiblePages === 0 && searchQuery === '';
    const showScroll = !isMobile() && numberOfVisiblePages >= MIN_ITEMS_TO_SHOW_SCROLL;

    // Methods
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useQuery(GET_PAYMENTS, {
        context: { clientName: 'eezyPayHasura' },
        fetchPolicy: 'cache-and-network',
        variables: {
            searchTerm: '%%',
        },
        onCompleted: (data) => {
            const hasTransactions = !!data.transactions.length;

            if (!hasTransactions) {
                const didShowIntro = localStorage.getItem('EezyDataIntro') === '1';
                if (!didShowIntro) {
                    localStorage.setItem('EezyDataIntro', '1');
                    navigate('/sales-pages/intro');
                    return;
                }
            }
        },
    });

    const { data: campwireSalesPages } = useQuery(GET_CAMPWIRE_SALES_PAGES, {
        context: { clientName: 'eezyPayHasura' },
        fetchPolicy: 'cache-and-network',
        variables: {
            searchTerm: `%${searchQuery}%`,
        },
    });

    const [deleteSalesPage, { loading: deleteInProgress }] = useMutation(DELETE_SALES_PAGE, {
        context: { clientName: 'eezyPayHasura' },
        variables: {
            id: selectedId,
        },
        onCompleted: () => {
            setDeleteModalOpen(false);
            setSelectedId(0);
            if (searchQuery) {
                dispatch(saveSalesPageSearchQuery(''));
            }
            toast(t('general.saved'));
        },
        onError: () => toast.error(t('errors.general')),
        refetchQueries: () => [
            {
                context: { clientName: 'eezyPayHasura' },
                query: GET_SALES_PAGES,
                variables: {
                    searchTerm: '%%',
                },
            },
        ],
    });

    useEffect(() => {
        if (salesPages?.sales_pages.length === 0 && campwireSalesPages?.sales_pages.length === 0) {
            setShowGuide(true);
        } else {
            setShowGuide(false);
        }
    }, [salesPages, campwireSalesPages]);

    const handleExpandChange = (panel: number) => {
        setExpanded(panel === expanded ? 0 : panel);
        setSelectedId(panel);
    };

    const onClickCreatePayment = () => {
        navigate('/sales-pages/create');
    };

    const onClickViewPayments = () => {
        navigate('/sales-pages/payments');
    };

    const handlePageItemClose = (e: MouseEvent) => {
        e.stopPropagation();
        setSelectedId(0);
    };

    const handleCopyLinkClick = (path: string) => {
        navigator.clipboard.writeText(path);
        toast(t('form.copied'));
    };

    const handleDeleteClick = () => {
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
    };

    const deletePaymentPage = () => {
        deleteSalesPage();
    };

    const handlePageShareClick = (page: ISalesPage) => {
        setSelectedId(page.id);
        generateQrCode(page.publicPath);
        setShareModalOpen(true);
    };

    const handleShareModalClose = () => {
        setShareModalOpen(false);
    };

    const generateQrCode = async (path: string) => {
        const url = await QRCode.toDataURL(`${config.eezyPayPublicPath}/${path}`, {
            width: 142,
            margin: 1,
        });

        if (url) {
            setQrCodeUrl(url);
        }
    };

    const listPaymentPages = (items: ISalesPage[], delay: number) => {
        return (
            <ul>
                {items.map((salesPage, index) => {
                    const handleClick = () => {
                        setSelectedId(salesPage.id);
                        generateQrCode(salesPage.publicPath);
                    };
                    const selected = selectedId === salesPage.id;
                    return (
                        <li
                            className={selected ? 'selected' : ''}
                            key={`${index}-pages-${salesPage.id}`}
                            onClick={handleClick}
                            onKeyPress={handleClick}
                        >
                            <Fade
                                in={true}
                                timeout={FADE_TIMEOUT}
                                style={{
                                    transitionDelay: `${ITEM_DELAY * index + delay}ms`,
                                }}
                            >
                                <div>
                                    <SalesPageListItem
                                        key={salesPage.id}
                                        salesPage={salesPage}
                                        selected={selected}
                                        handleClose={handlePageItemClose}
                                    />
                                </div>
                            </Fade>
                        </li>
                    );
                })}
            </ul>
        );
    };

    const getSelectedPageData = () => {
        if (!salesPages?.sales_pages) {
            return;
        }

        const salesPagesCopy = JSON.parse(JSON.stringify(salesPages?.sales_pages));

        const selectedPage = salesPagesCopy.filter((page: ISalesPage) => page.id === selectedId);

        return selectedPage[0];
    };

    const renderDeleteModal = (page: ISalesPage) => {
        if (!page) {
            return;
        }

        return (
            <Modal
                small
                id="modal-delete-sales-page"
                isOpen={deleteModalOpen}
                onClose={handleDeleteModalClose}
                title={
                    t('eezyPay:delete-modal-title', {
                        serviceName: page.serviceName,
                    }) || ''
                }
            >
                <ModalActions $small>
                    <EezyButton color="purple" onClick={handleDeleteModalClose}>
                        {t('general.no')}
                    </EezyButton>
                    <EezyButton color="purple" dark onClick={deletePaymentPage}>
                        {t('general.yes')}
                    </EezyButton>
                </ModalActions>
            </Modal>
        );
    };

    const renderShareModal = (page: ISalesPage) => {
        if (!page) {
            return;
        }

        return (
            <SalesPageShareModal
                shareModalOpen={shareModalOpen}
                handleShareModalClose={handleShareModalClose}
                page={page}
                qrCodeUrl={qrCodeUrl}
                handleCopyLinkClick={() => {
                    handleCopyLinkClick(`${config.eezyPayPublicPath}/${page.publicPath}`);
                }}
            />
        );
    };

    const renderPaymentPageDetails = (page: ISalesPage) => {
        if (!page) {
            return;
        }

        return (
            <SalesPageDetails
                page={page}
                qrCodeUrl={qrCodeUrl}
                handleCopyLinkClick={() => {
                    handleCopyLinkClick(`${config.eezyPayPublicPath}/${page.publicPath}`);
                }}
                handleDeleteClick={() => {
                    handleDeleteClick();
                }}
            />
        );
    };

    return (
        <>
            {/* MOBILE */}
            <Hidden mdUp>
                <>
                    <ReactJoyride
                        run={showGuide}
                        disableCloseOnEsc={true}
                        hideCloseButton={true}
                        steps={[
                            {
                                content: (
                                    <div>
                                        <h3 style={{ marginBottom: '20px' }}>
                                            {t('eezyPay:guide.step-1.title')}
                                        </h3>
                                        <div style={{ fontSize: '15px' }}>
                                            <p style={{ marginBottom: '10px' }}>
                                                {t('eezyPay:guide.step-1.p1')}
                                            </p>
                                            <p>{t('eezyPay:guide.step-1.p2')}</p>
                                        </div>
                                    </div>
                                ),
                                placement: 'bottom',
                                target: '.create-new-page',
                                disableBeacon: true,
                            },
                        ]}
                        floaterProps={{
                            disableAnimation: true,
                        }}
                        styles={{
                            tooltipFooter: {
                                display: 'none',
                            },
                        }}
                    />
                    <BackButtonWithTitle title={t('eezyPay:sales-pages') || ''} />
                    <div>
                        <div
                            style={{
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <EezyButtonStyled
                                color="purple"
                                transparent
                                onClick={() => setVisibleInstructionModal(true)}
                                style={{
                                    marginLeft: 0,
                                    marginBottom: 0,
                                    padding: 0,
                                    width: 'auto',
                                    border: 0,
                                }}
                            >
                                <Icon
                                    icon={['fas', 'info-circle']}
                                    style={{
                                        marginRight: 5,
                                        marginTop: 6,
                                        fontWeight: 600,
                                    }}
                                />
                                {t('eezyPay:help-modal.button')}
                            </EezyButtonStyled>
                            <LineMiddleStyled color={'#ADA8BE'} style={{ width: '100%' }} />
                        </div>

                        <EezyButtonStyled
                            color="purple"
                            dark
                            onClick={onClickCreatePayment}
                            className="create-new-page"
                        >
                            <Icon
                                color={COLOR_WHITE_WALKER}
                                icon={['far', 'plus']}
                                style={{
                                    marginRight: 5,
                                    marginTop: 6,
                                    fontWeight: 600,
                                }}
                            />
                            {t('eezyPay:new-page')}
                        </EezyButtonStyled>

                        <EezyButtonStyled
                            color="purple"
                            transparent
                            onClick={onClickViewPayments}
                            style={{ marginLeft: 0 }}
                        >
                            {t('eezyPay:payments-received')}
                        </EezyButtonStyled>
                        <LineMiddleStyled color={'#ADA8BE'} />
                    </div>

                    {!hasNoPages && (
                        <div style={{ marginBottom: 10 }}>
                            <SalesPageSearch />
                        </div>
                    )}

                    {hasNoPages ? (
                        <div style={{ padding: '10px 0' }}>
                            <EmptyListPlaceholder textColor={COLOR_BLUM} text={t('eezyPay:no-pages')} />
                        </div>
                    ) : (
                        <ul>
                            {salesPages?.sales_pages?.map((salesPage: ISalesPage) => (
                                <AccordionPanel expanded={expanded === salesPage.id} key={salesPage.id}>
                                    <AccordionPanelSummaryStyled>
                                        <AccordionSummaryContainer
                                            style={{
                                                margin: '0',
                                                minWidth: 0,
                                            }}
                                        >
                                            <ServiceName data-mf-replace="**REMOVED**">
                                                {salesPage.serviceName}
                                            </ServiceName>

                                            <AccordionTypography>
                                                {t('eezyPay:price')}:{' '}
                                                {t('form.eurs', {
                                                    eurs: formatCents(salesPage.servicePrice, true),
                                                })}
                                            </AccordionTypography>
                                        </AccordionSummaryContainer>
                                        <AccordionActions>
                                            <EezyButton
                                                style={{
                                                    borderWidth: 2,
                                                    padding: 0,
                                                    minWidth: 'auto',
                                                    width: 32,
                                                    height: 32,
                                                    marginRight: 8,
                                                    verticalAlign: 'top',
                                                }}
                                                color="purple"
                                                disabled={salesPage.state !== 1}
                                                transparent
                                                onClick={() => {
                                                    handlePageShareClick(salesPage);
                                                }}
                                            >
                                                <Icon
                                                    style={{
                                                        cursor: 'pointer',
                                                        marginTop: 6,
                                                    }}
                                                    icon={['fas', 'share']}
                                                />
                                            </EezyButton>
                                            <EezyButton
                                                style={{
                                                    borderWidth: 2,
                                                    padding: 0,
                                                    minWidth: 'auto',
                                                    width: 32,
                                                    height: 32,
                                                    marginRight: 8,
                                                    verticalAlign: 'top',
                                                }}
                                                color="purple"
                                                transparent
                                                onClick={() => {
                                                    handleExpandChange(salesPage.id);
                                                }}
                                            >
                                                <Icon
                                                    style={{
                                                        cursor: 'pointer',
                                                        verticalAlign: 'middle',
                                                        width: 18,
                                                        height: 18,
                                                    }}
                                                    icon={[
                                                        'fas',
                                                        expanded === salesPage.id
                                                            ? 'chevron-up'
                                                            : 'chevron-down',
                                                    ]}
                                                />
                                            </EezyButton>
                                        </AccordionActions>
                                    </AccordionPanelSummaryStyled>
                                    <AccordionPanelDetails>
                                        {salesPage.serviceDescription && (
                                            <div
                                                style={{
                                                    marginBottom: '20px',
                                                }}
                                            >
                                                <div>{t('eezyPay:description')}: </div>
                                                <div data-mf-replace="**REMOVED**">
                                                    {salesPage.serviceDescription}
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            {t('eezyPay:payments-amount')}:{' '}
                                            {salesPage.transactions_aggregate.aggregate.count}
                                        </div>
                                        {salesPage.state === 0 && (
                                            <div style={{ color: COLOR_IMPORTANT, marginTop: 10 }}>
                                                {trans('eezyPay:closed')}
                                            </div>
                                        )}
                                        {salesPage.state === 2 && (
                                            <div style={{ color: COLOR_IMPORTANT, marginTop: 10 }}>
                                                {trans('eezyPay:waiting-for-approval')}
                                            </div>
                                        )}
                                        <PaymentPageActions>
                                            <EezyButton
                                                color="purple"
                                                disabled={salesPage.state === 0}
                                                transparent
                                                onClick={() => {
                                                    navigate(`/sales-pages/edit/${salesPage.publicPath}`);
                                                }}
                                            >
                                                {t('eezyPay:edit-page')}
                                            </EezyButton>
                                            <EezyButton
                                                color="purple"
                                                transparent
                                                onClick={() => {
                                                    handleDeleteClick();
                                                }}
                                            >
                                                {t('eezyPay:delete-page')}
                                            </EezyButton>
                                        </PaymentPageActions>
                                    </AccordionPanelDetails>
                                </AccordionPanel>
                            ))}
                        </ul>
                    )}
                </>
                <RelatedObjectMessage displayEezyPayBadge />
            </Hidden>

            {/* DESKTOP */}
            <Hidden mdDown>
                <ReactJoyride
                    run={showGuide}
                    disableCloseOnEsc={true}
                    hideCloseButton={true}
                    steps={[
                        {
                            content: (
                                <div>
                                    <h3 className="mb-5">{t('eezyPay:guide.step-1.title')}</h3>
                                    <div className="tg-body">
                                        <p className="mb-2">{t('eezyPay:guide.step-1.p1')}</p>
                                        <p>{t('eezyPay:guide.step-1.p2')}</p>
                                    </div>
                                </div>
                            ),
                            placement: 'bottom',
                            target: '.create-new-page',
                            disableBeacon: true,
                        },
                    ]}
                    floaterProps={{
                        disableAnimation: true,
                    }}
                    styles={{
                        tooltipFooter: {
                            display: 'none',
                        },
                    }}
                />
                <TwoColumnView>
                    <div>
                        <Wrapper showScroll={showScroll}>
                            <Fade in={true} timeout={FADE_TIMEOUT}>
                                <div className="py-6">
                                    <h2 className="tg-heading-2">{t('eezyPay:sales-pages')}</h2>
                                </div>
                            </Fade>

                            <Fade in={true} timeout={FADE_TIMEOUT} style={{ transitionDelay: '100ms' }}>
                                {!hasNoPages ? (
                                    <div style={{ marginBottom: 4 }}>
                                        <SalesPageSearch />
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </Fade>

                            {hasNoPages ? (
                                <div style={{ padding: '65px 0' }}>
                                    <EmptyListPlaceholder textColor="#C2BCD1" text={t('eezyPay:no-pages')} />
                                </div>
                            ) : (
                                <List
                                    className={showScroll ? 'show-scroll' : 'hide-scroll'}
                                    style={{
                                        top: 180,
                                    }}
                                >
                                    {monthList.map(({ month, year }: IMonthYear, index) => {
                                        const pages = filterPages(salesPages?.sales_pages, month, year);
                                        animationCounter += pages.length;
                                        const delay = (animationCounter - pages.length) * ITEM_DELAY;
                                        return (
                                            <Fragment key={`${index}-${month}-${year}`}>
                                                <Fade
                                                    in={true}
                                                    timeout={FADE_TIMEOUT}
                                                    style={{
                                                        transitionDelay: `${delay}ms`,
                                                    }}
                                                >
                                                    <div>
                                                        <TextDivider>
                                                            <p className="tg-body-bold text-violet-900">
                                                                <b>
                                                                    {getMonthName(month)} {year}
                                                                </b>
                                                            </p>
                                                        </TextDivider>
                                                    </div>
                                                </Fade>
                                                {listPaymentPages(pages, delay)}
                                            </Fragment>
                                        );
                                    })}
                                </List>
                            )}
                        </Wrapper>
                    </div>
                    <div className="hide-scroll">
                        <div className="flex flex-col items-stretch justify-center gap-2">
                            <Button
                                variant="ghost"
                                startIcon={Info}
                                onClick={() => setVisibleInstructionModal(true)}
                            >
                                {t('eezyPay:help-modal.button')}
                            </Button>

                            <LineMiddleStyled color={'#ADA8BE'} style={{ width: '100%' }} />

                            <Button startIcon={Plus} onClick={onClickCreatePayment}>
                                {t('eezyPay:new-page')}
                            </Button>

                            <Button variant="outline" onClick={onClickViewPayments}>
                                {t('eezyPay:payments-received')}
                            </Button>

                            {selectedId !== 0 && !deleteInProgress
                                ? renderPaymentPageDetails(getSelectedPageData())
                                : null}
                        </div>

                        <RelatedObjectMessage displayEezyPayBadge />
                    </div>
                </TwoColumnView>
            </Hidden>
            {selectedId !== 0 && !deleteInProgress && renderDeleteModal(getSelectedPageData())}
            {selectedId !== 0 && !deleteInProgress && renderShareModal(getSelectedPageData())}

            {visibleInstructionModal && (
                <ModalContainer>
                    <EezyPayInstructionModal close={() => setVisibleInstructionModal(false)} />
                </ModalContainer>
            )}
        </>
    );
};

export default SalesPages;
