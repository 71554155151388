import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import type { IMessageInput, IMessageReplyServerInput } from '../../../../shared/src/types/message';
import { RightSideBody } from 'components/layout/RightSideBody';
import MessageForm from './MessageForm';
import MessageThread from './MessageThread';
import { MARK_MESSAGES_READ, REPLY_TO_MESSAGE_THREAD } from './queries';
import { trpc } from 'trpc';

const ThreadView: FC = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const threadId = Number.parseInt(params?.threadId || '', 10);

    const [isReplyMode, setReplyMode] = useState(false);
    const { data: messagesData, refetch: refetchMessages } = trpc.messages.list.useQuery();

    const [markMessagesRead] = useMutation(MARK_MESSAGES_READ, {
        onCompleted: () => {
            refetchMessages();
        },
    });

    const thread = messagesData?.find((t) => t.id === threadId);

    const [replyToMessage, { loading }] = useMutation(REPLY_TO_MESSAGE_THREAD, {
        onCompleted: () => {
            refetchMessages();
            toast(t('support:message.sent-success'));
            navigate('/support');
        },
        onError: () => toast.error(t('errors.general')),
    });

    useEffect(() => {
        if (thread) {
            const timer = setTimeout(() => {
                const unreadMessages = thread?.messages.filter((m) => !m.isRead).map((m) => m.id);
                if (unreadMessages.length > 0) {
                    markMessagesRead({
                        variables: { messageIds: unreadMessages },
                    });
                }
            }, 3 * 1000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [thread, threadId, markMessagesRead]);

    useEffect(() => {
        setReplyMode(false);
    }, [threadId]);

    if (!thread) {
        return null;
    }

    const handleReply = () => {
        setReplyMode(true);
    };

    const sendReply = (message: IMessageInput, callback: () => void) => {
        const parent = thread.messages[0];
        const messageReply: IMessageReplyServerInput = {
            content: message.content,
            fileIds: message.fileIds || [],
            parentId: parent.id,
        };
        replyToMessage({
            variables: {
                lang: i18n.language,
                message: messageReply,
            },
        }).then(() => {
            callback();
        });
    };

    return (
        <RightSideBody>
            {isReplyMode && (
                <MessageForm loadingSend={loading} onCancel={() => setReplyMode(false)} onSend={sendReply} />
            )}
            <MessageThread thread={thread} onReply={handleReply} />
        </RightSideBody>
    );
};

export default ThreadView;
