import type React from 'react';
import type { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { buttonVariants } from 'components/ui/button';

const WrapperLabel = styled.label`
    cursor: pointer;

    &&.disabled {
        cursor: default;
        opacity: 0.3;
    }

    svg {
        font-size: 15px;
        height: 15px;
        margin-right: 10px;
        width: 15px;
    }

    input {
        height: 0px;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
    }
`;

interface IFileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    accept: string;
    children: ReactNode;
    disabled?: boolean;
    color?: string;
    style?: CSSProperties;
}

const FileInput = (props: IFileInputProps) => {
    const { children, ...restProps } = props;
    return (
        <WrapperLabel color={props.color} style={props.style} className={[props.disabled ? 'disabled' : '', buttonVariants({ variant: 'outline' })].join(' ')}>
            <input {...restProps} type="file" accept={props.accept} />
            {props.children}
        </WrapperLabel>
    );
};

export default FileInput;
