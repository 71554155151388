import { CommandGroup, CommandItem, CommandList } from 'components/ui/command';
import { Command as CommandPrimitive } from 'cmdk';
import { type KeyboardEventHandler, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRight, Search } from 'lucide-react';
import { cn } from 'utils';
import { useNavigate } from 'react-router-dom';
import { type SiteSearchItem, useSiteSearch } from './useSiteSearch';

export const SiteSearch = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const { filteredItems, searchTerm, setSearchTerm } = useSiteSearch();

    const handleSelect = useCallback(
        (item: SiteSearchItem) => {
            navigate(item.path);
            setIsOpen(false);
            setSearchTerm('');
            inputRef.current?.blur();
        },
        [navigate, setSearchTerm],
    );

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
        const input = inputRef.current;
        if (!input) {
            return;
        }

        if (event.key === 'Escape') {
            input.blur();
        }
    }, []);

    const showResults = isOpen && filteredItems.length && searchTerm;

    return (
        <CommandPrimitive shouldFilter={false} loop>
            <div
                className="flex items-center border rounded-lg border-gray-200 px-3 gap-3 focus-within:border-violet-700"
                cmdk-input-wrapper=""
            >
                <Search className="size-4" />
                <CommandPrimitive.Input
                    ref={inputRef}
                    value={searchTerm}
                    onValueChange={setSearchTerm}
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                    onKeyDown={handleKeyDown}
                    placeholder={t('general.search')}
                    className="flex h-11 w-full bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground tg-body-medium"
                />
            </div>
            <div className="mt-1 relative">
                <div
                    className={cn(
                        'absolute top-0 z-10 w-full rounded-xl bg-gray-50 shadow-md outline-none animate-in fade-in-0 zoom-in-95 hidden',
                        showResults && 'block',
                    )}
                >
                    <CommandList>
                        <CommandGroup>
                            {filteredItems.map((item) => (
                                <CommandItem
                                    key={`${item.title}_${item.path}`}
                                    onMouseDown={(e) => e.preventDefault()}
                                    onSelect={() => handleSelect(item)}
                                    className="flex flex-col gap-1 items-start data-[selected=true]:bg-violet-100"
                                >
                                    <div className="flex gap-2 font-bold">
                                        {Array.isArray(item.title)
                                            ? item.title.map((title, index) => (
                                                  <div
                                                      key={`${title}_${item.path}`}
                                                      className="flex items-center gap-2"
                                                  >
                                                      {index !== 0 && <ArrowRight className="size-3" />}
                                                      <div>{title}</div>
                                                  </div>
                                              ))
                                            : item.title}
                                    </div>
                                    <div className="text-gray-600">{item.description}</div>
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </div>
            </div>
        </CommandPrimitive>
    );
};
