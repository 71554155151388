import { type SiteSearchItem, useSiteSearch } from './useSiteSearch';
import { ArrowRight } from 'lucide-react';
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from 'components/ui/command';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export const MobileSiteSearch = ({ onLinkClick }: { onLinkClick: () => void }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { filteredItems, searchTerm, setSearchTerm } = useSiteSearch();

    const handleSelect = useCallback(
        (item: SiteSearchItem) => {
            onLinkClick();
            navigate(item.path);
            setSearchTerm('');
        },
        [navigate, setSearchTerm],
    );

    return (
        <Command shouldFilter={false} className="px-4">
            <CommandInput
                value={searchTerm}
                onValueChange={setSearchTerm}
                placeholder={t('general.search')}
            />

            <CommandList className="-mx-4">
                <CommandGroup>
                    {filteredItems.map((item) => (
                        <CommandItem
                            key={`${item.title}_${item.path}`}
                            onMouseDown={(e) => e.preventDefault()}
                            onSelect={() => handleSelect(item)}
                            className="flex flex-col gap-1 items-start data-[selected=true]:bg-gray-100 cursor-pointer"
                        >
                            <div className="flex gap-2 font-bold tg-subtitle">
                                {Array.isArray(item.title)
                                    ? item.title.map((title, index) => (
                                          <div
                                              key={`${title}_${item.path}`}
                                              className="flex items-center gap-2"
                                          >
                                              {index !== 0 && <ArrowRight className="size-4" />}
                                              <div className="tg-subtitle">{title}</div>
                                          </div>
                                      ))
                                    : item.title}
                            </div>
                            <div className="text-gray-600 tg-body">{item.description}</div>
                        </CommandItem>
                    ))}
                </CommandGroup>
            </CommandList>
        </Command>
    );
};
