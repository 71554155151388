import type { MutationFunctionOptions } from '@apollo/client';
import type { IConfirmAction } from '../../actions/app';
import { AutoSaveCard } from '../../components/cards';
import { trans } from '../../utils';
import { ProfileSettingsLanguage } from './ProfileSettingsLanguage';
import { ProfileSettingsNotifyInvoicePaid } from './ProfileSettingsNotifyInvoicePaid';
import { ProfileSettingsPassword } from './ProfileSettingsPassword';
import { ProfileSettingsRemoveAccount } from './ProfileSettingsRemoveAccount';
import { ProfileSettingsStorageConsent } from './ProfileSettingsStorageConsent';
import { ProfileSettings2FA } from './ProfileSettings2FA';
import { ProfileSettingsCookieHub } from './ProfileSettingsCookieHub';

interface IProps {
    confirmAction: (payload: IConfirmAction) => void;
    updateUserData: (options?: MutationFunctionOptions<any, Record<string, any>> | undefined) => Promise<any>;
}

export const ProfileSettings = (props: IProps) => {
    return (
        <AutoSaveCard title={trans('profile:settings.title')}>
            <div className="flex flex-col gap-4">
                <ProfileSettingsLanguage />
                <div className="flex flex-col gap-2">
                    <ProfileSettingsNotifyInvoicePaid updateUserData={props.updateUserData} />
                    <ProfileSettingsStorageConsent updateUserData={props.updateUserData} />
                </div>
                <ProfileSettings2FA />
                <ProfileSettingsPassword />
                <ProfileSettingsRemoveAccount />
                <ProfileSettingsCookieHub />
            </div>
        </AutoSaveCard>
    );
};
