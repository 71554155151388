import type { UiLanguage } from '../../../shared/src/types/user';
import { changeLanguage } from 'utils';
import API from 'utils/API';
import { trpc } from 'trpc';

export const useUserQuery = () =>
    trpc.user.me.useQuery(undefined, {
        retry: (failureCount, error) => {
            if (error.data?.httpStatus === 401) {
                // dispatch(logout());
                return false;
            }
            return failureCount < 1;
        },
    });

export const useUser = () => {
    return useUserQuery().data ?? null;
};

export const useChangeUserLanguageMutation = () => {
    return async (lang: UiLanguage) => {
        changeLanguage(lang);
        await API.put('/user', { language: lang });
    };
};
