import { useMutation } from '@apollo/client';
import type { UiLanguage } from '../../../../shared/src/types/user';
import { FormRadio } from '../../components/form';
import { UPDATE_USER_LANGUAGE } from '../eezypay/queries';
import { useTranslation } from 'react-i18next';
import { useChangeUserLanguageMutation, useUser } from 'queries/useUserQuery';

export const ProfileSettingsLanguage = () => {
    const { t, i18n } = useTranslation();
    const user = useUser();
    const changeUserLanguage = useChangeUserLanguageMutation();
    const [updateUserLanguageInHasura] = useMutation(UPDATE_USER_LANGUAGE, {
        context: { clientName: 'eezyPayHasura' },
    });

    const handleLanguageUpdate = (lang: string) => {
        changeUserLanguage(lang as UiLanguage);
        if (user?.id) {
            updateUserLanguageInHasura({
                variables: {
                    id: user.id,
                    language: lang,
                },
            });
        }
    };

    return (
        <FormRadio
            label={t('profile:settings.language')}
            options={[
                { label: t('languages.fi'), value: 'fi' },
                { label: t('languages.en'), value: 'en' },
            ]}
            name="language"
            onChange={handleLanguageUpdate}
            required
            value={i18n.language}
        />
    );
};
