import { useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { Flex } from 'components/Flex';
import CardTitle from 'components/cards/CardTitle';
import { formatMoneyWithTwoDecimals, formatPercentage } from 'utils';
import { BodyPSmall, Bold, LabelThin, RowTitle, Span } from 'components/textElements';
import { DashboardCard } from 'components/cards/DashboardCard';
import { isDesktopSize } from 'containers/invoice/fillHelpers/utils';
import { ChartNoAxesCombined, ChevronUp, ChevronDown } from 'lucide-react';
import {
    BORDER_RADIUS,
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_DARK_GRAY,
    COLOR_GREYHOUND,
    COLOR_GREYJOY,
    COLOR_STATUS_DONE,
    COLOR_EEZY_BORDER_GRAY_200,
    COLOR_WHITE_WALKER,
    SCREEN_M,
    SCREEN_S,
} from 'styles/variables';
import TabHeaders from 'components/tabs/TabHeaders';
import SalesStatsCard from 'components/cards/SalesStatsCard';
import LoadingSpinner from 'components/Loading';
import { invoiceDateFilter } from 'utils/statistics/statisticUtils';
import { useStatisticsData } from 'hooks/statistics/useStatisticsData';
import { Button } from 'components/common/Button';
import { useUser } from 'queries/useUserQuery';
interface IServiceFeeProps {
    current: number;
    minLimit: number;
    maxLimit?: number;
    serviceFee: number;
}

enum SalesTabEnum {
    Laskutus = 0,
    EezyPay = 1,
}

const serviceFeeSteps = [
    {
        maxLimit: 50_000,
        minLimit: 0,
        serviceFee: 7,
    },
    {
        maxLimit: 150_000,
        minLimit: 50_000,
        serviceFee: 6,
    },
    {
        maxLimit: 300_000,
        minLimit: 150_000,
        serviceFee: 5.5,
    },
    {
        minLimit: 300_000,
        serviceFee: 5,
    },
];

const Ball = styled.div`
    width: 35px;
    height: 35px;
    background-color: ${COLOR_GREYJOY};
    font-size: 13px;
    font-weight: 600;
    color: ${COLOR_WHITE_WALKER};
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    &.active {
        width: 45px;
        height: 45px;
        line-height: 45px;
        background-color: ${COLOR_STATUS_DONE};
        color: ${COLOR_BLUM};
        margin-right: -8px;
    }
`;

const Connector = styled.div`
    width: 4px;
    background-color: ${COLOR_GREYJOY};
    position: absolute;
    z-index: 0;
    right: 30px;
    top: 30px;
    bottom: 30px;
`;

const ServiceFeeBox = styled(Flex)`
    padding: 10px 15px;
    z-index: 1;

    &.active {
        background-color: rgba(255, 255, 255, 0.8);
        border: 2px solid ${COLOR_STATUS_DONE};
        border-radius: ${BORDER_RADIUS};
    }
`;

const ServiceFeeBlock = (props: IServiceFeeProps) => {
    const { t } = useTranslation();
    const previous = props.maxLimit && props.current >= props.maxLimit;
    const active = props.current >= props.minLimit && (!props.maxLimit || props.current < props.maxLimit);
    const upcoming = props.current < props.minLimit;
    const classes = active ? 'active' : '';
    return (
        <ServiceFeeBox className={classes} spread>
            <Flex column>
                <LabelThin
                    color={upcoming ? COLOR_GREYHOUND : COLOR_GREYJOY}
                    style={{
                        marginTop: !active ? 8 : 0,
                        textDecoration: previous ? 'line-through' : 'none',
                    }}
                >
                    {!props.maxLimit
                        ? t('salaries.moreThan300000')
                        : props.maxLimit === 50_000
                          ? t('salaries.lessThan50000')
                          : props.maxLimit === 150_000
                            ? t('salaries.moreThan50000')
                            : t('salaries.moreThan150000')}
                </LabelThin>
                {active && (
                    <>
                        <div>
                            <Money id="income-year">{formatMoneyWithTwoDecimals(props?.current)}</Money>
                            <Unit>{t('form.eurs', { eurs: '' })}</Unit>
                        </div>
                        <LabelThin color={COLOR_DARK_GRAY}>{t('salaries.cumulativeInvoicing')}</LabelThin>
                    </>
                )}
            </Flex>
            <Flex column justifyCenter>
                <Ball className={classes}>
                    <span>{formatPercentage(props.serviceFee)}%</span>
                </Ball>
            </Flex>
        </ServiceFeeBox>
    );
};

const Money = styled(Bold)`
    color: ${COLOR_BLUM};
    font-size: 16px;
    line-height: 16px;
`;
const Unit = styled(Span)`
    color: ${COLOR_BLACKWATER};
    font-size: 16px;
    line-height: 16px;
`;
const SectionSeparator = styled.div`
    @media (min-width: ${SCREEN_S}px) {
        width: 1px;
        background: #e3e3e3;
        margin: 0 32px;
    }
`;
const DashboardSalesBillingHistoryWrapper = styled.div`
    @media (max-width: ${SCREEN_M}px) {
        margin-top: 8px;
        border-radius: ${BORDER_RADIUS};
        border: 1px solid ${COLOR_EEZY_BORDER_GRAY_200};
        padding: 24px;
    }
`;

const DashboardSalesBillingHistory = () => {
    const { t } = useTranslation();

    const defaultServiceFee = serviceFeeSteps[0].serviceFee;

    const user = useUser();

    const serviceFee = user?.servicePercentage ?? defaultServiceFee;
    const cumulativeInvoicing = user?.cumulativeInvoicing ?? 0;

    const useCustomPercentage = useMemo(() => {
        if (!user?.servicePercentage) return false;

        const currentStep = serviceFeeSteps.find(
            (s) => cumulativeInvoicing >= s.minLimit && (!s.maxLimit || cumulativeInvoicing < s.maxLimit),
        );
        return serviceFee < (currentStep?.serviceFee ?? 0);
    }, [user]);

    if (!user) {
        return (
            <div className="text-center">
                <LoadingSpinner color="inherit" />
            </div>
        );
    }

    return (
        <DashboardSalesBillingHistoryWrapper>
            <RowTitle className="hidden md:block">{t('salaries.cumulativeInvoicingTitle')}</RowTitle>
            <BodyPSmall>
                {useCustomPercentage && serviceFee
                    ? t('salaries.customServiceFee', {
                          percentage: formatPercentage(serviceFee),
                      })
                    : t('salaries.serviceFee')}
            </BodyPSmall>

            <Flex column style={{ marginTop: 10, position: 'relative' }}>
                {user.cumulativeInvoicing !== null && user.cumulativeInvoicing !== undefined && (
                    <>
                        {serviceFeeSteps.map((conf) => (
                            <ServiceFeeBlock
                                key={conf.minLimit}
                                minLimit={conf.minLimit}
                                maxLimit={conf.maxLimit}
                                current={user.cumulativeInvoicing ?? defaultServiceFee}
                                serviceFee={conf.serviceFee}
                            />
                        ))}
                        <Connector />
                    </>
                )}
            </Flex>
        </DashboardSalesBillingHistoryWrapper>
    );
};

const SalesTabs = [
    { label: 'statistic.tabs.invoices', value: SalesTabEnum.Laskutus },
    { label: 'statistic.tabs.eezy-page', value: SalesTabEnum.EezyPay },
];

const DashboardSalesCardContent = () => {
    const { t } = useTranslation();

    const [activeSalesTab, setActiveSalesTab] = useState<SalesTabEnum>(SalesTabEnum.Laskutus);
    const [visibleBillingHistory, setVisibleBillingHistory] = useState(isDesktopSize());

    const { invoices } = useStatisticsData({
        withVat: false,
        useEezyPay: activeSalesTab === SalesTabEnum.EezyPay,
    });

    const monthPaymentsTotal = useMemo(() => {
        return invoices
            .filter(
                invoiceDateFilter({
                    from: moment().startOf('month').toDate(),
                    to: moment().endOf('month').toDate(),
                }),
            )
            .reduce((prev, curr) => prev + curr.total, 0);
    }, [invoices]);

    const yearPaymentsTotal = useMemo(() => {
        return invoices
            .filter(
                invoiceDateFilter({
                    from: moment().startOf('year').toDate(),
                    to: moment().endOf('year').toDate(),
                }),
            )
            .reduce((prev, curr) => prev + curr.total, 0);
    }, [invoices]);

    const navigate = useNavigate();

    return (
        <div className="flex flex-col md:flex-row">
            <div className="md:flex-5">
                <div className="flex flex-col gap-9">
                    <TabHeaders
                        items={SalesTabs}
                        activeValue={activeSalesTab}
                        onChange={(v) => setActiveSalesTab(v)}
                    />
                    <div className="flex gap-2 md:gap-3 flex-wrap md:flex-nowrap">
                        <SalesStatsCard
                            amount={monthPaymentsTotal / 100}
                            description={t(
                                activeSalesTab === SalesTabEnum.EezyPay
                                    ? 'dashboard.sales.eezy-pay.paymentsThisMonth'
                                    : 'salaries.incomeMonth',
                            )}
                        />
                        <SalesStatsCard
                            amount={yearPaymentsTotal / 100}
                            description={t(
                                activeSalesTab === SalesTabEnum.EezyPay
                                    ? 'dashboard.sales.eezy-pay.paymentsThisYear'
                                    : 'salaries.incomeYear',
                            )}
                        />
                    </div>
                    <div>
                        <Button
                            className="min-w-full md:min-w-0"
                            color="green"
                            startIcon={ChartNoAxesCombined}
                            onClick={() => navigate('/statistics')}
                        >
                            {t('dashboard.sales.navigate-statistics')}
                        </Button>
                    </div>
                </div>
            </div>
            <SectionSeparator />
            <div className="md:flex-3">
                <Button
                    className="w-full mt-2 md:hidden"
                    variant="ghost"
                    endIcon={visibleBillingHistory ? ChevronUp : ChevronDown}
                    onClick={() => setVisibleBillingHistory(!visibleBillingHistory)}
                >
                    {t('dashboard.sales.billing-history')}
                </Button>
                {visibleBillingHistory && <DashboardSalesBillingHistory />}
            </div>
        </div>
    );
};

const DashboardSalesCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <DashboardCard className="w-full">
            <div className="flex flex-col gap-7">
                <div className="flex justify-between">
                    <CardTitle
                        title={t('dashboard.sales.title')}
                        subtitle={t('dashboard.sales.subtitle')}
                        subIcon={<Icon className="small" icon={['far', 'info-circle']} />}
                    />
                    <EezyButton
                        className="v2-btn"
                        color="purple"
                        hasIcon
                        iconAlignment="right"
                        onClick={() => navigate('/salaries')}
                    >
                        {t('salaries.salaries')}
                        <Icon icon={['far', 'arrow-right']} />
                    </EezyButton>
                </div>
                <DashboardSalesCardContent />
            </div>
        </DashboardCard>
    );
};

export default DashboardSalesCard;
