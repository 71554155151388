import { useState } from 'react';
import { EezyLink } from 'components/Buttons';
import type { IDropdownOption } from 'components/form/AutocompleteDropdown';
import { Icon } from 'components/Icon';
import { Modal, ModalActions, ModalContent } from 'components/modals/Modal';
import config from 'config';
import { COLOR_BLUM } from 'styles/variables';
import { currentYear } from 'utils';
import { useTranslation } from 'react-i18next';
import InfoMessage from 'components/InfoMessage';
import { useUser } from 'queries/useUserQuery';
import moment from 'moment';
import Select from 'components/ui/select';
import { Button } from 'components/common/Button';

interface IModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
}

const DownloadTaxReportModal = (props: IModalProps) => {
    const { t, i18n } = useTranslation();
    const user = useUser();

    const registrationYear = user ? moment(user.registrationDate).year() : currentYear();

    const [year, setYear] = useState<string>((currentYear() - 1).toString());

    const yearOptions: IDropdownOption[] = Array.from(
        { length: currentYear() - registrationYear + 1 },
        (_, i) => {
            const year = registrationYear + i;
            return { label: `${year}`, value: `${year}` };
        },
    );

    return (
        <Modal
            id="modal-download-tax-report"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('salaries:taxModal.title') || ''}
            noscroll
            zIndex={50}
        >
            <ModalContent>
                <div className="flex flex-col gap-4">
                    <div className="flex items-center justify-between">
                        <p className="tg-body-bold">
                            <b>{t('salaries:taxModal.year-label')}</b>
                        </p>
                        <Select
                            options={yearOptions}
                            triggerText={t('profile:settings.terminate-account.termination-reasons.select')}
                            onChange={setYear}
                            value={year}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="tg-body-bold">
                            <b>{t('salaries:taxModal.net-report-label')}</b>
                        </p>
                        <EezyLink
                            color="purple"
                            href={`${config.backendUrl3}/net-subtractions/${year}/report.pdf`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('file.download')}
                        </EezyLink>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="tg-body-bold">
                            <b>{t('salaries:taxModal.travel-report-label')}</b>
                        </p>
                        <EezyLink
                            color="purple"
                            href={`${config.backendUrl3}/taxable-costs/${year}/report.pdf`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('file.download')}
                        </EezyLink>
                    </div>
                </div>

                <p className="tg-body mt-5">
                    {t('salaries:taxModal.paragraph-1')}
                    <div className="mt-2">
                        <b>{t('salaries:taxModal.bold-1')}</b>
                        {t('salaries:taxModal.paragraph-3-1')}
                    </div>
                    <div className="mt-2">
                        <b>{t('salaries:taxModal.bold-2')}</b>
                        {t('salaries:taxModal.paragraph-3-2')}
                    </div>
                    <InfoMessage message={t('salaries:taxModal.deductions-note')} background />
                </p>

                <p className="tg-body mt-5">
                    <a
                        className="unstyled"
                        href={
                            i18n.language === 'en'
                                ? 'https://kevytyrittajat.eezy.fi/en/faq/#what-work-related-costs-can-a-light-entrepreneur-deduct-in-taxation'
                                : 'https://kevytyrittajat.eezy.fi/usein-kysyttya/#mita-tyohon-liittyvia-kuluja-kevytyrittaja-voi-vahentaa-verotuksessa'
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t('salaries:taxModal.link-anchor')}{' '}
                        <Icon icon={['far', 'arrow-up-right-from-square']} color={COLOR_BLUM} />
                    </a>
                </p>
            </ModalContent>

            <ModalActions>
                <Button variant="outline" onClick={props.handleModalClose}>
                    {t('general.cancel')}
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default DownloadTaxReportModal;
