import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { type ApolloError, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { Hidden } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import type { ThunkDispatch } from 'redux-thunk';
import type { IInvoice } from '../../../../shared/src/types/invoice';
import { alertAction, confirmAction, type IAlertAction } from 'actions/app';
import { saveRecipientSearchQuery } from 'actions/invoice';
import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { MoveButtonUpToBar } from 'components/layout/MoveButtonUpToBar';
import { PreviewButtonWrapper, PreviewWrapper } from 'components/layout/PreviewWrapper';
import { MultiplePostItWrapper } from 'components/PostIt';
import { AttachmentRibbon, CostRibbon, RibbonContainerInvoicePreview } from 'components/Ribbon';
import { RowTitle } from 'components/textElements';
import { TogglingTools, Tools, ToolsHeader, ToolsRow } from 'components/TogglingTools';
import { COLOR_BLUM, COLOR_GREYS_ANATOMY } from 'styles/variables';
import { canCostInvoiceBeEditedEvenInvoiceIsPaid } from 'utils/costs/costLogic';
import {
    CopyInvoiceMessageEnum,
    costInvoiceNotSent,
    duedateReferences,
    filterInvoicesByRecipientId,
    getById,
    isCreditable,
    isEditable,
    isGroupInvoiceCoworker,
    isIncompleteAavaInvoice,
    isInvoicePartlyPaid,
    isTurnedBackAndNotEditable,
    showCostInvoiceTurnbackReason,
    splitRecipientId,
} from 'utils/invoice/invoiceLogic';
import invoiceTemplates, {
    DEFAULT_INVOICE_TEMPLATE,
    type IInvoiceTemplate,
} from '../../utils/invoice/invoiceTemplates';
import { invoiceValidator } from 'utils/invoice/validators';
import { setLocalStorage } from 'utils/user/userUtils';
import { formatValidationResult } from 'utils/validation';
import CostDocument from '../costs/CostDocument';
import { DeleteInvoice } from '../invoice/DeleteInvoice';
import GroupInvoiceInfoBox from '../invoice/GroupInvoiceInfoBox';
import InvoiceAttachments from '../invoice/InvoiceAttachments';
import InvoiceCreditModal from '../invoice/InvoiceCreditModal';
import InvoiceDocument from '../invoice/InvoiceDocument';
import InvoiceSendModal from '../invoice/InvoiceSendModal';
import {
    CostInvoiceSendingReminderPostIt,
    CostTurnbackReasonPostIt,
    InvoiceSendingFailedPostIt,
    InvoiceTurnbackReasonPostIt,
} from '../invoice/postits';
import {
    COPY_INVOICE,
    CREDIT_INVOICE,
    GET_INVOICE,
    RETURN_INVOICE,
    RETURN_TURNED_BACK_INVOICE,
    SEND_INVOICE,
    VALIDATE_INVOICE,
} from '../invoice/queries';
import { INVOICE_COPY_META, INVOICE_ERRORS_VISIBLE } from '../invoice/queries/invoiceStateQueries';
import { SEND_COST_INVOICE } from '../costs/queries';
import { CollectionInfo } from './CollectionInfo';
import { CreditRequestedInfo } from './CreditRequestedInfo';
import { GET_AAVA_INVOICES, GET_ANKKA_INVOICES, REQUEST_COLLECTION } from './queries';
import { getErrors } from 'utils/apolloErrors';
import { AAVA_INVOICE_LIMIT } from 'constants/invoices';
import { useTranslation } from 'react-i18next';
import { checkContract } from 'containers/invoice/Invoice';
import ClientModal from './ClientModal';
import RelatedObjectMessage from './RelatedObjectMessage';
import InformationModal from 'components/modals/InformationModal';
import LoadingSpinner from '../../components/Loading';
import GET_CONTRACTS from 'containers/invoice/queries/getContracts';
import Show from 'components/ui/Show';
import { useUser } from 'queries/useUserQuery';
import { checkHasForwardHistory } from 'utils/navigation';
import { trpc } from 'trpc';

const TriangleButton = styled.button`
    background: linear-gradient(
        to top right,
        transparent 0%,
        transparent 50%,
        ${COLOR_GREYS_ANATOMY} 50%,
        ${COLOR_GREYS_ANATOMY} 100%
    );
    position: absolute;
    padding: 10px 10px 35px 35px;
    right: 0;
    top: 0;
`;

type InvoicePreviewProps = {
    alertAction: (payload: IAlertAction) => void;
};
const InvoicePreview: React.FC<InvoicePreviewProps> = (props: InvoicePreviewProps) => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const user = useUser();
    const trpcUtils = trpc.useUtils();
    const client = useApolloClient();

    const [invoice, setInvoice] = useState<IInvoice>();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [sendModalOpen, setSendModalOpen] = useState(false);
    const [creditModalOpen, setCreditModalOpen] = useState(false);
    const [cancelInvoiceRequested, setCancelInvoiceRequested] = useState(false);
    const [clientModalOpen, setClientModalOpen] = useState(false);
    const [costInvoiceNotEditableModalOpen, setCostInvoiceNotEditableModalOpen] = useState(false);
    const [hasContract, setHasContract] = useState<boolean>(false);
    const [isSending, setIsSending] = useState(false);

    const attachmentFirstScrollRef = useRef<HTMLSpanElement>(null);
    const costInvoiceScrollRef = useRef<HTMLSpanElement>(null);

    const params = useParams();

    const id = Number.parseInt(params?.invoiceId || '', 10);
    const isGroupInvoice = window.location.href.includes('group');

    const { data: allInvoicesData } = trpc.invoices.list.useQuery();

    const [fetchContracts] = useLazyQuery(GET_CONTRACTS);

    const [fetchInvoice, { loading, error }] = useLazyQuery(GET_INVOICE, {
        onError: () => {
            navigate('/invoices', { replace: true });
        },
        variables: {
            id,
        },
    });

    const loadInvoice = async (isRefetch = false) => {
        try {
            const { data } = await fetchInvoice({
                fetchPolicy: 'network-only',
                context: {
                    headers: {
                        'x-info-refetch': isRefetch ? 1 : 0,
                    },
                },
            });

            if (!data?.invoice) {
                return;
            }

            setInvoice(data.invoice);
        } catch (e) {
            console.error(e);
        }
    };

    const [sendDebtCollection, { loading: loadingDebtCollection }] = useMutation(REQUEST_COLLECTION, {
        variables: { invoiceId: invoice?.id },
        onError: () => toast.error(t('errors.general')),
        onCompleted: () => {
            toast(t('general.saved'));
            loadInvoice();
        },
    });

    useEffect(() => {
        if (id) {
            const isBackHistory = checkHasForwardHistory();
            loadInvoice(isBackHistory);
        }
    }, [id, fetchInvoice]);

    const [sendCostInvoice, { loading: costInvoiceSendingLoading }] = useMutation(SEND_COST_INVOICE, {
        onCompleted: () => {
            if (invoice?.costInvoice?.status === 'turned_back') {
                toast(t('costs.sent-corrections'));
            } else {
                toast(t('costs.sent-costinvoice'));
            }

            trpcUtils.invoices.list.invalidate();
        },
        onError: (e: ApolloError) => {
            const errors = getErrors(e);
            const errorMsg = errors?.costs?.includes('MISSING_RECEIPT_FILE')
                ? 'errors.cost-invoice-validation.missing-receipt-file'
                : 'errors.general';
            toast.error(t(errorMsg));
        },
        refetchQueries: () => [
            {
                query: GET_INVOICE,
                variables: {
                    id,
                    isGroupInvoice,
                },
            },
        ],
        variables: { id: invoice?.costInvoice?.id },
    });

    const [checkSalaryPaymentStatusForInvoiceAndSend] = useLazyQuery(GET_INVOICE, {
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
            const { invoice: resInvoice } = data;
            if (
                resInvoice &&
                resInvoice.status === 'paid' &&
                !canCostInvoiceBeEditedEvenInvoiceIsPaid(resInvoice)
            ) {
                setCostInvoiceNotEditableModalOpen(true);
            } else {
                if (sendCostInvoice) {
                    sendCostInvoice();
                }
            }
        },
    });

    const [validateInvoice, { loading: validationLoading }] = useLazyQuery(VALIDATE_INVOICE, {
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setSendModalOpen(true);
            setIsSending(false);
        },
        onError: (validationErrors) => {
            setIsSending(false);
            dispatch(
                confirmAction({
                    id: `send-${id}`,
                    onAction: () => {
                        setLocalStorage(`invoice-error-${id}`, validationErrors);
                        navigate(`/invoices/${id}`);
                    },
                    texts: {
                        paragraph: 'invoice.send-continue-confirmation',
                        submit: 'invoice.edit-invoice',
                        title: 'invoice.send',
                    },
                }),
            );
        },
    });

    const [sendInvoice, { loading: sendingLoading }] = useMutation(SEND_INVOICE, {
        onCompleted: (result) => {
            // InvoiceSendingStatusListener takes care of showing toasts
            const newId = result.sendInvoiceById?.id;
            if (newId) {
                navigate(`/invoices/view/${newId}`, { replace: true });
                return;
            }
            trpcUtils.invoices.list.invalidate();
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
        refetchQueries: () => [
            //     { query: GET_INVOICE, variables: { id } },
            //     { query: GET_ANKKA_INVOICES },
            //     {
            //         query: GET_AAVA_INVOICES,
            //         variables: {
            //             page: { offset: 0, pageSize: AAVA_INVOICE_LIMIT },
            //         },
            //     },
        ],
    });

    const handleSend = (messageToEezy: string) => {
        if (isSending || sendingLoading || validationLoading) return; // Extra check

        setIsSending(true);
        sendInvoice({
            variables: {
                id,
                messageToEezy,
                hasContract,
            },
        }).finally(() => {
            setIsSending(false);
        });
    };

    const [copyInvoice, { loading: copyLoading }] = useMutation(COPY_INVOICE, {
        onCompleted: ({ copyInvoice: newInvoice }) => {
            try {
                const messages: CopyInvoiceMessageEnum[] = newInvoice?.meta?.messages ?? [];

                for (const message of messages) {
                    if (message === CopyInvoiceMessageEnum.INVOICING_VAT_CHANGED) {
                        props.alertAction({
                            id: 'copy-vat-changed',
                            texts: {
                                paragraph: 'errors.copy.vat-changed',
                                submit: 'form.understand',
                                title: '',
                            },
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }

            const copyid = newInvoice?.invoice.id;
            return navigate(`/invoices/${copyid}`);
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
        update(cache, { data: { copyInvoice: invoiceCopy } }) {
            const newInvoice = invoiceCopy?.invoice;
            const metaData = invoiceCopy?.meta;

            if (newInvoice) {
                cache.writeQuery({
                    data: {
                        invoice: newInvoice,
                    },
                    query: GET_INVOICE,
                    variables: { id: newInvoice.id },
                });
                if (metaData) {
                    client.writeQuery({
                        data: {
                            copyMeta: {
                                __typename: 'MetaMessages',
                                id: newInvoice.id,
                                messages: metaData.messages,
                            },
                        },
                        query: INVOICE_COPY_META,
                    });
                }
            }
        },
        variables: { invoiceId: id },
        refetchQueries: () => [{ query: GET_ANKKA_INVOICES }],
    });

    const [returnInvoice] = useMutation(RETURN_INVOICE, {
        onCompleted: ({ returnInvoice: newInvoice }) => {
            navigate(`/invoices/${newInvoice.id}`, { replace: true });
        },
        onError: () => toast.error(t('errors.general')),
        refetchQueries: () => [
            { query: GET_ANKKA_INVOICES },
            {
                query: GET_AAVA_INVOICES,
                variables: { page: { offset: 0, pageSize: AAVA_INVOICE_LIMIT } },
            },
        ],
        variables: { id },
    });

    const { mutateAsync: removeInvoiceFromCacheMutation } =
        trpc.invoices.removeInvoiceFromCache.useMutation();

    const [returnTurnedBackInvoice] = useMutation(RETURN_TURNED_BACK_INVOICE, {
        onCompleted: async ({ returnTurnedBackInvoice: newInvoice }) => {
            if (invoice?.id) await removeInvoiceFromCacheMutation({ id: invoice.id });
            trpcUtils.invoices.list.invalidate();

            navigate(`/invoices/${newInvoice.id}`, { replace: true });
        },
        onError: () => toast.error(t('errors.general')),
        variables: { id },
    });

    const [creditInvoice] = useMutation(CREDIT_INVOICE, {
        onCompleted: () => {
            toast(t('general.saved'));
        },
        onError: () => toast.error(t('errors.general')),
    });

    const scrollToFirstAttachment = useCallback(() => {
        if (attachmentFirstScrollRef.current) {
            attachmentFirstScrollRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [attachmentFirstScrollRef]);

    const scrollToCostInvoice = useCallback(() => {
        if (costInvoiceScrollRef.current) {
            costInvoiceScrollRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [costInvoiceScrollRef]);

    const invoiceBrief = getById(invoice?.id, allInvoicesData?.items);

    // Refetch Ankkanet invoice when ever invoice changes to make sure info is fresh
    useEffect(() => {
        if (invoice) {
            if (invoice.recipient?.id) {
                fetchContracts({
                    fetchPolicy: 'network-only',
                    variables: {
                        clientId: Number.parseInt(invoice.recipient.id),
                        clientType: invoice.recipient.type,
                    },
                    onCompleted: (data) => {
                        if (checkContract(data.contracts)) {
                            setHasContract(true);
                        } else {
                            setHasContract(false);
                        }
                    },
                });
            }
        }
    }, [invoice]);

    useEffect(() => {
        if (invoiceBrief?.status !== invoice?.status) {
            // refetch ivoice if status is updated (in aava)
            loadInvoice(true);
        }
    }, [invoiceBrief]);

    if (!invoice || error) {
        return null;
    }

    const editable = isEditable(invoice, false);
    const selectedTemplate: IInvoiceTemplate =
        invoiceTemplates.find((t) => t.id === invoice?.template) || DEFAULT_INVOICE_TEMPLATE;

    const recipientName = invoice.recipient?.name || '';
    const sameInvoices = filterInvoicesByRecipientId(
        splitRecipientId(invoice.recipient?.id || ''),
        allInvoicesData?.items || [],
    );

    const handleOpen = () => {
        navigate(`/invoices/${id}`);
    };

    const handleClose = () => {
        navigate('/invoices');
    };

    const handleSearchAllClients = () => {
        if (recipientName) {
            dispatch(saveRecipientSearchQuery(recipientName, splitRecipientId(invoice.recipient?.id || '')));
        }
    };

    const handleSearchAllClientsMobile = () => {
        handleSearchAllClients();
        navigate('/invoices');
    };

    const handleOpenClientModal = () => {
        setClientModalOpen(true);
    };

    const handleEditBeforeSend = () => {
        client.writeQuery({
            data: { invoiceErrorsVisible: true },
            query: INVOICE_ERRORS_VISIBLE,
        });
        navigate(`/invoices/${invoice.id}`);
    };

    const handleEditIncomplete = () => {
        dispatch(
            confirmAction({
                id: `edit-${invoice.id}`,
                onAction: () => {
                    toast(t('invoice.edit-modal.loading'));
                    returnInvoice();
                },
                texts: {
                    paragraph: 'invoice.edit-modal.paragraph',
                    paragraph2: 'invoice.edit-modal.paragraph2',
                    submit: 'invoice.edit-modal.submit',
                    title: 'invoice.edit-modal.title',
                },
            }),
        );
    };

    const handleEditTurnedBack = () => {
        dispatch(
            confirmAction({
                id: `edit-turned-back-${invoice.id}`,
                onAction: () => {
                    toast(t('invoice.edit-modal-turned-back.loading'));
                    returnTurnedBackInvoice();
                },
                texts: {
                    paragraph: 'invoice.edit-modal-turned-back.paragraph',
                    paragraph2: 'invoice.edit-modal-turned-back.paragraph2',
                    submit: 'invoice.edit-modal-turned-back.submit',
                    title: 'invoice.edit-modal-turned-back.title',
                },
            }),
        );
    };

    const handleEditIncompleteInAava = () => {
        dispatch(
            confirmAction({
                id: `edit-incomplete-${invoice.id}`,
                onAction: () => {
                    toast(t('invoice.edit-modal-incomplete.loading'));
                    returnInvoice();
                },
                texts: {
                    paragraph: 'invoice.edit-modal-incomplete.paragraph',
                    paragraph2: 'invoice.edit-modal-incomplete.paragraph2',
                    submit: 'invoice.edit-modal-incomplete.submit',
                    title: 'invoice.edit-modal-incomplete.title',
                },
            }),
        );
    };

    const handleSendValidation = () => {
        if (isSending || sendingLoading || validationLoading) return; // Prevent multiple clicks

        if (
            formatValidationResult(
                invoiceValidator.validate({
                    ...invoice,
                    ...duedateReferences(invoice.dispatchDate),
                }),
            )
        ) {
            dispatch(
                confirmAction({
                    id: `send-${invoice.id}`,
                    onAction: handleEditBeforeSend,
                    texts: {
                        paragraph: 'invoice.send-continue-confirmation',
                        submit: 'invoice.edit-invoice',
                        title: 'invoice.send',
                    },
                }),
            );
        } else {
            setIsSending(true); // Set sending state to true
            // Validate also in backend
            validateInvoice({
                variables: {
                    id: invoice.id,
                },
            });
        }
    };

    const handleCopy = () => {
        if (!copyLoading) {
            toast(t('errors.copy.ongoing'));
            copyInvoice();
        }
    };

    const handleCredit = (sendToClient: boolean) => {
        setCancelInvoiceRequested(true);
        creditInvoice({ variables: { id, sendToClient } });
    };

    const partlyPaid = isInvoicePartlyPaid(invoice);

    const invoiceErrors = formatValidationResult(
        invoiceValidator.validate({
            ...invoice,
            ...duedateReferences(invoice.dispatchDate),
        }),
    );

    const groupInvoiceCoworker = isGroupInvoiceCoworker(user?.id, invoice);

    const handleCostInvoiceSend = () => {
        // If invoice is paid check salary payment status
        if (invoice?.status === 'paid') {
            checkSalaryPaymentStatusForInvoiceAndSend({
                variables: {
                    id: invoice?.id,
                    isGroupInvoice: groupInvoiceCoworker,
                },
            });
        } else {
            sendCostInvoice();
        }
    };

    return (
        <PreviewWrapper>
            <Hidden mdUp>
                <div style={{ margin: '0px -15px' }}>
                    <MoveButtonUpToBar>
                        <EezyButton className="edit-button-xs" color="purple" onClick={handleOpen}>
                            {editable ? t('general.edit') : t('general.open')}
                        </EezyButton>
                    </MoveButtonUpToBar>

                    <Hidden mdUp>
                        <>
                            {invoice.sendingFailed && <InvoiceSendingFailedPostIt padded />}

                            {invoice?.status === 'turned_back' && invoice?.turnBackReason && (
                                <InvoiceTurnbackReasonPostIt invoice={invoice} padded />
                            )}

                            {invoiceBrief && costInvoiceNotSent(invoiceBrief) && (
                                <CostInvoiceSendingReminderPostIt padded />
                            )}

                            {showCostInvoiceTurnbackReason(invoice?.costInvoice) && (
                                <CostTurnbackReasonPostIt invoice={invoice} padded />
                            )}
                        </>
                    </Hidden>

                    <TogglingTools
                        titleProps={{
                            style: { color: COLOR_BLUM, fontWeight: 'bold' },
                        }}
                        titleTransKey="invoice.tools-section-title"
                    >
                        <div style={{ padding: '0 15px 15px 15px' }}>
                            {invoice?.status === 'unaccepted' && (
                                <ToolsRow
                                    buttonTransKey="general.edit"
                                    titleTransKey="invoice.edit-invoice"
                                    onClick={handleEditIncomplete}
                                />
                            )}
                            {isTurnedBackAndNotEditable(invoice) && (
                                <ToolsRow
                                    buttonTransKey="general.edit"
                                    titleTransKey="invoice.edit-invoice"
                                    onClick={handleEditTurnedBack}
                                />
                            )}
                            {isIncompleteAavaInvoice(invoice) && (
                                <ToolsRow
                                    buttonTransKey="general.edit"
                                    titleTransKey="invoice.edit-invoice"
                                    onClick={handleEditIncompleteInAava}
                                />
                            )}
                            {recipientName && (
                                <ToolsRow
                                    buttonTransKey="invoice.client-info"
                                    title={t('invoice.clients-info') || ''}
                                    onClick={handleOpenClientModal}
                                />
                            )}
                            {recipientName && (
                                <ToolsRow
                                    buttonTransKey="general.do-list"
                                    title={
                                        t('invoice.clients-all-invoices', { amount: sameInvoices.length }) ||
                                        ''
                                    }
                                    onClick={handleSearchAllClientsMobile}
                                />
                            )}
                            {editable && (
                                <Tools
                                    header={
                                        <ToolsHeader
                                            titleTransKey="invoice.sending"
                                            style={{
                                                color: COLOR_BLUM,
                                            }}
                                        >
                                            <EezyButton
                                                color="purple"
                                                transparent
                                                onClick={handleSendValidation}
                                            >
                                                {sendingLoading || validationLoading ? (
                                                    <LoadingSpinner size="1em" />
                                                ) : (
                                                    t('invoice.send')
                                                )}
                                            </EezyButton>
                                        </ToolsHeader>
                                    }
                                />
                            )}
                            <Tools
                                header={
                                    <ToolsHeader
                                        titleTransKey="invoice.copy"
                                        style={{
                                            color: COLOR_BLUM,
                                        }}
                                    >
                                        <EezyButton color="purple" transparent onClick={handleCopy}>
                                            {copyLoading ? <LoadingSpinner size="1em" /> : t('form.copy')}
                                        </EezyButton>
                                    </ToolsHeader>
                                }
                            />
                            {isCreditable(invoice) && (
                                <Tools
                                    header={
                                        <ToolsHeader
                                            titleTransKey={
                                                partlyPaid
                                                    ? 'invoice.credit.partly-paid.button'
                                                    : 'invoice.credit.unpaid.button'
                                            }
                                        >
                                            <EezyButton
                                                color="purple"
                                                transparent
                                                disabled={invoice.creditRequested || cancelInvoiceRequested}
                                                onClick={() => setCreditModalOpen(true)}
                                            >
                                                {t(
                                                    partlyPaid
                                                        ? 'invoice.credit.partly-paid.button'
                                                        : 'invoice.credit.unpaid.button',
                                                )}
                                            </EezyButton>
                                        </ToolsHeader>
                                    }
                                />
                            )}
                            {editable && (
                                <ToolsRow
                                    buttonTransKey="form.delete"
                                    titleTransKey="invoice.delete"
                                    onClick={() => {
                                        setDeleteModalOpen(true);
                                    }}
                                />
                            )}
                        </div>
                    </TogglingTools>
                </div>
            </Hidden>

            <Hidden mdDown>
                {recipientName && (
                    <PreviewButtonWrapper>
                        <div>
                            <RowTitle>{t('invoice.clients-info')}</RowTitle>
                        </div>
                        <div>
                            <EezyButton color="purple" dark onClick={handleOpenClientModal}>
                                {t('invoice.client-info')}
                            </EezyButton>
                            <EezyButton color="purple" dark onClick={handleSearchAllClients}>
                                {t('invoice.clients-all-invoices', {
                                    amount: sameInvoices.length,
                                })}
                            </EezyButton>
                        </div>
                    </PreviewButtonWrapper>
                )}
                <PreviewButtonWrapper>
                    <div>
                        <RowTitle>{t('invoice.functions')}</RowTitle>
                    </div>
                    <div>
                        {editable && (
                            <EezyButton
                                color="purple"
                                dark
                                onClick={handleSendValidation}
                                disabled={isSending || sendingLoading || validationLoading}
                            >
                                {isSending || sendingLoading || validationLoading ? (
                                    <LoadingSpinner size="1em" />
                                ) : (
                                    t('invoice.send-invoice')
                                )}
                            </EezyButton>
                        )}

                        <EezyButton color="purple" dark onClick={handleCopy}>
                            {copyLoading ? <LoadingSpinner size="1em" /> : t('form.copy')}
                        </EezyButton>

                        {isCreditable(invoice) && (
                            <EezyButton
                                color="purple"
                                dark
                                disabled={invoice.creditRequested || cancelInvoiceRequested}
                                onClick={() => setCreditModalOpen(true)}
                            >
                                {t(
                                    partlyPaid
                                        ? 'invoice.credit.partly-paid.button'
                                        : 'invoice.credit.unpaid.button',
                                )}
                            </EezyButton>
                        )}

                        {editable && (
                            <EezyButton
                                color="purple"
                                dark
                                onClick={() => {
                                    setDeleteModalOpen(true);
                                }}
                            >
                                {t('form.delete')}
                            </EezyButton>
                        )}

                        {invoice?.status === 'unaccepted' && (
                            <EezyButton color="purple" onClick={handleEditIncomplete}>
                                {t('general.edit')}
                            </EezyButton>
                        )}
                        {isTurnedBackAndNotEditable(invoice) && (
                            <EezyButton color="purple" onClick={handleEditTurnedBack}>
                                {t('general.edit')}
                            </EezyButton>
                        )}
                        {isIncompleteAavaInvoice(invoice) && (
                            <EezyButton color="purple" onClick={handleEditIncompleteInAava}>
                                {t('general.edit')}
                            </EezyButton>
                        )}

                        <EezyButton color="purple" onClick={handleOpen}>
                            {editable ? t('general.edit') : t('general.open')}
                        </EezyButton>
                    </div>
                </PreviewButtonWrapper>
            </Hidden>

            <CollectionInfo
                invoice={invoice}
                briefInvoice={invoiceBrief}
                loading={loadingDebtCollection || loading}
                onClickRequestDebtCollection={sendDebtCollection}
            />
            <CreditRequestedInfo invoice={invoice} />

            <Show when={!!invoice.isGroupInvoice}>
                <GroupInvoiceInfoBox hideButton={true} invoice={invoice} isOwner={true} preview={true} />
            </Show>

            <InvoiceDocument
                allowDownload
                editable={false}
                invoice={invoice}
                invoiceBrief={invoiceBrief}
                invoiceErrors={invoiceErrors}
                loading={loading}
                handleInvoiceUpdate={() => null}
                template={selectedTemplate}
                hasContract={hasContract}
            >
                <TriangleButton aria-label={t('menu.close') || ''} onClick={handleClose}>
                    <Icon icon={['fal', 'times']} color={COLOR_BLUM} />
                </TriangleButton>

                <Hidden mdDown>
                    <MultiplePostItWrapper>
                        {invoice.sendingFailed && <InvoiceSendingFailedPostIt square />}

                        {invoice?.status === 'turned_back' && invoice?.turnBackReason && (
                            <InvoiceTurnbackReasonPostIt invoice={invoice} square />
                        )}

                        {invoiceBrief && costInvoiceNotSent(invoiceBrief) && (
                            <CostInvoiceSendingReminderPostIt square />
                        )}

                        {showCostInvoiceTurnbackReason(invoice?.costInvoice) && (
                            <CostTurnbackReasonPostIt invoice={invoice} square />
                        )}
                    </MultiplePostItWrapper>
                </Hidden>

                <RibbonContainerInvoicePreview>
                    {invoice?.files && invoice?.files.length > 0 && (
                        <AttachmentRibbon onClick={scrollToFirstAttachment}>
                            {t('invoice.attachments-count', {
                                count: invoice?.files.length,
                            })}
                        </AttachmentRibbon>
                    )}
                    {invoice?.costInvoice && (
                        <CostRibbon onClick={scrollToCostInvoice}>{t('costs.breakdown-short')}</CostRibbon>
                    )}
                </RibbonContainerInvoicePreview>
            </InvoiceDocument>

            <Show when={!!invoice}>
                <RelatedObjectMessage relatedObject={{ id: invoice.id, type: 'invoice' }} />
            </Show>

            <Show when={!!invoice?.costInvoice}>
                <span ref={costInvoiceScrollRef} />
                <CostDocument
                    invoice={invoice}
                    onCostInvoiceSend={handleCostInvoiceSend}
                    sendingLoading={costInvoiceSendingLoading}
                    editButtonOnInvoicePreview
                    hasContract={hasContract}
                />
                <RelatedObjectMessage
                    relatedObject={{
                        id: invoice?.costInvoice?.id || 0,
                        type: 'cost_invoice',
                    }}
                />
            </Show>

            <Show when={!!invoice?.files}>
                <span ref={attachmentFirstScrollRef} />
                <InvoiceAttachments
                    deleteLoading={false}
                    editable={editable}
                    handleFileRemoval={() => null}
                    files={invoice?.files}
                    invoiceId={invoice.id}
                />
            </Show>

            <Show when={invoice && editable}>
                <DeleteInvoice
                    invoiceId={invoice.id}
                    open={deleteModalOpen}
                    toggleOpen={(open: boolean) => setDeleteModalOpen(open)}
                />
            </Show>

            <Show when={editable && sendModalOpen}>
                <InvoiceSendModal
                    handleSend={handleSend}
                    handleModalClose={() => setSendModalOpen(false)}
                    invoice={invoice}
                    isOpen={sendModalOpen}
                />
            </Show>

            <Show when={isCreditable(invoice) && creditModalOpen}>
                <InvoiceCreditModal
                    handleCredit={handleCredit}
                    handleModalClose={() => setCreditModalOpen(false)}
                    invoice={invoice}
                    isOpen={creditModalOpen}
                />
            </Show>

            <Show when={!!(clientModalOpen && invoice.recipient?.id && invoice.recipient?.type)}>
                <ClientModal
                    clientId={invoice.recipient!.id!}
                    clientType={invoice.recipient!.type!}
                    handleModalClose={() => setClientModalOpen(false)}
                    invoice={invoice}
                    isOpen={clientModalOpen}
                />
            </Show>

            <Show when={costInvoiceNotEditableModalOpen}>
                <InformationModal
                    id="add-cost-paid-info"
                    isOpen={costInvoiceNotEditableModalOpen}
                    onClose={() => {
                        setCostInvoiceNotEditableModalOpen(false);
                    }}
                    title={'costs.warning.salary-already-paid-title'}
                    texts={[
                        'costs.warning.salary-already-paid-send',
                        'costs.warning.salary-already-paid-reach-out',
                    ]}
                />
            </Show>
        </PreviewWrapper>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        alertAction: (payload: IAlertAction) => {
            dispatch(alertAction(payload));
        },
    };
};

export default connect(null, mapDispatchToProps)(InvoicePreview);
