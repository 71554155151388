import { type ChangeEvent, type FC, useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ManualCard } from 'components/cards';
import { ExampleImage } from 'components/ExampleImage';
import { Flex } from 'components/Flex';
import { FormRadio, FormRow } from 'components/form';
import FileInput from 'components/form/FileInput';
import LoadingSpinner from 'components/Loading';
import { PSmall } from 'components/textElements';
import config from 'config';
import { COLOR_BLUM, COLOR_DARK_GRAY, SCREEN_S, COLOR_IMPORTANT } from 'styles/variables';
import { COST_RECEIPT_FILETYPES } from 'utils/costs/costLogic';
import { getNameOptions, WHOLE_NAME } from 'utils/profile/marketing';
import { UPDATE_USER_DATA } from './queries';
import { InfoTooltip } from 'components/ToolTip';
import { Icon } from 'components/Icon';
import { useUserQuery } from 'queries/useUserQuery';
import { Button } from 'components/common/Button';
import { Input } from 'components/common/Input';

const ImageWrapper = styled.div`
    margin-right: 10px;
`;

const IconWrapper = styled.span`
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
`;

const MarketingWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${SCREEN_S}px) {
        flex-direction: row;
    }
`;

const MarketingCopyLink = styled.div`
    display: flex;
    flex-direction: column;

    button {
        margin-top: 10px;
    }

    @media (min-width: ${SCREEN_S}px) {
        flex-direction: row;
        justify-content: space-between;

        button {
            margin-top: 0;
        }
    }
`;

const ErrorMessage = styled.div`
    color: ${COLOR_IMPORTANT};
`;

const bonusLink =
    config.env === 'prod'
        ? 'https://extra.eezy.fi/register?bonusCode='
        : 'https://eezy.test.aavaohjelmistot.fi/register?bonusCode=';
interface IProps {
    deleteAttachment: (fileId: number) => void;
    deleteLoading: boolean;
    fileLoading: boolean;
    uploadLogo: (data: FormData) => void;
}

const ProfileMarketing: FC<IProps> = ({ deleteAttachment, deleteLoading, fileLoading, uploadLogo }) => {
    const { t } = useTranslation();
    const { data: user, refetch, isLoading: profileLoading } = useUserQuery();

    const [companyName, setCompanyName] = useState(''); // Declare state inside the component
    const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);

    const [closeF, setCloseF] = useState<any>(null);
    const [firstNameForInvoice, setFirstNameForInvoice] = useState(WHOLE_NAME);

    const firstName = firstNameForInvoice === WHOLE_NAME ? user?.firstName : firstNameForInvoice;

    const nameOnInvoice = () => `${companyName && `${companyName} / `}${firstName} ${user?.lastName}`;

    const handleCompanyNameChange = (val: string) => {
        setCompanyName(val.slice(0, 35)); // Limit the input to 35 characters
        setIsCompanyNameValid(val.length !== 1);
    };

    const getReferralLink = (recruitmentCode: string): string => {
        return `${bonusLink}${recruitmentCode}`;
    };

    // Local version of update is needed as we want to close the card only if the mutation
    // is completed, and there are currently no other ways to call onCompleted
    const [updateUserData, { loading }] = useMutation(UPDATE_USER_DATA, {
        onCompleted: () => {
            refetch();
            if (closeF) {
                closeF();
            }
            toast(t('general.saved'));
            handleReset();
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
    });

    const handleSave = (callback: () => void) => {
        if (!isCompanyNameValid) return;
        // callback-fn (that closes the card) is available here but needs to be called in
        // onCompleted of the mutation, thus the hack
        setCloseF(() => () => callback());

        updateUserData({
            variables: {
                companyName,
                firstNameForInvoice: firstName,
            },
        });
    };

    const handleReset = useCallback(() => {
        setCompanyName(user?.companyName || '');
        if (user?.firstNameForInvoice === user?.firstName) {
            setFirstNameForInvoice(WHOLE_NAME);
        } else {
            setFirstNameForInvoice(user?.firstNameForInvoice || WHOLE_NAME);
        }
    }, [user, user]);

    useEffect(() => {
        handleReset();
    }, [user, handleReset]);

    const handleAddFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const newFile = e.target.files[0];
            const uploadData = new FormData();
            uploadData.append('file', newFile);
            uploadLogo(uploadData);
        }
    };

    const onCopy = () => {
        toast(t('form.copied'));
    };

    const imageId = user?.pendingInvoiceLogoId || user?.invoiceLogoId;

    const actualLengthWithoutSpaces = companyName.replace(/\s+/g, '').length;

    return (
        <ManualCard
            title={t('profile:marketing.title')}
            editableContent={
                <div className="flex flex-col gap-4">
                    <Input
                        disabled
                        label={t('profile:marketing.recruitmentCode.label')}
                        helpText={t('profile:marketing.recruitmentCode.help')}
                        onChange={(e) => handleCompanyNameChange(e.target.value)}
                        name="recruitmentCode"
                        value={user?.recruitmentCode}
                    />

                    {getNameOptions(user?.firstName ?? '').length > 2 && (
                        <FormRadio
                            label={t('profile:marketing.name.label') || ''}
                            onTwoLines
                            options={getNameOptions(user?.firstName ?? '')}
                            name="firstNameForInvoice"
                            onChange={(val) => setFirstNameForInvoice(val)}
                            value={firstNameForInvoice}
                        />
                    )}

                    <Input
                        required
                        label={t('profile:marketing.marketingName.label')}
                        helpText={[
                            t('profile:marketing.marketingName.help1'),
                            t('profile:marketing.marketingName.help2'),
                        ].join('\n')}
                        onChange={(e) => handleCompanyNameChange(e.target.value)}
                        name="companyName"
                        value={companyName}
                        maxLength={35}
                    />

                    {actualLengthWithoutSpaces < 2 && companyName.length > 0 && (
                        <ErrorMessage>{t('profile:marketing.marketingName.tooShort')}</ErrorMessage>
                    )}

                    {companyName.length === 35 && (
                        <ErrorMessage>{t('profile:marketing.marketingName.tooLong')}</ErrorMessage>
                    )}

                    <FormRow>
                        <div className="flex flex-col">
                            <div className="tg-caption-bold">{t('profile:marketing.logo.title')}</div>
                            {!imageId && (
                                <FileInput
                                    accept={COST_RECEIPT_FILETYPES}
                                    onChange={handleAddFile}
                                    style={{
                                        borderRadius: 100,
                                    }}
                                >
                                    {fileLoading || profileLoading ? (
                                        <LoadingSpinner color={COLOR_BLUM} size="1em" />
                                    ) : (
                                        <span aria-hidden>{t('file.upload')}</span>
                                    )}
                                </FileInput>
                            )}
                        </div>
                        <p className="tg-body mt-5">{t('profile:marketing.logo.description')}</p>
                        {imageId && (
                            <Flex spread>
                                <ImageWrapper>
                                    <img
                                        aria-label={'logo'}
                                        src={`${config.backendUrl3}/logo/${imageId}/picture.png`}
                                        alt="logo"
                                        style={{
                                            height: '102px',
                                            marginRight: 10,
                                            width: '102px',
                                        }}
                                    />
                                    {imageId !== user?.invoiceLogoId && (
                                        <>
                                            <br />
                                            <span
                                                style={{
                                                    color: COLOR_DARK_GRAY,
                                                    fontSize: 12,
                                                    fontStyle: 'italic',
                                                }}
                                            >
                                                {t('invoice.statuses.unaccepted')}
                                            </span>
                                        </>
                                    )}
                                </ImageWrapper>
                                <Button
                                    color="red"
                                    loading={deleteLoading || fileLoading || profileLoading}
                                    onClick={() => deleteAttachment(imageId)}
                                >
                                    {t('form.delete')}
                                </Button>
                            </Flex>
                        )}
                    </FormRow>

                    <div className="flex flex-col gap-2">
                        <div className="tg-caption-bold">{t('profile:marketing.example.title')}</div>
                        <ExampleImage>
                            <div className="flex flex-col">
                                <div className="tg-caption-bold">{t('invoice.recipient')}</div>

                                <p className="tg-body">{t('profile:marketing.example.company')}</p>
                                <p className="tg-body">{t('profile:marketing.example.address1')}</p>
                                <p className="tg-body">{t('profile:marketing.example.address2')}</p>
                            </div>
                            <div className="flex flex-col">
                                {imageId && (
                                    <ImageWrapper>
                                        <img
                                            aria-label={'logo'}
                                            alt={'logo'}
                                            src={`${config.backendUrl3}/logo/${imageId}/picture.png`}
                                            style={{
                                                height: '102px',
                                                marginRight: 10,
                                                width: '102px',
                                            }}
                                        />
                                    </ImageWrapper>
                                )}
                                <div className="flex flex-col">
                                    <div className="tg-caption-bold">
                                        {t('profile:marketing.example.contractor')}
                                    </div>
                                    <PSmall data-mf-replace="**REMOVED**" color={COLOR_DARK_GRAY}>
                                        {nameOnInvoice()}
                                    </PSmall>
                                </div>
                            </div>
                        </ExampleImage>
                    </div>
                </div>
            }
            onClose={handleReset}
            onSave={handleSave}
            saveLoading={loading}
            viewContent={
                <MarketingWrapper>
                    {imageId && (
                        <ImageWrapper>
                            <img
                                aria-label={'logo'}
                                alt={'logo'}
                                src={`${config.backendUrl3}/logo/${imageId}/picture.png`}
                                style={{
                                    height: '102px',
                                    marginRight: 10,
                                    width: '102px',
                                }}
                            />
                        </ImageWrapper>
                    )}
                    <Flex column style={{ flexGrow: 1 }}>
                        <MarketingCopyLink>
                            <div style={{ paddingRight: 15 }}>
                                <p className="tg-body">
                                    <b>
                                        {t('profile:marketing.recruitmentCode.label')}:
                                        <InfoTooltip
                                            title={t('profile:marketing.recruitmentCode.tooltip')}
                                            placement="top"
                                            enterTouchDelay={0}
                                            arrow
                                        >
                                            <IconWrapper>
                                                <Icon
                                                    icon={['fas', 'info-circle']}
                                                    color={COLOR_BLUM}
                                                    style={{ width: '15px' }}
                                                />
                                            </IconWrapper>
                                        </InfoTooltip>
                                    </b>
                                </p>
                                <p className="tg-body">
                                    {t('profile:marketing.recruitmentCode.yourCode')}:{' '}
                                    <span data-mf-replace="**REMOVED**">{user?.recruitmentCode}</span>
                                </p>
                            </div>
                            <div className="sm:ml-auto">
                                {user?.recruitmentCode && (
                                    <CopyToClipboard
                                        onCopy={onCopy}
                                        text={getReferralLink(user?.recruitmentCode)}
                                    >
                                        <Button variant="outline">
                                            {t('profile:marketing.recruitmentCode.copyLink')}
                                        </Button>
                                    </CopyToClipboard>
                                )}
                            </div>
                        </MarketingCopyLink>

                        <p className="tg-body mt-4">
                            <b>{t('profile:marketing.name-on-the-invoice')}:</b>
                        </p>
                        <p className="tg-body" data-mf-replace="**REMOVED**">
                            {nameOnInvoice()}
                        </p>

                        {!imageId && (
                            <>
                                <p className="tg-body" style={{ marginTop: 10 }}>
                                    <b>{t('profile:marketing.logo.nologo-title')}:</b>
                                </p>
                                <p className="tg-body">{t('profile:marketing.logo.nologo-description')}</p>
                            </>
                        )}
                    </Flex>
                </MarketingWrapper>
            }
        />
    );
};

export default ProfileMarketing;
