import { Hidden } from '@mui/material';
import { Flex } from '../Flex';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';

interface IProps {
    editModeOn?: boolean;
    onClose?: () => void;
    onEdit?: () => void;
    title: string;
}

export const CardHeader = (props: IProps) => {
    const { t } = useTranslation();
    return (
        <Flex spread style={{ paddingBottom: 15 }}>
            <h3 className="tg-heading-2">{props.title}</h3>

            <Hidden smDown>
                {props.onClose && props.editModeOn && (
                    <Button variant="outline" onClick={() => props.onClose?.()}>
                        {t('menu.close')}
                    </Button>
                )}

                {props.onEdit && !props.editModeOn && (
                    <Button onClick={() => props.onEdit?.()}>{t('general.edit')}</Button>
                )}
            </Hidden>
        </Flex>
    );
};
