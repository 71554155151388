import type { MutationFunctionOptions } from '@apollo/client';
import { CheckBox } from 'components/ui/checkbox';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../components/Icon';
import { InfoTooltip } from '../../components/ToolTip';
import { COLOR_BLUM } from '../../styles/variables';
import { useUser } from 'queries/useUserQuery';

interface IProps {
    updateUserData: (options?: MutationFunctionOptions<any, Record<string, any>> | undefined) => Promise<any>;
}

export const ProfileSettingsStorageConsent = (props: IProps) => {
    const { t } = useTranslation();
    const user = useUser();
    const handleConsentUpdate = (value: boolean) => {
        props.updateUserData({
            optimisticResponse: {
                updateUserData: {
                    __typename: 'User',
                    ...user,
                    longTermStorageConsent: value,
                },
            },
            variables: {
                longTermStorageConsent: value,
            },
        });
    };

    return (
        <div className="flex items-center">
            <CheckBox
                required
                id="storage-consent"
                name="storage-consent"
                value={'longTermStorageConsent'}
                checked={user?.longTermStorageConsent}
                label={t('profile:settings.long-term-storage-consent')}
                onCheckedChange={(checked) => handleConsentUpdate(!!checked.valueOf())}
            />
            <InfoTooltip
                className="ml-3"
                title={t('profile:settings.long-term-storage-consent-info')}
                placement="top"
                enterTouchDelay={0}
                arrow
            >
                <div className="relative grid items-center">
                    <Icon icon={['fas', 'info-circle']} color={COLOR_BLUM} style={{ width: '15px' }} />
                </div>
            </InfoTooltip>
        </div>
    );
};
