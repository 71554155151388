import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Fade, Hidden } from '@mui/material';
import type { ThunkDispatch } from 'redux-thunk';
import { showModals } from 'actions/auth';
import { EezyButton } from 'components/Buttons';
import { List } from '../../components/layout/List';
import { EmptyListPlaceholder } from 'components/EmptyListPlaceholder';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import { UniqueTitle, PSmall } from 'components/textElements';
import { COLOR_BLUM, SCREEN_L, SCREEN_S } from 'styles/variables';
import { isMobile } from 'utils';
import { useSelectedListItem } from 'utils/hooks';
import {
    divideInvoices,
    filterInvoices,
    filterInvoicesByRecipientId,
    sortInvoices,
} from 'utils/invoice/invoiceLogic';
import { useTranslation } from 'react-i18next';
import { TextDivider } from 'components/TextDivider';
import type { IRootState } from 'reducers';
import { DashboardCard } from 'components/cards/DashboardCard';
import InvoiceSearch from 'containers/invoice/InvoiceSearch';
import LoadingSpinner from 'components/Loading';
import ContainerMobileScrollTop from 'components/ui/ContainerMobileScrollTop';
import ContainerScrollTop from 'components/ui/ContainerScrollTop';
import { Button } from 'components/common/Button';
import PendingPaymentInvoice from 'components/invoices/PendingPaymentInvoice';
import InvoiceListItems from 'components/invoices/InvoiceListItems';
import { Plus } from 'lucide-react';
import { trpc } from 'trpc';

const FADE_TIMEOUT = 200;
const MIN_ITEMS_TO_SHOW_SCROLL = 12;

const Wrapper = styled.div<{ showScroll?: boolean }>`
    padding-right: ${(props) => (props.showScroll ? '15px' : '0')};
`;
Wrapper.displayName = 'Wrapper';

const InvoiceList = () => {
    const DEFAULT_VISIBLE_ITEMS_LENGTH = window.innerWidth >= SCREEN_L ? 200 : 5;
    const el = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const searchQuery = useSelector((state: IRootState) => state.invoice.searchQuery);
    const searchRecipientId = useSelector((state: IRootState) => state.invoice.searchRecipientId);

    const [visibleTopLength, setVisibleTopLength] = useState(DEFAULT_VISIBLE_ITEMS_LENGTH);
    const [visibleBottomLength, setVisibleBottomLength] = useState(DEFAULT_VISIBLE_ITEMS_LENGTH);

    const selectedId = useSelectedListItem();

    const scrollRef = useRef<HTMLSpanElement>(null);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const loadMoreTopInvoices = () => {
        setVisibleTopLength(visibleTopLength + DEFAULT_VISIBLE_ITEMS_LENGTH);
    };

    const loadMoreBottomInvoices = () => {
        setVisibleBottomLength(visibleBottomLength + DEFAULT_VISIBLE_ITEMS_LENGTH);
    };

    const { data: invoiceData, isLoading: loading } = trpc.invoices.list.useQuery();

    const allInvoices = useMemo(() => (invoiceData ? sortInvoices(invoiceData.items) : []), [invoiceData]);

    const filteredInvoices = useMemo(() => {
        if (searchRecipientId) {
            return filterInvoicesByRecipientId(searchRecipientId.toString(), allInvoices);
        }

        if (searchQuery) {
            return filterInvoices(searchQuery, allInvoices);
        }

        return allInvoices;
    }, [searchQuery, searchRecipientId, allInvoices]);

    const topInvoices = useMemo(() => divideInvoices(filteredInvoices).topInvoices, [filteredInvoices]);
    const bottomInvoices = useMemo(() => divideInvoices(filteredInvoices).bottomInvoices, [filteredInvoices]);

    const showScroll = !isMobile() && topInvoices.length + bottomInvoices.length >= MIN_ITEMS_TO_SHOW_SCROLL;

    const visibleTopLoadMoreButton = filteredInvoices && visibleTopLength < topInvoices.length;
    const visibleBottomLoadMoreButton = filteredInvoices && visibleBottomLength < bottomInvoices.length;
    const visibleTopInvoices = topInvoices.slice(0, visibleTopLength);
    const visibleBottomInvoices = bottomInvoices.slice(0, visibleBottomLength);

    const handleNewInvoiceClick = () => {
        if (window.innerWidth >= SCREEN_S) {
            dispatch(showModals(['CREATION_METHOD']));
        } else {
            navigate('/invoices/new');
        }
    };

    const showMobileScrollToTop =
        el.current &&
        (visibleTopLength > DEFAULT_VISIBLE_ITEMS_LENGTH ||
            visibleBottomLength > DEFAULT_VISIBLE_ITEMS_LENGTH);

    return (
        <DashboardCard>
            <Wrapper showScroll={showScroll} className="xl:relative w-full">
                <Fade in={true} timeout={FADE_TIMEOUT}>
                    <div className="flex justify-between items-center pb-7">
                        <UniqueTitle>{t('invoice.invoices')}</UniqueTitle>
                        <Button startIcon={Plus} onClick={handleNewInvoiceClick}>
                            {t('invoice.new-invoice')}
                        </Button>
                    </div>
                </Fade>

                {loading ? (
                    <Flex justifyCenter style={{ padding: '25px 0' }}>
                        <LoadingSpinner color={COLOR_BLUM} />
                    </Flex>
                ) : (
                    <>
                        <Fade in={true} timeout={FADE_TIMEOUT} style={{ transitionDelay: '100ms' }}>
                            <div>
                                <InvoiceSearch />
                                {allInvoices.length === 0 && (
                                    <EmptyListPlaceholder text={t('invoice.empty-list-text')} />
                                )}
                                {filteredInvoices.length === 0 && allInvoices.length > 0 && (
                                    <EmptyListPlaceholder text={t('invoice.no-results')} />
                                )}
                            </div>
                        </Fade>
                        <PendingPaymentInvoice />
                    </>
                )}

                <List ref={el} className={showScroll ? 'show-scroll' : 'hide-scroll'}>
                    <Hidden mdDown>
                        <span ref={scrollRef} />
                    </Hidden>
                    {/* Top Invoices */}
                    <InvoiceListItems items={visibleTopInvoices} selectedId={selectedId} />
                    {visibleTopLoadMoreButton && (
                        <div className="mt-10">
                            <EezyButton
                                color="purple"
                                transparent
                                iconAlignment="right"
                                onClick={loadMoreTopInvoices}
                                className="v2-btn w-full border-0"
                            >
                                {loading ? (
                                    <LoadingSpinner size="1em" />
                                ) : (
                                    t('dashboard.invoices.load-more-button')
                                )}
                                <Icon icon={['far', 'arrow-down']} />
                            </EezyButton>
                        </div>
                    )}
                    {/* Bottom Invoices */}
                    {visibleBottomInvoices.length > 0 && (
                        <TextDivider>
                            <PSmall color={COLOR_BLUM}>{t('invoice.invoices-ready')}</PSmall>
                        </TextDivider>
                    )}
                    <InvoiceListItems items={visibleBottomInvoices} selectedId={selectedId} />
                    {visibleBottomLoadMoreButton && (
                        <div className="mt-10">
                            <EezyButton
                                color="purple"
                                transparent
                                iconAlignment="right"
                                onClick={loadMoreBottomInvoices}
                                className="v2-btn w-full border-0"
                            >
                                {loading ? (
                                    <LoadingSpinner size="1em" />
                                ) : (
                                    t('dashboard.invoices.load-more-button')
                                )}
                                <Icon icon={['far', 'arrow-down']} />
                            </EezyButton>
                        </div>
                    )}
                </List>

                {el.current && window.innerWidth >= SCREEN_L && <ContainerScrollTop container={el.current} />}
                {showMobileScrollToTop && (
                    <ContainerMobileScrollTop
                        container={el.current}
                        key={`${visibleTopLength}-${visibleBottomLength}`}
                        topOffset={148}
                    />
                )}
            </Wrapper>
        </DashboardCard>
    );
};

export default InvoiceList;
