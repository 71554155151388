import { Navigation } from 'components/navigation/Navigation';
import { Outlet } from 'react-router-dom';

export const MainWrapper = () => {
    return (
        <div>
            <Navigation />
            <Outlet />
        </div>
    );
};
