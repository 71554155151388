import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import type { IRootState } from 'reducers';
import { useTranslation } from 'react-i18next';
import ACCEPT_USER_TERMS from 'containers/eezypay/queries/acceptUserTerms';
import GET_USER from 'containers/eezypay/queries/getUser';
import CREATE_USER from 'containers/eezypay/queries/createUser';
import { dispatch } from 'utils/store';
import { acceptedEezyPayTerms } from 'actions/eezyPay';
import { useUser } from 'queries/useUserQuery';

export default function () {
    const { i18n } = useTranslation();
    const user = useUser();
    const eezyPayUserState = useSelector((state: IRootState) => state.eezyPay.userState);

    const [acceptEezyPayTerms] = useMutation(ACCEPT_USER_TERMS, {
        context: { clientName: 'eezyPayHasura' },
        refetchQueries: [{ query: GET_USER, context: { clientName: 'eezyPayHasura' } }],
    });

    const [createEezyPayUser] = useMutation(CREATE_USER, {
        context: { clientName: 'eezyPayHasura' },
        refetchQueries: [{ query: GET_USER, context: { clientName: 'eezyPayHasura' } }],
    });

    const submitAcceptTerms = async (userData: any) => {
        if (eezyPayUserState) {
            acceptEezyPayTerms({
                variables: {
                    id: user?.id,
                },
            });
        } else {
            createEezyPayUser({
                variables: {
                    firstName: userData?.firstName,
                    lastName: userData?.lastName,
                    language: i18n.language,
                    email: user?.email,
                    phone: `+${user?.phoneCountryCode} ${user?.phone}`,
                    userAccountId: userData?.accountId,
                    campwireTermsAccepted: false,
                },
            });
        }

        dispatch(acceptedEezyPayTerms());
    };

    return {
        submitAcceptTerms,
    };
}
