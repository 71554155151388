import { useDispatch } from 'react-redux';
import type { INotification } from '../../../../shared/src/types/notifications';
import { NotificationDynamic } from './NotificationDynamic';
import { getStaticNotificationIconColor, isArchiveDisabled } from 'utils/notifications/notifications';
import { NotificationActionType } from 'types/notification.type';
import { Notification } from './Notification';
import { showModals } from 'actions/auth';
import { useTranslation } from 'react-i18next';

type Props = {
    isIdentified: boolean;
    data: INotification;
    onOpen: (data: INotification) => void;
    onArchive: (data: INotification) => void;
};

export default function NotificationsItem(props: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onOpen = () => {
        props.onOpen(props.data);
    };

    const onArchive = () => {
        if (isArchiveDisabled(props.data)) {
            return;
        }

        props.onArchive(props.data);
    };

    switch (props.data.actionType as string) {
        case NotificationActionType.VerifyIdentity:
            return (
                <Notification
                    icon={['far', 'exclamation-circle']}
                    title={t('dashboard.messages.auth.title')}
                    text={t('dashboard.messages.auth.text') || ''}
                    iconColor={getStaticNotificationIconColor(false)}
                    isRead={false}
                    buttonTransKey="dashboard.message-button"
                    onOpen={() => dispatch(showModals(['AUTH']))}
                />
            );
        case NotificationActionType.YelConsent:
            return (
                <Notification
                    icon={['far', 'exclamation-circle']}
                    iconColor={getStaticNotificationIconColor(false)}
                    isRead={false}
                    title={t('dashboard.messages.yel.title')}
                    text={t('dashboard.messages.yel.text') || ''}
                    buttonTransKey="dashboard.message-button"
                    onOpen={() => dispatch(showModals(['YEL']))}
                />
            );
        default:
            return (
                <NotificationDynamic
                    key={`notification-${props.data.aavaId}`}
                    notification={props.data}
                    onArchive={isArchiveDisabled(props.data) ? undefined : onArchive}
                    onOpen={onOpen}
                />
            );
    }
}
