import React, { type FormEvent, type KeyboardEvent } from 'react';
import { Input } from './common/Input';
import { Search, X } from 'lucide-react';
import ToolTip from 'components/ui/tooltip';

interface ISearchFieldProps {
    onChange: (query: string, fireImmediately?: boolean) => void;
    placeholder: string;
    query: string;
    tooltip?: string;
}

const SearchField = (props: ISearchFieldProps) => {
    const [focused, setFocused] = React.useState(false);
    const [hovered, setHovered] = React.useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
    const onMouseOver = () => setHovered(true);
    const onMouseOut = () => setHovered(false);

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        props.onChange(e.currentTarget.value);
    };

    const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        const code = e.key;
        if (code === 'Enter') {
            // enter pressed, fire search immediately
            props.onChange(e.currentTarget.value, true);
        }
    };

    const handleEmpty = () => {
        props.onChange('', true);
    };

    const renderSearchField = () => (
        <Input
            onChange={handleChange}
            onKeyDown={handleEnter}
            placeholder={props.placeholder}
            type="text"
            value={props.query}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            startIcon={Search}
            endIconButton={props.query ? X : undefined}
            onEndIconButtonClick={handleEmpty}
            helpText={props.tooltip}
        />
    );

    if (!props.tooltip) {
        return renderSearchField();
    }

    return (
        <ToolTip trigger={renderSearchField()}>
            <p>{props.tooltip}</p>
        </ToolTip>
    );
};

export default SearchField;
