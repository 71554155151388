import { forwardRef } from 'react';
import type { IUserData } from '../../../../shared/src/types/user';

type AvatarProps = {
    // user can be null from useUser, we set the type like this so it's simpler to use
    user: IUserData | null;
    asDiv?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type AvatarButtonProps = AvatarProps & {
    asDiv?: false;
    onClick: () => void;
};

type AvatarDivProps = AvatarProps & {
    asDiv?: true;
};

export const Avatar = forwardRef<HTMLButtonElement, AvatarButtonProps | AvatarDivProps>(
    (props: AvatarButtonProps | AvatarDivProps, forwardRef) => {
        const { asDiv, user, ...rest } = props;
        // If user is not loaded yet, return an empty div with same size.
        if (!user) return <div className="size-10" />;

        const baseClassName =
            'tg-caption-bold size-10 bg-violet-800 text-white rounded-full overflow-hidden flex justify-center items-center';
        const initials = user.firstName[0] + user.lastName[0];
        const ariaLabel = `${user.firstName} ${user.lastName}`;

        if (asDiv)
            return (
                <div className={baseClassName} aria-label={ariaLabel}>
                    {initials}
                </div>
            );

        return (
            <button
                className={`${baseClassName} hover:bg-violet-700 active:bg-violet-600 focus-within:ring-1 outline-ring ring-gray-700 ring-offset-1`}
                type="button"
                aria-label={ariaLabel}
                onClick={(props as AvatarButtonProps).onClick}
                ref={forwardRef}
                {...rest}
            >
                {initials}
            </button>
        );
    },
);
