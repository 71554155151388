import Fuse from 'fuse.js';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SiteSearchItem = {
    title: string | string[];
    path: string;
    tags: string;
    description: string;
};

export const useSiteSearch = () => {
    const { t } = useTranslation();

    const [searchTerm, setSearchTerm] = useState('');

    // TODO: this is nearly not all, and the yel title is wrong at the time of writing this.
    // as it is still on its own page instead of being under profile.

    const searchMap = useMemo<SiteSearchItem[]>(
        () => [
            {
                title: t('nav.invoices'),
                path: '/invoices',
                tags: t('siteSearch:tags.invoices'),
                description: t('siteSearch:descriptions.invoice'),
            },
            {
                title: t('invoice.new-invoice'),
                path: 'invoices/new',
                tags: t('siteSearch:tags.newInvoice'),
                description: t('siteSearch:descriptions.newInvoice'),
            },
            {
                title: [t('nav.own-info'), t('menu.yel')],
                path: '/yel',
                tags: t('siteSearch:tags.yel'),
                description: t('siteSearch:descriptions.yel'),
            },
        ],
        [t],
    );

    const fuse = useMemo(() => new Fuse(searchMap, { keys: ['title', 'tags'], threshold: 0.4 }), [searchMap]);

    const filteredItems = useMemo(() => {
        const items = searchTerm ? fuse.search(searchTerm).map((match) => match.item) : searchMap;

        return items.slice(0, 5);
    }, [fuse, searchTerm, searchMap]);

    return { filteredItems, searchTerm, setSearchTerm };
};
